import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chart from "../partials/chart";
import { FaCheck, FaTimes } from "react-icons/fa";
import CustomTable from "../../../../widgets/table";
import { ScheduleSummary, DiscrepancySummary, ExceptionSummary} from './Violation/columns';
import DownloadPdf from "../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";

const SummaryReport: React.FC = () => {
  const { bulletId } = useParams<{ bulletId: string }>();
  const { t } = useTranslation();


  const [timeRange, setTimeRange] = useState("monthly");
  const [chartType, setChartType] = useState("bar");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedGraph, setSelectedGraph] = useState<string>("Summary");
  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    activityType:"",
    shift: "",
    operator: "",
    microbiologist: "",
    period: "",
    organismType: "",
    organismStrain: "",
    organismGenus: "",
    organismSpecies: "",
  })

  let tableDataSummary: any[] = []
  const scheduleData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Actual Schedule": "2/20/24 9:00", "Expected Schedule": "2/20/24 9:00" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Exposure", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 0:00", "Actual Schedule": "2/21/24 0:00", "Expected Schedule": "2/21/24 0:00" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Exposure", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 0:00", "Actual Schedule": "2/22/24 0:00", "Expected Schedule": "2/22/24 0:00" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Exposure", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 0:00", "Actual Schedule": "2/23/24 0:00", "Expected Schedule": "2/23/24 0:00" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 0:00", "Actual Schedule": "2/24/24 0:00", "Expected Schedule": "2/24/24 0:00" },
    { "Area": "Area 2", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 0:00", "Actual Schedule": "2/25/24 0:00", "Expected Schedule": "2/25/24 0:00" },
    { "Area": "Area 3", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 0:00", "Actual Schedule": "2/26/24 0:00", "Expected Schedule": "2/26/24 0:00" },
    { "Area": "Area 4", "Location": "Location H", "Activity Type": "Exposure", "Operator": "Operator 8", "Microbiologist": "Microbiologist 8", "Date & Time": "2/27/24 0:00", "Actual Schedule": "2/27/24 0:00", "Expected Schedule": "2/27/24 0:00" }
  ];
  const discrepancyData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Discrepancy Type": "Type 1", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Incubation", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 9:00", "Discrepancy Type": "Type 2", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Incubation", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 9:00", "Discrepancy Type": "Type 3", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Incubation", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 9:00", "Discrepancy Type": "Type 4", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 9:00", "Discrepancy Type": "Type 5", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 9:00", "Discrepancy Type": "Type 6", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 9:00", "Discrepancy Type": "Type 7", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" }
  ];
  const exceptionData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Exception Type": "Type 1", "Exception Reasons": "Reason 1" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Incubation", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 9:00", "Exception Type": "Type 2", "Exception Reasons": "Reason 2" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Incubation", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 9:00", "Exception Type": "Type 3", "Exception Reasons": "Reason 3" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Incubation", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 9:00", "Exception Type": "Type 4", "Exception Reasons": "Reason 4" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 9:00", "Exception Type": "Type 5", "Exception Reasons": "Reason 5" },
    { "Area": "Area 1", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 9:00", "Exception Type": "Type 6", "Exception Reasons": "Reason 6" },
    { "Area": "Area 1", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 9:00", "Exception Type": "Type 7", "Exception Reasons": "Reason 7" }
  ];

  useEffect(() => {
    if (bulletId === "area") {
      setSelectedArea("Area");
    } else if (bulletId === "location") {
      setSelectedArea("Location");
    } else if (bulletId === "operator") {
      setSelectedArea("Operator");
    } else if (bulletId === "schedule") {
      setSelectedArea("Schedule Summary");
    } else if (bulletId === "discrepancy") {
      setSelectedArea("Discrepency Summary");
    } else if (bulletId === "exception") {
        setSelectedArea("Exception Summary");
    } else {
      setSelectedArea("");
    }
  }, [bulletId]);

  let columnsSummary: any = [];
  if (bulletId === "schedule") {
    columnsSummary = ScheduleSummary;
    tableDataSummary = scheduleData;
  } else if (bulletId === "discrepancy") {
    columnsSummary = DiscrepancySummary;
    tableDataSummary = discrepancyData
  } else if (bulletId === "exception") {
    columnsSummary = ExceptionSummary; 
    tableDataSummary = exceptionData
  }

  const violationTrends = ["area", "location", "operator"]
  const violationSummarys = ["schedule", "discrepancy", "exception"]
  if (bulletId === undefined) {
    return null; 
  }
  const trimmedBulletId = bulletId.trim();
  const isInViolationTrends = violationTrends.includes(trimmedBulletId);
  const isInViolationSummarys = violationSummarys.includes(trimmedBulletId);

  const generateLabels = (range: any) => {
    switch (range) {
      case "daily":
        return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      case "weekly":
        return ["Week 1", "Week 2", "Week 3", "Week 4"];
      case "monthly":
        return ["January", "February", "March", "April", "May"];
      case "yearly":
        return ["2021", "2022", "2023", "2024", "2025"];
      case "seasonal":
        return ["Spring", "Summer", "Autumn", "Winter"];
      default:
        return [];
    }
  };

  const tableData: any[] = [
    {
      "Area": "Area 1",
      "Location": "Location 1",
      "Test Category": "Exposure",
      "samples": 200,
      "Colony Count": 5,
      "Excursion": "",
      "Organism Type": "ABC",
      "Genus": "XYZ",
      "Species": "AAA",
      "Strain": "STR",
      "Operator / Microbiologist Name": "Operator 1"
    },
    {
      "Area": "Area 2",
      "Location": "Location 2",
      "Test Category": "Exposure",
      "samples": 250,
      "Colony Count": 10,
      "Excursion": "",
      "Organism Type": "XYV",
      "Genus": "XYZ",
      "Species": "AAA",
      "Strain": "STR",
      "Operator / Microbiologist Name": "Operator 1"
    },
    {
      "Area": "Area 3",
      "Location": "Location 3",
      "Test Category": "Exposure",
      "samples": 260,
      "Colony Count": 8,
      "Excursion": "",
      "Organism Type": "ABC",
      "Genus": "XYZ",
      "Species": "AAA",
      "Strain": "STR",
      "Operator / Microbiologist Name": "Operator 2"
    },
    {
      "Area": "Area 4",
      "Location": "Location 4",
      "Test Category": "Exposure",
      "samples": 210,
      "Colony Count": 4,
      "Excursion": "",
      "Organism Type": "QWE",
      "Genus": "XYZ",
      "Species": "AAA",
      "Strain": "ZXY",
      "Operator / Microbiologist Name": "Operator 2"
    },
    {
      "Area": "Area 5",
      "Location": "Location 5",
      "Test Category": "Exposure",
      "samples": 180,
      "Colony Count": 30,
      "Excursion": "",
      "Organism Type": "ABC",
      "Genus": "QWE",
      "Species": "ASD",
      "Strain": "ZXY",
      "Operator / Microbiologist Name": "Operator 1"
    },
    {
      "Area": "Area 6",
      "Location": "Location 6",
      "Test Category": "Exposure",
      "samples": 300,
      "Colony Count": 6,
      "Excursion": "",
      "Organism Type": "ABC",
      "Genus": "QWE",
      "Species": "ASD",
      "Strain": "LMN",
      "Operator / Microbiologist Name": "Operator 1"
    },
    {
      "Area": "Area 7",
      "Location": "Location 7",
      "Test Category": "Exposure",
      "samples": 275,
      "Colony Count": 8,
      "Excursion": "",
      "Organism Type": "VBN",
      "Genus": "QWE",
      "Species": "ASD",
      "Strain": "LMN",
      "Operator / Microbiologist Name": "Operator 2"
    },
    {
      "Area": "Area 8",
      "Location": "Location 8",
      "Test Category": "Exposure",
      "samples": 255,
      "Colony Count": 10,
      "Excursion": "",
      "Organism Type": "ABC",
      "Genus": "QWE",
      "Species": "AAA",
      "Strain": "PQR",
      "Operator / Microbiologist Name": "Operator 2"
    },
    {
      "Area": "Area 9",
      "Location": "Location 9",
      "Test Category": "Exposure",
      "samples": 224,
      "Colony Count": 5,
      "Excursion": "",
      "Organism Type": "XYV",
      "Genus": "XYZ",
      "Species": "AAA",
      "Strain": "PQR",
      "Operator / Microbiologist Name": "Microbiologist 1"
    },
    {
      "Area": "Area 10",
      "Location": "Location 10",
      "Test Category": "Exposure",
      "samples": 216,
      "Colony Count": 8,
      "Excursion": "",
      "Organism Type": "VBN",
      "Genus": "QWE",
      "Species": "AAA",
      "Strain": "ZXY",
      "Operator / Microbiologist Name": "Microbiologist 1"
    }
  ];
  
  const handleInputChange = (event : any) => {
    const { name, value } = event.target;
    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  interface ChartData {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      fill?: boolean; // Adjust as needed
    }[];
  }

  const data: ChartData = {
    labels: generateLabels(timeRange),
   
    datasets: [
      {
        label: "Sample Data 1",
        data: [12, 19, 3, 5, 2], // Replace with your actual data
        backgroundColor: "red",
        borderColor: "",
        borderWidth: 1,
        fill: chartType === "area", // Adjust as needed
      },
      {
        label: "Sample Data 2",
        data: [8, 15, 6, 10, 5], // Replace with your actual data
        backgroundColor: "blue",
        borderColor: "",
        borderWidth: 1,
        fill: chartType === "area", // Adjust as needed
      },
      {
        label: "Sample Data 3",
        data: [5, 10, 2, 8, 3], // Replace with your actual data
        backgroundColor: "yellow",
        borderColor: "",
        borderWidth: 1,
        fill: chartType === "area", // Adjust as needed
      },
    ],
  };


  const columns: TableColumn[] = [
    {
      Header: 'analyticalDashboard.SummaryReportColumns.area',
      accessor: 'Area',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.location',
      accessor: 'Location',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.testCategory',
      accessor: 'Test Category',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.noOfSamples',
      accessor: 'samples',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.colonyCount',
      accessor: 'Colony Count',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.excursion',
      accessor: 'Excursion',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.organismType',
      accessor: 'Organism Type',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.genus',
      accessor: 'Genus',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.species',
      accessor: 'Species',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.strain',
      accessor: 'Strain',
    },
    {
      Header: 'analyticalDashboard.SummaryReportColumns.operatorOrMicrobiologistName',
      accessor: 'Operator / Microbiologist Name',
    },
  ];

  interface TableColumn {
    Header: string;
    accessor: string;
  }

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('dashboard.summaryReport')} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard"> {t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Summary">{t('common.summary')}</Link> 
            </li>
            <li className="breadcrumb-item active selectedArea" aria-current="page">
              {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <DownloadPdf divId="dashWrap" reportHead={t('dashboard.summaryReport')} />
        </div>
      </nav>

      <div >
      <div className="d-flex justify-content-around mt-3">

        {/* Area filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_area')}:</label>
          <select className='areaTypeSelect' name="area" value={filterData.area} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Area 1" >Area 1</option>
            <option value="Area 2" >Area 2</option>
            <option value="Area 3" >Area 3</option>
            <option value="Area 4" >Area 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Location filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_location')}:</label>
          <select className='areaTypeSelect' name="location" value={filterData.location} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Location 1" >Location 1</option>
            <option value="Location 2" >Location 2</option>
            <option value="Location 3" >Location 3</option>
            <option value="Location 4" >Location 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Activity Type filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_activity')}:</label>
          <select className='areaTypeSelect' name="activityType" value={filterData.activityType} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Exposure" >Exposure</option>
            <option value="Incubation" >Incubation</option>
            <option value="Finger Dab" >Finger Dab</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Shift filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_shift')}:</label>
          <select className='areaTypeSelect' name="shift" value={filterData.shift} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Shift 1" >Shift 1</option>
            <option value="Shift 2" >Shift 2</option>
            <option value="Shift 3" >Shift 3</option>
            <option value="Shift 4" >Shift 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Operator filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_operator')}:</label>
          <select className='areaTypeSelect' name="operator" value={filterData.operator} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Operator 1" >Operator 1</option>
            <option value="Operator 2" >Operator 2</option>
            <option value="Operator 3" >Operator 3</option>
            <option value="Operator 4" >Operator 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

      </div>

      <div className="d-flex justify-content-around mt-3">

        {/* Microbiologist filter */}
         <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_microbiologist')}:</label>
          <select className='areaTypeSelect' name="microbiologist" value={filterData.microbiologist} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Microbiologist 1" >Microbiologist 1</option>
            <option value="Microbiologist 2" >Microbiologist 2</option>
            <option value="Microbiologist 3" >Microbiologist 3</option>
            <option value="Microbiologist 4" >Microbiologist 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Period filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_period')}:</label>
          <select className='areaTypeSelect' name="period" value={filterData.period} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Daily" >Daily</option>
            <option value="Weekly" >Weekly</option>
            <option value="Monthly" >Monthly</option>
            <option value="Yearly" >Yearly</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Organism Type filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_organism_type')}:</label>
          <select className='areaTypeSelect' name="organismType" value={filterData.organismType} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Organism Type 1" >Organism Type 1</option>
            <option value="Organism Type 2" >Organism Type 2</option>
            <option value="Organism Type 3" >Organism Type 3</option>
            <option value="Organism Type 4" >Organism Type 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>
     

        {/* Organism Genus filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_organism_genus')}:</label>
          <select className='areaTypeSelect' name="organismGenus" value={filterData.organismGenus} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Organism Genus 1" >Organism Genus 1</option>
            <option value="Organism Genus 2" >Organism Genus 2</option>
            <option value="Organism Genus 3" >Organism Genus 3</option>
            <option value="Organism Genus 4" >Organism Genus 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

         {/* Organism Species filter */}
         <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_organism_species')}:</label>
          <select className='areaTypeSelect' name="organismSpecies" value={filterData.organismSpecies} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Organism Species 1" >Organism Species 1</option>
            <option value="Organism Species 2" >Organism Species 2</option>
            <option value="Organism Species 3" >Organism Species 3</option>
            <option value="Organism Species 4" >Organism Species 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

      </div>

      <div className="d-flex justify-content-around mt-3">
        
         {/* Organism Strain filter */}
         <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_organism_strain')}:</label>
          <select className='areaTypeSelect' name="organismStrain" value={filterData.organismStrain} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Organism Strain 1" >Organism Strain 1</option>
            <option value="Organism Strain 2" >Organism Strain 2</option>
            <option value="Organism Strain 3" >Organism Strain 3</option>
            <option value="Organism Strain 4" >Organism Strain 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* From Date */}
        <div className="select-container analyticFilterDate ms-1" 
          style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.from_date')}:</label>
            <input type="date" />
        </div>

        {/* To Date */}
        <div className="select-container analyticFilterDate" 
          style={{display: "flex", flexDirection: "column", marginRight:"236px"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.to_date')}:</label>
          <input type="date" />
        </div>

        <div className="my-3">
            <button className="analyticalFilterClose" title="Click here to Reset Filter">{t('buttons.cancel')}</button>
            <button className="analyticalFilterApply" title="Click here to Apply Filter">{t('buttons.apply')}</button>
        </div>

      </div>


      <div className="row mx-2" id="dashWrap" style={{"display":"flex", "justifyContent":"center"}}>
      {isInViolationTrends && (
        <div className="col-12">
          <div className="">
            <CustomTable 
              columns={columns}
              data={tableData}
              isActionVisible={false}
              isViewVisible={false}
              totalElements={10}
              tableHead={`${t('common.summary')} - ${selectedArea}`}
            />
          </div>
        </div>
      )}
      {isInViolationSummarys && (
        <div className="col-12">
          <div>
            <CustomTable 
              columns={columnsSummary}
              data={tableDataSummary}
              isActionVisible={false}
              isViewVisible={false}
              totalElements={10}
              tableHead={selectedArea}
            />
          </div>
        </div>
      )}
      </div>

      </div>
    </div>
  );
};

export default SummaryReport;
