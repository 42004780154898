import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


const PendingFloraPlates =  async (page :number, pageSize :number,area_id: string, activityType: string, keyWord: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plate_environmental_flora/list_plates_for_flora?page=${page}&size=${pageSize}&sort=id&order=asc&status=active&lab_area_id=${area_id}&keyword=${keyWord}&plate_type=${activityType}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const scanEnvironmentFloraById = async (dataToSend :any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/plate_environmental_flora/check_plate_for_flora`, dataToSend,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listShapesOptions =  async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_colony_shapes?sort=shape_name&order=asc&status=active`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listColorOptions =  async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_colony_colors?sort=colony_colour&order=asc&status=active`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listTypeOptions =  async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_organism_types?sort=organism_type&order=asc&status=active`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listGenusOptions =  async (type_id: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_colony_genus?sort=organism_type&order=asc&status=active&organism_type_id=${type_id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listSpeciesOptions =  async (type_id: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_colony_species?sort=organism_type&order=asc&status=active&genus_id=${type_id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const listStrainOptions =  async (type_id: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/master/list_colony_strain?sort=organism_type&order=asc&status=active&species_id=${type_id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const saveEnvironmentFlora = async (dataToSend : any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/plates/save_plate_environmental_flora_details`, 
     dataToSend,
     {
       headers: {
         'x-auth-token': authToken,
         'x-userid': authUserID
       }
     }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const HistoryFloraPlates =  async (page :number, pageSize :number,area_id: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/plate_environmental_flora/list_plate_flora_details?page=${page}&size=${pageSize}&sort=id&order=asc&status=active&lab_area_id==${area_id}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}





const floraServices = {

    PendingFloraPlates,
    scanEnvironmentFloraById,
    listShapesOptions,
    listColorOptions,
    listTypeOptions,
    listGenusOptions,
    listSpeciesOptions,
    listStrainOptions,
    saveEnvironmentFlora,
    HistoryFloraPlates,

  };
  
  export { floraServices };