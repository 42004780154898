import React, { FC, useEffect, useState } from 'react';
import Notification from '../notification';
import { IoIosLogOut, IoMdLogOut } from 'react-icons/io';
import { authenticationService } from '../../../service/authentication';
import CustomToast from '../../../widgets/toaster';
import { userService } from '../../../service/userService';
import { handleLogOut as handleLogOutTimer  } from "../../../utils/oathUtils";
interface PageHeaderProps {
  pageHeader: string;
}


const PageHeader: FC<PageHeaderProps> = ({ pageHeader }) => {
  const [userDetails, setUserDetails] = useState<Array<any>>([]);
  const [userName, setUserName] = useState("")
  const [role, setRole] = useState("")
  const [remainingTime, setRemainingTime] = useState(20 * 60); // 20 minutes in seconds
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [authToken , setAuthToken] = useState("")
  const [authUserID , setAuthUserID] = useState("")
  const [userId, setUserId] = useState("");
  const [notificationCount, setNotificationCount] = useState(0)


  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserDetails(storedObject || '')
      setUserName(storedObject[0]?.first_name + " " + storedObject[0]?.last_name);
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
      setUserId(storedObject[0].id)
      getPermissionByRole(storedObject[0]?.role_master_id)
      listNotificationCount(storedObject[0].id);
    }
  }, [])

  const [isActive, setIsActive] = useState(true);

  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setRole(response.data.title)
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const intervalId = setTimeout(() => {
      // Call your function here
      listNotificationCount(userId);
      // Recursive call after 20 seconds
      setTimeoutFunction();
    }, 20000); // Initial 20 seconds

    // Clear the timeout when the component unmounts
    return () => clearTimeout(intervalId);
  }, [userId]); // Empty dependency array to run the effect only once

  const setTimeoutFunction = () => {
    setTimeout(() => {
      // Call your function here
      listNotificationCount(userId);
      // Recursive call after 20 seconds
      setTimeoutFunction();
    }, 20000); // 20 seconds
  };


  // updated session logout function
  useEffect(() => {
  let inactivityTimeout : any;

  const resetTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
      setIsActive(false);
      handleLogOutTimer()
    }, 20 * 60 * 1000); // 20 minutes
  };

  const handleActivity = () => {
    if (!isActive) {
      setIsActive(true);
      setRemainingTime(20 * 60); // Reset the timer on activity
      resetTimeout();
    } else {
      setRemainingTime(20 * 60); // Reset the timer on activity
      resetTimeout();
    }
  };

  const updateRemainingTime = () => {
    setRemainingTime((prevTime) => Math.max(0, prevTime - 1));
  };

  resetTimeout();

  const intervalId = setInterval(updateRemainingTime, 1000);

  window.addEventListener('click', handleActivity);
  window.addEventListener('keypress', handleActivity);

  return () => {
    clearInterval(intervalId);
    window.addEventListener('click', handleActivity);
    window.removeEventListener('keypress', handleActivity);
    clearTimeout(inactivityTimeout);
  };
  }, [isActive]);

  const formattedTime = `${String(Math.floor(remainingTime / 60)).padStart(2, '0')}:${String(remainingTime % 60).padStart(2, '0')}`;

  const handleLogOut =  async () => {
    try {
      const response = await authenticationService.LogoutApi(userId, authToken, authUserID);
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
        localStorage.removeItem('userDetails');
        window.location.replace(`/`);
      }else{
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
    } catch (error) {
      console.error("Error signing in:", error);
    }
  }

  const listNotificationCount = async (id : string) => {
    console.log(id, "idd---")
    try {
      const response = await authenticationService.listNotificationCount(id);
      if(response.status && response.statusCode === 200){
        setNotificationCount(response.totalElements)
      } else {
        setNotificationCount(0);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  return (
    <div className='areaManagementHead px-2'>
    <CustomToast
    show={showToast}
    onClose={() => setShowToast(false)}
    delay={5000}
    message={toastMessage}
    toastType={toastType}
    />  
        <p className='headText1 mx-2 pt-1'>{pageHeader}</p>
        <div className='d-flex pt-2'>
            {/* <div className='notifiUserBg mx-1'></div> */}
            
            <div className='me-4 user-name'>
                <p className='boldText3 m-0'>{userName} [ {role} ]</p>
                <p className='sessionLogoutText'>Session Logout in <span className='sessionLogoutTime'>{formattedTime}</span></p>
            </div>
            <div style={{paddingTop : "15px"}}>
              <Notification notificationCount={notificationCount} />
            </div>
            <div>
              <button onClick={() => handleLogOut()} className='logoutBtn mx-4'></button>
            </div>
        </div>
    </div>
  )
}

export default PageHeader