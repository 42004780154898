import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'; 
import {BiSolidDashboard, BiFridge} from 'react-icons/bi'
import {GrClose} from 'react-icons/gr'
import {AiOutlineLeft} from 'react-icons/ai'
import {IoIosNotifications} from 'react-icons/io'
import {MdSensorDoor, MdPolicy, MdInventory2, MdOutlineError, MdImportantDevices} from 'react-icons/md'
import {FaCog, FaFingerprint, FaMicroscope, FaUserFriends} from 'react-icons/fa'
// import {BsQrCodeScan} from 'react-icons/bs'
import {BiQrScan} from 'react-icons/bi'
import { SiTestcafe } from "react-icons/si";
import {ImLab} from 'react-icons/im'
import {MdMotionPhotosAuto} from 'react-icons/md'
import {RiLeafFill, RiMicroscopeFill} from 'react-icons/ri'
import { useSelector } from 'react-redux';
import { RootState} from '../store/store';
import AddException from './common/addException';
import { RiUserSettingsFill } from "react-icons/ri";
import { GiGears, GiTestTubes, GiThermometerScale } from "react-icons/gi";
import { BsHouseAddFill } from "react-icons/bs";
import { AiOutlineAudit } from "react-icons/ai";
import { userService } from '../service/userService';
import { PiScales } from "react-icons/pi";
import { TbReportAnalytics } from "react-icons/tb";

const Navbar = () => {

const [activeTab, setActiveTab] = useState<number | null>();
const activeNotifi = useSelector((state: RootState) => state.notification.activeNotifi);
const [show, setShow] = useState(false);
const [show2, setShow2] = useState(false);
const [showRawMaterialModel, setShowRawMaterialModel] = useState(false)
const [showUserEndMenu, setShowUserEndMenu] = useState(false)
const [showAuditTrial, setAuditTrial] = useState(false)
const [showconfig, setShowConfig] = useState(false)
const [permission, setPermission] = useState<any>([])
const [showInventory, setShowInventory] = useState(false)

const [tab, setTab] = useState("1")

const navigate = useNavigate()
const location = useLocation();
const location_type = useSelector((state: RootState) => state.area.areaName);

useEffect(() => {

  var storedData = localStorage.getItem('userDetails');

  if(storedData) {
    var storedObject = JSON.parse(storedData);
    getPermissionByRole(storedObject[0].role_master_id)
  }
}, [])


  const handleTabClick = (index: number) => {
    setActiveTab(index);
    console.log("clicked")
    switch (index) {
      case 0:
        navigate('/adminDashboard');
        break;
      case 1:
        navigate('/areaManagement');
        break;
      case 2:
        navigate('/userManagement'); 
        break;
      case 3:
        navigate('/policyManagement');
        break;
      case 4:
        navigate('/plateInventory');
        break;
      case 6:
        navigate('/incubationArea');
        break;
      case 7:
        navigate('/labtestArea');
        break;
      case 8:
        navigate('/autoClave'); 
        break;
      case 9:
        navigate('/labOperatorDashboard');
        break;
      case 10:
        navigate('/labReport');
        break;
      case 11:
        navigate('/exceptionHandling');
        break;
      case 12:
        navigate('/scanArea');
        break;
     case 13:
        navigate('/environmental-flora');
        break;
      case 14:
        navigate('/equipmentCalibration');
        break; 
      case 15:
        navigate('/addRawMaterials');
        break;   
      case 16:
        navigate('/auditTrialReport');
        break;
      case 17:
        navigate('/userAccessReport');
        break;
      case 18:
        navigate('/rolesAndPermission');
        break;
      case 19:
        navigate('/addGradeType');
        break;
      case 20:
        navigate('/deviceRegistration');
        break;
      case 21:
        navigate('/logReport');
        break;
      case 22:
        navigate('/testTubeInventory');
        break;
      // Handle other cases as needed
      default:
        break;
    }
  };

  const handleRawMaterialClick = () => {
    setShowRawMaterialModel(true)
  }

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className={`sidebar ${activeNotifi ? 'sibebarActive' : ''}`}>   
      <div>
        <div className='mt-3 mb-5'>
            <p className='title'>MPATS</p>
            <p hidden={true} className='py-0 m-0 versionText'>v.1.5</p>
        </div>
        <div className='topContainer'>
          {/* admin section */}
          {permission?.includes("realtime_dashboard") && (
            <a onClick={() => handleTabClick(0)} className={location.pathname === '/adminDashboard' ? 'active': ''}>
                <BiSolidDashboard className={location.pathname === '/adminDashboard' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Dashboard</a>
          )} 

          {(permission?.includes("list_plates") || permission?.includes("list_plates")) && (
            <a onClick={() => setShowInventory(!showInventory)} className={showInventory ? 'groupDiv' : ''}>
                <FaMicroscope  className='sidebarIcon mx-2' /> Inventory</a>
          )}
           {showInventory && (
              <>
                {permission?.includes("list_plates") && (
                  <a onClick={() => handleTabClick(4)} className={location.pathname === '/plateInventory' ? 'active': ''} style={{paddingLeft: "40px"}}>
                      <MdInventory2 className={location.pathname === '/plateInventory' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Plate Inventory</a>
                )}

                {permission?.includes("list_plates") && (
                  <a onClick={() => handleTabClick(22)} className={location.pathname === '/testTubeInventory' ? 'active': ''} style={{paddingLeft: "40px"}}>
                      <GiTestTubes className={location.pathname === '/testTubeInventory' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Test Tube Inventory</a>
                )}
              </>
           )}

            {(permission?.includes("scan_incubation") || 
              permission?.includes("scan_labtest") || 
              permission?.includes("environmental_flora_identification") || 
              permission?.includes("scan_autoclave")) && (
                <a onClick={() => handleTabClick(12)} className={location.pathname === '/scanArea' ? 'active' : ''}>
                    <BiQrScan className={location.pathname === '/scanArea' ? 'sidebarIconActive mx-2' : 'sidebarIcon mx-2'} /> Scan Area / Equipment
                </a>
            )}
            {location_type === "12" && (
              <>
                {permission?.includes("scan_incubation") && (
                <a onClick={() => handleTabClick(6)} className={location.pathname === '/incubationArea' ? 'active': ''} style={{paddingLeft: "40px"}}>
                    <BiFridge className={location.pathname === '/incubationArea' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Incubation</a>
                )}
              </>
            )}
            {location_type === "8" && (
              <div>
                {permission?.includes("scan_labtest") && (
                <a onClick={() => handleTabClick(7)} className={location.pathname === '/labtestArea' ? 'active': ''} style={{paddingLeft: "40px"}}>
                    <RiMicroscopeFill className={location.pathname === '/labtestArea' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Lab Test Area</a>
                )}
                {permission?.includes("environmental_flora_identification") && (
                <a onClick={() => handleTabClick(13)} className={location.pathname === '/environmental-flora' ? 'active': ''} style={{paddingLeft: "40px"}}>
                    <RiLeafFill className={location.pathname === '/environmental-flora' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Environmental Flora</a>
                )}
              </div>
            )}
            {location_type === "9" && (
              <>
              {permission?.includes("scan_autoclave") && (
              <a onClick={() => handleTabClick(8)} className={location.pathname === '/autoClave' ? 'active': ''} style={{paddingLeft: "40px"}}>
                  <MdMotionPhotosAuto className={location.pathname === '/autoClave' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Autoclave</a>
              )}
              </>
            )}
            {permission?.includes("sample_type_test") && (
              <a onClick={() => handleRawMaterialClick()}>
                  <SiTestcafe className='sidebarIcon mx-2' /> Sample Type Test</a>
            )}

            {permission?.includes("list_exceptions") && (
            <a onClick={() => handleTabClick(11)} className={location.pathname === '/exceptionHandling' ? 'active': ''}>
                <MdOutlineError className={location.pathname === '/exceptionHandling' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Exception Handling</a>
            )}

            {/* lab operator section */}
            {permission?.includes("qa_approval") && (
            <a onClick={() => handleTabClick(10)} className={location.pathname === '/labReport' ? 'active': ''}>
                <ImLab className={location.pathname === '/labReport' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Lab Approval</a>
            )}

          {((permission?.includes("list_roles") || permission?.includes("list_user") || permission?.includes("list_policy")) || permission?.includes("list_area")
           || permission?.includes("list_equipment") || permission?.includes("list_rawmaterial_type") || permission?.includes("list_grade_type")) && (
            <a onClick={() => setShowUserEndMenu(!showUserEndMenu)} className={showUserEndMenu ? 'groupDiv' : ''}>
                <RiUserSettingsFill className='sidebarIcon mx-2' /> Configuration</a>
          )}
            {showUserEndMenu && (
            <>

            {permission?.includes("list_roles") && (
              <a onClick={() => handleTabClick(18)} className={location.pathname === '/rolesAndPermission' ? 'active': ''} style={{paddingLeft: "40px"}}>
                  <FaFingerprint className={location.pathname === '/rolesAndPermission' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Roles and Permission</a>
              )}

            {permission?.includes("list_user") && (
            <a onClick={() => handleTabClick(2)} className={location.pathname === '/userManagement' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <FaUserFriends className={location.pathname === '/userManagement' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />User Management</a>
            )}
            {permission?.includes("list_policy") && (
            <a onClick={() => handleTabClick(3)} className={location.pathname === '/policyManagement' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <MdPolicy className={location.pathname === '/policyManagement' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Policy Management</a>
            )}
            {permission?.includes("list_area") && (
            <a onClick={() => handleTabClick(1)} className={location.pathname === '/areaManagement' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <MdSensorDoor className={location.pathname === '/areaManagement' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Area / Location Management</a>
            )}
            {permission?.includes("list_equipment") && (
              <a onClick={() => handleTabClick(14)} className={location.pathname === '/equipmentCalibration' ? 'active': ''} style={{paddingLeft: "40px"}}>
                  <GiThermometerScale className={location.pathname === '/equipmentCalibration' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Equipment Configuration</a>
            )}
            {permission?.includes("list_rawmaterial_type") && (
              <a onClick={() => handleTabClick(15)} className={location.pathname === '/addRawMaterials' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <BsHouseAddFill className={location.pathname === '/addRawMaterials' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Sample Type Configuration</a>
            )}
             {permission?.includes("list_grade_type") && (
              <a onClick={() => handleTabClick(19)} className={location.pathname === '/addGradeType' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <PiScales className={location.pathname === '/addGradeType' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Limits Configuration</a>
            )}
            </>
            )}
            {permission?.includes("list_device") && (
            <a onClick={() => setShowConfig(!showconfig)} className={showconfig ? 'groupDiv' : ''}>
                <FaCog  className='sidebarIcon mx-2' />Application Configuration</a>
            )}
                {showconfig && (
            <>
            
               {permission?.includes("list_device") && (
              <a onClick={() => handleTabClick(20)} className={location.pathname === '/deviceRegistration' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <MdImportantDevices className={location.pathname === '/deviceRegistration' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Device Registration</a>
              )}
            </>
            )}
          {((permission?.includes("view_configuration_change_report") || permission?.includes("view_user_access_report")) || permission?.includes("list_log_report")) && (
            <a onClick={() => setAuditTrial(!showAuditTrial)} className={showAuditTrial ? 'groupDiv' : ''}>
                <AiOutlineAudit className='sidebarIcon mx-2' />Audit Trial Logs</a>
          )}
                {showAuditTrial && (
            <>
            {permission?.includes("view_configuration_change_report") && (
              <a onClick={() => handleTabClick(16)} className={location.pathname === '/auditTrialReport' ? 'active': ''} style={{paddingLeft: "40px"}}>
                  <GiGears className={location.pathname === '/auditTrialReport' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} /> Configuration Change Report</a>
            )}
            {permission?.includes("view_user_access_report") && (
              <a onClick={() => handleTabClick(17)} className={location.pathname === '/userAccessReport' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <FaUserFriends className={location.pathname === '/userAccessReport' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />User Access Report</a>
            )}
            {permission?.includes("list_log_report") && (
              <a onClick={() => handleTabClick(21)} className={location.pathname === '/logReport' ? 'active': ''} style={{paddingLeft: "40px"}}>
                <TbReportAnalytics  className={location.pathname === '/logReport' ? 'sidebarIconActive mx-2': 'sidebarIcon mx-2'} />Log Report</a>
            )}
            </>
            )}        
          </div>
      </div>

      <AddException showModel={showRawMaterialModel} isPlateSampling={false} isRawMaterialTest={true} setShowModel={() => setShowRawMaterialModel(false)} />

    </div>
  )
}

export default Navbar;