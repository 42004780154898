import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import CustomToast from "../../../../widgets/toaster";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { columns } from "./partials/columns";
import { rolesAndPermission } from "../../../../service/rolesAndPermission";
import InnerPageRoles from "./partials/innerPage";
import AlertModal from "../../../../widgets/alertModal";
import { userService } from "../../../../service/userService";

const RolesManagement = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [rolesList, setrolesList] = useState<Array<any>>([]);
  const [userId, setUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [tab , setTab] = useState("1");
  const [editId , setEditId] = useState("");
  const [role, setRole] = useState("");
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [id , setId] = useState("");
  const [useeffect , setUseEffect] = useState(false);
  const [statusAlert , setStatusAlert] = useState(false);
  const [roleStatusId, setRoleStatusId] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [modalName, setModalName] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [reason, setReason] = useState("");

  
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listRolesTableList();
  }, []);

  const listRolesTableList = async () => {
    try {
      const response = await rolesAndPermission.listRoles(page, pageSize);
      console.log(response.data, "response.data---");
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.title,
                  status: element.status,
                  id: element.id,
                };
              }
            );
            setrolesList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  useEffect(() => {
    listRolesTableList();
    setUseEffectRequired(false);
    setUseEffect(false)
  }, [useeffect, useEffectRequired]);

  const handleAddClick = () => {
    setTab("2")
    setModalName("Add")
  };

  const handleEditClick = (id: string) => {
    const filteredUserDetails = rolesList.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
    getRolesById(selectedIds)
    setModalName("Update")
  }

  const getRolesById = async (selectedIds: string) => {
    try {
      const response = await rolesAndPermission.editRolesandPermission(selectedIds);
      if(response.status && response.statusCode === 200){
        setTab("2")
        setId(response.data.id)
        setRole(response.data.title)
        setSelectedCodes(response.data.permissions);
        setReason(response.data.reason);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handleStatusChange = (area_id:any , status: string) => {
    setStatusAlert(true)
    setRoleStatusId(area_id)
      if (status === "active") {
        setUpdatedStatus("inactive")
      } else {
        setUpdatedStatus("active")
      }
  }

  const handleStatusChangeSubmit = async () => {
    try {
      const response = await rolesAndPermission.changeRoleStatus(roleStatusId, updatedStatus , "Role Status");
      
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      setUpdatedStatus("")
      setRoleStatusId("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }

   //get permission by role 
   const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Roles and Permission" />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      {tab === "1" && (
      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={rolesList}
            columns={columns}
            isEditable={permission?.includes("edit_roles")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            isToggleStatus={permission?.includes("edit_roles")}
            tableHead="Roles"
            showBatchFilter={false}
            showSerielNoFilter={false}
            addButton={permission?.includes("add_roles")}
            addButtonText="Add Role"
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
            onUseEffectRequired={() => setUseEffectRequired(true)}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
      )}

      {tab === "2" && (
      <div className="me-3">
         <InnerPageRoles 
         setTab={setTab}
         setRole={setRole}
         role={role}
         reason={reason}
         setReason={setReason}
         setSelectedCodes={setSelectedCodes}
         selectedCodes={selectedCodes}
         id={id}
         setUseEffect={setUseEffect}
         setShowToast={setShowToast}
         setToastMessage={setToastMessage}
         setToastType={setToastType}
         setId={setId}
         setModalName={setModalName}
         modalName={modalName}
         />
      </div>
      )}


      <AlertModal 
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title='Alert'
        message={`Change Role Status`}
        onConfirm={handleStatusChangeSubmit}
        />
    </div>
  );
};

export default RolesManagement;
