import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import translationData from '../../../../locales/dashboardTranslation.json'
import DownloadPdf from "../../../../widgets/downloadPdf";



const InnerPageAutoclave = () => {
    const { autoclaveId } = useParams();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const displayName = getDisplayName(Number(autoclaveId));
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");

    function getDisplayName(id: any) {
        const AutoclaveDataHead = translationData.AutoclaveDataHead;
        const autoclave = AutoclaveDataHead.find(item => item.id === id);
        return autoclave ? autoclave.displayName : "";
    }

    //column header for exposure view
    const columns : any  = [
        {
          Header: "Sl.No",
          accessor: "slno",
        },
        {
          Header: "dashboard.autoclaveInprogressColumns.plateSerialNumber",
          accessor: "plate_serial_number",
        },
        {
          Header: "dashboard.autoclaveInprogressColumns.batchNumber",
          accessor: "batch_number",
        },
        {
            Header: "dashboard.autoclaveInprogressColumns.autoclaveName",
            accessor: "location_name",
        },
        {
          Header: "dashboard.autoclaveInprogressColumns.autoclaveStartDateTime",
          accessor: "autoclave_date",
        },
        {
          Header: "dashboard.autoclaveInprogressColumns.autoclaveStartedBy",
          accessor: "autoclave_started_by",
        },
        {
          Header: "dashboard.autoclaveInprogressColumns.autoclaveEndDateTime",
          accessor: "ended_date",
        },
        {
            Header: "dashboard.autoclaveInprogressColumns.autoclaveEndedBy",
            accessor: "ended_by",
        },
        {
            Header: "dashboard.autoclaveInprogressColumns.discrepenciesIfAny",
            accessor: "descrepencies_any",
        },
        {
            Header: "dashboard.autoclaveInprogressColumns.deviationsIfAny",
            accessor: "deviations_any",
        },
        {
            Header: "dashboard.autoclaveInprogressColumns.status",
            accessor: "status",
        },
    ]
  
      //handle pagination changes 
      const handleSchedulePageChange = (newPage : any, pageSize?: number) => {
        setPage(newPage);
        if(pageSize) {
          setPageSize(pageSize)
        }
        setUseEffectRequired(true)
      };

    //api call to get the data into inner pages 
    const fetchData = async () => {
        try {
          let response;
          switch (autoclaveId) {
            case "3":
              response = await dashboardServices.getAutoclaveInprogressPageView(page, pageSize,sort,order);
              break;
            case "4":
              response = await dashboardServices.getAutoclaveCompletedPageView(page, pageSize,sort,order);
              break;
            default:
              break;
          }
      
          if (response.status && response.statusCode === 200) {
            try {
              if (Array.isArray(response.data) && response.data.length > 0) {
                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
                let rowData = response.data.map((element: any, index: any) => {
                  
                  return {
                    slno: index + 1, 
                    plate_serial_number : element.plate_serial_number,
                    batch_number: element.plate_batch_number,
                    location_name: element.location_name,
                    autoclave_date: element.start_time,
                    autoclave_started_by: element.started_user,
                    ended_date: element.end_time,    
                    ended_by: element.ended_user,
                    descrepencies_any: element.descrepencies_any,
                    deviations_any: element.deviations_any,
                    status: element.status,
                  };
                });
                setScheduleDetails(rowData);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            setScheduleDetails([]);
          }
        } catch (error) {
          console.error('API call error', error);
        }
      };


    useEffect(() => {
        fetchData();
    }, [autoclaveId]);


      useEffect(() => {
        fetchData();
        setUseEffectRequired(false)
    }, [useEffectRequired])

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

      return (
        <div>
          <HeaderSection />
          <PageHeader pageHeader={t('dashboard.title')} />
          <div id='dashWrap'>
          <nav aria-label="breadcrumb" className="breadcrumb-container row">
            <div className='col-10 d-flex'>
              <ol className="breadcrumb">
                <li className="breadcrumb-item dashboard-hover">
                  <Link to="/*">{t('dashboard.title')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {displayName}
                </li>
              </ol>
            </div>
            <div className='col-2 d-flex'>
              <DownloadPdf divId="dashWrap" reportHead="Autoclave Report" />
            </div>
          </nav>
    
          <div className='col-md-12 table-footer'>
                    <CustomTable
                        tableHead={displayName} 
                        data={scheduleDetails} 
                        columns={columns} 
                        isEditable={false} 
                        isActionVisible={false} 
                        isViewVisible={false} 
                        totalElements={totalElements}
                        isSeachable={false}
                        isToggleStatus={false}   
                        onSortClick={handleSort}   
                        onUseEffectRequired={() => setUseEffectRequired(true)} 
                    />
            </div>
            <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handleSchedulePageChange}
                  />
            </div>
          </div>
        </div>
      );
  };
  
  export default InnerPageAutoclave;
  