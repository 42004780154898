// import moment from 'moment';
// import React, { useState, useEffect, useRef } from 'react';

// interface CountdownTimerProps {
//   endTime: string; // Define the expected type of columns
//   status?: string;
// }

// const CountdownTimer: React.FC<CountdownTimerProps> = (params:any) => {
//   const [minutesLeft, setMinutesLeft] = useState(0);
//   const [minutesPassed, setMinutesPassed] = useState(0);
 
//   const endTime = params.endTime
//   const status = params.status

//   useEffect(() => {
//     const updateMinutesLeft = () => {
//       const now = new Date();
//       let end: Date | null = null;
//       const [hours, minutes] = endTime.split(':');
  
//       if (hours && minutes) {
//         end = new Date();
//         end.setHours(parseInt(hours, 10));
//         end.setMinutes(parseInt(minutes, 10));
//       }
  
//       if (end) {
//         const timeDiff = (end as any) - (now as any); // Explicitly cast to 'any' and then calculate
//         const remainingMinutes = Math.floor(timeDiff / 60000);
  
//         if (remainingMinutes >= 0) {
//           setMinutesLeft(remainingMinutes);
//         } else {
//           // End time has passed
//           setMinutesLeft(0);
//         }
//       }
//     };
  
//     updateMinutesLeft(); // Call the function once on component mount
  
//     const interval = setInterval(updateMinutesLeft, 1000); // Update every second
  
//     return () => clearInterval(interval);
//   }, [endTime]);


//   useEffect(() => {
//     const interval = setInterval(() => {
//       const currentTime = new Date();
//       const endTimeArray = endTime.split(":");
//       const endHour = parseInt(endTimeArray[0]);
//       const endMinute = parseInt(endTimeArray[1]);

//       const currentHour = currentTime.getHours();
//       const currentMinute = currentTime.getMinutes();
      

//       let minutesPassed = 0;
     
//       if (currentHour > endHour || (currentHour === endHour && currentMinute >= endMinute)) {
//         minutesPassed = (currentHour - endHour) * 60 + (currentMinute - endMinute);
//       }

//       setMinutesPassed(minutesPassed);
//     }, 1000); // Update every second

//     return () => clearInterval(interval);
//   }, [endTime]);

//   console.log(minutesLeft, minutesPassed, "minutesLeft")

  
//   return (
//     <div>
//       {!minutesLeft? (
//         <>
//         {/* <div className='overdue'>Over Due</div> */}
//         {status === "InProgress" && (<div className='overdue'>Over Due</div>)}
//         {status === "Pending" && (<div className='overdue'>Not Started</div>)}
//         {status === "Completed" && (<div className='overdue'>00:00:00</div>)}
//         </>
//       ) : (
//         <div>{moment.utc(minutesLeft * 60000).format("HH:mm:ss")}</div>    
//       )}
//     </div>
//   );
// };

// export default CountdownTimer;

import moment from 'moment';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  endTime: string;
  status?: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = (props: CountdownTimerProps) => {
  const { endTime, status } = props;
  const [timeLeft, setTimeLeft] = useState(moment.duration(0));

  useEffect(() => {
    const updateTimer = () => {
      const now = moment();
      const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');
      const diff = moment.duration(end.diff(now));

      if (diff.asSeconds() >= 0) {
        setTimeLeft(diff);
      } else {
        // End time has passed
        setTimeLeft(moment.duration(0));
      }
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  const formatTime = (duration: moment.Duration) => {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  return (
    <div>
      {!timeLeft.asSeconds() ? (
        <>
          {status === 'In Progress' && <div className='overdue'>Over Due</div>}
          {status === 'Pending' && <div className='overdue'>Not Started</div>}
          {status === 'Completed' && <div className='overdue'>00:00:00</div>}
        </>
      ) : (
        <div className='counter-timer'>{formatTime(timeLeft)}</div>
      )}
    </div>
  );
};

export default CountdownTimer;
