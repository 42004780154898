import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { TfiClose } from 'react-icons/tfi';
import { policyService } from '../../../../service/policyServices';
import AlertModal from '../../../../widgets/alertModal';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../service/plateServices';
import Select from 'react-select';
import { areaService } from '../../../../service/areaServices';


interface Incubator {
  id: string;
  name: string;
}

interface AddPolicyModalProps {
    showAddModal: boolean;
    setShowAddUserAlert: () => void;
    setShowAddModal: () => void;
    setSelectedPolicyId: (id: string) => void;
    departmentList: any[];
    areas: any[];
    policyId?: string;
    modalName: string;
    policySubmitted: boolean
    setSelectedIncubators:(value: Incubator[]) => void;
    setPolicySubmitted: (value : boolean) => void;
    selectedIncubators: Incubator[];
    setAddPolicyData: React.Dispatch<React.SetStateAction<{ 
      id:string,
      policyName: string,
      areaType: string,
      policyDuration: string,
      isEquipmentCalReq: string,
      lastCalDate: string,
      nextCalDate: string,
      incubationDurFromExp: string,
      incubationCount: string,
      colCountMinRange: string,
      colCountMaxRange: string,
      isColCountManual: string,
      labTestDurFromInc: string,
      AutoclaveDurFromLab: string,
      isAutoclaveStopMandatory: string,
      fingerDabType: string,
      fingerDabDepartment: string,
      incubationTemparature: string,
      incubationTemparatureMax: string,
      equipmentCalibrationDays: string,
      plateType: string,
      reuseUnUsedPlates: string,
      incubationOrder: string,
      reason: string
      }>>;
    addPolicyData: { 
      policyName: string,
      areaType: string,
      policyDuration: string,
      isEquipmentCalReq: string,
      lastCalDate: string,
      nextCalDate: string,
      incubationDurFromExp: string,
      incubationCount: string,
      colCountMinRange: string,
      colCountMaxRange: string,
      isColCountManual: string,
      labTestDurFromInc: string,
      AutoclaveDurFromLab: string,
      isAutoclaveStopMandatory: string,
      fingerDabType: string,
      fingerDabDepartment: string,
      plateType?: string,
      reuseUnUsedPlates?: string,
      incubationOrder?: string,
      equipmentCalibrationDays?: string,
      reason?: string,
      id?: string
    };


}

const AddLocationPolicyModal: React.FC<AddPolicyModalProps> = ({
    showAddModal,
    setShowAddUserAlert,
    setShowAddModal,
    setSelectedPolicyId,
    setAddPolicyData,
    setSelectedIncubators,
    setPolicySubmitted,
    selectedIncubators,
    areas,
    departmentList,
    policyId,
    addPolicyData,
    modalName,
    policySubmitted,
}) => {


    interface Errors {
        policyName?: string,
        areaType?: string,
        policyDuration?: string,
        isEquipmentCalReq?: string,
        lastCalDate?: string,
        nextCalDate?: string,
        incubationDurFromExp?: string,
        incubationCount?: string,
        colCountMinRange?: string,
        colCountMaxRange?: string,
        isColCountManual?: string,
        labTestDurFromInc?: string,
        AutoclaveDurFromLab?: string,
        isAutoclaveStopMandatory?: string,
        fingerDapType?: string,
        fingerDabDepartment?: string,
        equipmentCalibrationDays?: string,
        selectedIncubators?:string,
        reason?: string,
      }
      const [errors, setErrors] = useState<Errors>({});
      const { t } = useTranslation();
      // const [defaultIncubators, setDefaultIncubators] = useState<Incubator[]>([
      //   { incubator_id: "1", incubator_name: "Incubator 1" },
      //   { incubator_id: "2", incubator_name: "Incubator 2" },
      //   { incubator_id: "3", incubator_name: "Incubator 3" },
      // ]);
    
      const [plateTypes, setPlateTypes] = useState([]);
      const [plateGrades, setPlateGrades] = useState([]);
      const [incubatorList, setIncubatorList] = useState<Incubator[]>([]);
      const [fingurDabTypes, setFingurDabTypes] = useState([]);






      useEffect(() => {
        getPlateTypeList();
        getPlateGradeList();
        getIncubatorList();
        getFingerDabTypeList();
        setPolicySubmitted(false)
      }, [policySubmitted])

      useEffect(() => {
        if(policyId) {
          getPolicyById(policyId)
        }
      }, [policyId])

      useEffect(() => {
        const selectedIncubatorIdsString = selectedIncubators
          .map((incubator) => incubator.id)
          .join(',');

        setAddPolicyData((prevData) => ({
            ...prevData,
            incubationOrder: selectedIncubatorIdsString
        }));
      }, [selectedIncubators])


      const handleAddPolicyChange = (e : any) => {
        const { name, value } = e.target;
        console.log(name, value, "name-value")
        const errors: Errors = {};


        if (name === 'lastCalDate' || name === 'nextCalDate') {
          const currentDate = new Date().toISOString().split('T')[0];
      
          // if (name === 'lastCalDate' && value > currentDate) {
          //   errors.lastCalDate = `select a previous date`;
          // }

          // if (name === 'nextCalDate' && !value) {
          //   errors.nextCalDate = `please enter a due count`;
          // }
        }

        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors({});
        }

        setAddPolicyData((prevFormData : any) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      //handle policy management alert
      const handlePolicyAddAlert = () => {
        console.log(addPolicyData, " add policy data")
        const errors: Errors = {};
          // if (!addPolicyData.policyDuration) {
          //   errors.policyDuration = "Please enter Policy Duration";
          // }
          if (!addPolicyData.policyName) {
            errors.policyName = "Please enter Policy Name";
          }
          if (!addPolicyData.areaType) {
            errors.areaType = "Please select Area Type";
          }
          if (!addPolicyData.incubationOrder) {
              errors.selectedIncubators = "Please Select Incubation Order";
          }
          if (addPolicyData.id && !addPolicyData.reason) {
            errors.reason = "Please enter Reason";
          }
          //   if(!addPolicyData.incubationCount){
          //     errors.incubationCount = "Please enter Incubation Count";
          //   }
          // }
          // if (addPolicyData.areaType === "8") {
          //   if(!addPolicyData.colCountMinRange){
          //     errors.colCountMinRange = "Please enter Min Colony Count";
          //   }
          //   if(!addPolicyData.colCountMaxRange){
          //     errors.colCountMaxRange = "Please enter Max Colony Count";
          //   }
          // }
          // if (addPolicyData.areaType === "10") {
          //   if(!addPolicyData.fingerDapType){
          //     errors.fingerDapType = "Please select finger dab type";
          //   }
          //   if(!addPolicyData.fingerDabDepartment){
          //     errors.fingerDabDepartment = "Please select finger dab department";
          //   }
          // }
          if (addPolicyData.isEquipmentCalReq == "1") {
            if(!addPolicyData.lastCalDate){
              errors.lastCalDate = "Enter Last Calibrated Date";
            }
            // if(!addPolicyData.nextCalDate){
            //   errors.nextCalDate = "Enter Next Calibration Date";
            // }
            if(!addPolicyData.equipmentCalibrationDays){
              errors.equipmentCalibrationDays = "Enter Duration Day Count";
            }
          }
          
          
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            console.log(errors)
            return;
          }
          setShowAddUserAlert()
          setErrors({});
      }

      //on close button click 
      const addScheduleClose = () => {
        setShowAddModal()
        setErrors({})
        setSelectedPolicyId("")
        setSelectedIncubators([])
        getIncubatorList()
        setAddPolicyData({
          id: "",
          policyName: "",
          areaType: "",
          policyDuration: "0",
          isEquipmentCalReq: "0",
          lastCalDate: "",
          nextCalDate: "",
          incubationDurFromExp: "",
          incubationCount: "",
          colCountMinRange: "",
          colCountMaxRange: "",
          isColCountManual: "0",
          labTestDurFromInc: "0",
          AutoclaveDurFromLab: "0",
          isAutoclaveStopMandatory: "0",
          fingerDabType: "",
          fingerDabDepartment: "",
          incubationTemparature: "",
          equipmentCalibrationDays: "",
          plateType:"",
          reuseUnUsedPlates:"",
          incubationOrder:"",
          reason: "",
          incubationTemparatureMax: ""
        });
      }

      //api call to get the policy id 
      const getPolicyById = async (userId:string) => {
        try {
          const response = await policyService.getPolicyId(userId);
          if(response.status && response.statusCode === 200){

            const filteredIncubators = incubatorList?.filter(incubator => {
              const orderIds = response?.data?.incubation_order?.split(',').map((id: any ) => id.trim());
              return orderIds?.includes(incubator.id);
            });

            const updatedIncubatorList = incubatorList?.filter(incubator => !filteredIncubators?.includes(incubator));

            setSelectedIncubators(filteredIncubators)
            setIncubatorList(updatedIncubatorList)

              setAddPolicyData({
                policyName: response.data.activity_name,
                areaType: response.data.policy_area_type,
                policyDuration: response.data.duration,
                isEquipmentCalReq: response.data.equipment_calibration,
                lastCalDate: response.data.last_calibration_date,
                nextCalDate: response.data.equipment_calibration_days,
                incubationDurFromExp: response.data.inc_duration_from_ep,
                incubationCount: response.data.inc_activity_count,
                colCountMinRange: response.data.lab_min_range,
                colCountMaxRange: response.data.lab_max_range,
                isColCountManual: response.data.lab_colony_count_entry_type,
                labTestDurFromInc: response.data.lab_duration_from_inc,
                AutoclaveDurFromLab: response.data.aut_duration_from_lab,
                isAutoclaveStopMandatory: response.data.aut_end_flag,
                fingerDabType: response.data.fin_test_type,
                fingerDabDepartment: response.data.fin_dipartment_id,
                incubationTemparature: response.data.inc_temparature,
                equipmentCalibrationDays: response.data.equipment_calibration_days,
                id: response.data.id,
                plateType: response.data.plate_types,
                reuseUnUsedPlates: response.data.plate_grades,
                incubationOrder: response.data.incubation_order,
                reason: response.data.reason,
                incubationTemparatureMax: response.data.inc_temparature_max,
              });
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      // interface Incubator {
      //   id: string;
      //   name: string;
      // }
      
      interface IncubatorListProps {
        incubators: Incubator[];
        onIncubatorClick: (incubator: Incubator) => void;
      }

      const IncubatorList: React.FC<IncubatorListProps> = ({ incubators, onIncubatorClick }) => {
        return (
          <div className='mx-3 p-2' style={{border:"1px solid grey", height: "25vh", width:"150px", overflow:"scroll"}}>
            {incubators.slice().reverse().map((incubator) => (
              <p className='my-1' style={{background: "grey", color: "white", fontSize:"small"}} key={incubator.id} onClick={() => onIncubatorClick(incubator)}>
                {incubator.name}
              </p>
            ))}
          </div>
        );
      };

      const handleIncubatorClick = (clickedIncubator: Incubator) => {
        const updatedDefaultIncubators = incubatorList.filter(
          (incubator) => incubator.id !== clickedIncubator.id
        );

        const updatedSelectedIncubators = selectedIncubators.filter(
          (incubator) => incubator.id !== clickedIncubator.id
        );
    
        setIncubatorList(updatedDefaultIncubators);
        setSelectedIncubators([clickedIncubator, ...selectedIncubators]);

      };
    
      const handleSelectedIncubatorClick = (clickedIncubator: Incubator) => {
        const updatedSelectedIncubators = selectedIncubators.filter(
          (incubator) => incubator.id !== clickedIncubator.id
        );
    
        setSelectedIncubators(updatedSelectedIncubators);
        setIncubatorList([clickedIncubator, ...incubatorList]);

      };

      //api call to get the plate types 
      const getPlateTypeList = async () => {
        try {
          const response = await userService.listAllPlateTypes();
          if(response.status && response.statusCode === 200){
            setPlateTypes(response.data)
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      //api call to get the plate grades 
      const getPlateGradeList = async () => {
        try {
          const response = await userService.listAllPlateGrades();
          if(response.status && response.statusCode === 200){
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                label: element.grade,
                value : element.id,
              };
            });
            setPlateGrades(rowData);
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      //api call to get the incubator list 
      const getIncubatorList = async () => {
        try {
          const response = await areaService.getIncubatorList();
          if(response.status && response.statusCode === 200){
            setIncubatorList(response.data)
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      //api call to get the finderDab list 
      const getFingerDabTypeList = async () => {
        try {
          const response = await userService.listFingerDabAssessmentTypes();
          if(response.status && response.statusCode === 200){
            setFingurDabTypes(response.data)
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      //add reuse unused plate change handle function
      const handleReuseUnusedPlate = (options:any) => {
        if (options) {
          const valuesString = options.map((option:any) => option.value).join(',');
          setAddPolicyData((prevData) => ({
            ...prevData,  
            reuseUnUsedPlates: valuesString 
          }));
        }
      }



  return (
    <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
              onHide={() => addScheduleClose()}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div onClick={() => addScheduleClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                    <div className="form-group my-1">
                      <label htmlFor="">{t('policyManagement.name')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                        id="policyName"
                        value={addPolicyData.policyName}
                        name="policyName"
                        type='text'
                        placeholder='Policy Name'
                        className="placeholder-style"
                        onChange={handleAddPolicyChange}
                      />
                      {errors.policyName && <p className="errorText">{errors.policyName}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="">{t('policyManagement.locationType')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="areaType"
                        value={addPolicyData.areaType}
                        name="areaType"
                        className="placeholder-style"
                        onChange={handleAddPolicyChange} 
                      >
                        <option value="">--select--</option>
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.title}
                          </option>
                        ))}
                      </select> 
                      {errors.areaType && <p className="errorText">{errors.areaType}</p>}
                    </div>


                    {addPolicyData.areaType === '10' ?
                    (<div>
                      <label>Finger Dab Type</label>
                      <br />
                      <select
                        id="fingerDabType"
                        value={addPolicyData.fingerDabType}
                        name="fingerDabType"
                        onChange={handleAddPolicyChange} 
                       >
                        <option value="">select</option>
                        {fingurDabTypes.map((obj : any, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.expose_type}
                          </option>
                      ))}
                      </select>
                      {/* {errors.colCountMinRange && <p className="errorText">{errors.colCountMinRange}</p>}      */}
                    </div>) : 
                    ( <div className="form-group my-1">
                      <label>{t('policyManagement.duration')}</label>
                      <br />
                      <input 
                        id="policyDuration"
                        value={addPolicyData.policyDuration}
                        name="policyDuration"
                        type='text'
                        className="placeholder-style"
                        onChange={handleAddPolicyChange} 
                      />
                      {errors.policyDuration && <p className="errorText">{errors.policyDuration}</p>}     
                    </div>
                    )}

                  </div>

                  <div className="selectWrap-new" style={{visibility:"hidden"}}>                
                    <div className="form-group my-1 mx-3 px-3">
                      <label>{t('policyManagement.caliberation')}</label>
                      <div className="radio-container">
                      <input
                        type="radio"
                        id="isEquipmentCalReqYes" // Unique id for the "Yes" option
                        name="isEquipmentCalReq"
                        value="1" // Assign value 1 for "Yes"
                        checked={addPolicyData.isEquipmentCalReq === "1"}
                        onChange={handleAddPolicyChange}
                        />
                      <label htmlFor="isEquipmentCalReqYes">Yes</label>
                      <input
                        type="radio"
                        id="isEquipmentCalReqNo" // Unique id for the "No" option
                        name="isEquipmentCalReq"
                        value="0" // Assign value 0 for "No"
                        checked={addPolicyData.isEquipmentCalReq === "0"}
                        onChange={handleAddPolicyChange}
                        />
                      <label htmlFor="isEquipmentCalReqNo">No</label>
                      </div>
                    </div>

                    {addPolicyData.isEquipmentCalReq === '1' && (
                          <div className="form-group my-1 mx-4 px-4">
                            <label>{t('policyManagement.last_caliberated')} <span style={{ color: 'red' }}>*</span></label>
                            <br/>
                            <input
                              id="lastCalDate"
                              value={addPolicyData.lastCalDate}
                              name="lastCalDate"
                              type="date"
                              className="placeholder-style"
                              onChange={handleAddPolicyChange}
                            />
                            {errors.lastCalDate && <p className="errorText">{errors.lastCalDate}</p>}
                          </div>
                       )}   
                      {addPolicyData.isEquipmentCalReq === '1' && (
                          <div className="form-group my-1 mx-3 px-3">
                            <label>{t('policyManagement.next_caliberated')} <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <input
                              id="equipmentCalibrationDays"
                              value={addPolicyData.equipmentCalibrationDays}
                              name="equipmentCalibrationDays"
                              type="text"
                              className="placeholder-style"
                              placeholder="Calibration Due in (Days)"
                              onChange={handleAddPolicyChange}
                            />
                            {errors.equipmentCalibrationDays && <p className="errorText">{errors.equipmentCalibrationDays}</p>}
                          </div>
                      )}
                  </div>


                 <div className="selectWrap-new">
                  <div className="form-group my-1 mx-3 px-2">
                    <label>Plate Types</label>
                    <br />
                    <select
                      id="plateType"
                      value={addPolicyData.plateType}
                      name="plateType"
                      onChange={handleAddPolicyChange} 
                    >
                      <option value="">select</option>
                      {plateTypes.map((obj : any, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.plate_type}
                          </option>
                      ))}
                    </select>
                    {/* {errors.locationType && <p className="errorText">{errors.locationType}</p>} */}
      
                  </div>

                  <div className="form-group my-1 mx-5 px-2">
                    <label htmlFor="">Reuse Unused Plates</label>
                    <br />
                    <Select
                      options={plateGrades}
                      onChange={handleReuseUnusedPlate}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      placeholder="Select plate grades"
                      // className='multiSelectRole'
                      value={plateGrades.filter((grade : any) => addPolicyData?.reuseUnUsedPlates?.split(',').includes(String(grade.value)))}
                    />
                    {/* {errors.locationName && <p className="errorText">{errors.locationName}</p>} */}
                  </div>
                </div>

                <div className="d-flex my-2">
                  <div className='form-group my-3 mx-3 px-2' style={{marginLeft:"5%"}}>
                    <p> Incubator List</p>
                    <IncubatorList incubators={incubatorList} onIncubatorClick={handleIncubatorClick} />                
                  </div>

                  <div className='form-group my-3 mx-5 px-2' style={{marginLeft:"10%"}}>
                    <p>Incubation Order <span style={{ color: 'red' }}>*</span></p>
                    <IncubatorList incubators={selectedIncubators} onIncubatorClick={handleSelectedIncubatorClick} />
                    {errors.selectedIncubators && <p className="errorText">{errors.selectedIncubators}</p>}
                  </div>
                  {/* <div>
                  {errors.selectedIncubators && <p className="errorText">{errors.selectedIncubators}</p>}
                  </div> */}

                  <div className="selectWrap-new">
                    {addPolicyData.id && (
                    <div className="form-group my-1 mx-3 px-2">
                        <label htmlFor="" className='ReasonLabel'>{t('policyManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                            id="reason"
                            value={addPolicyData.reason}
                            name="reason"
                            type='text'
                            placeholder={t('policyManagement.reason')}
                            className="reasonClass"
                            onChange={handleAddPolicyChange} 
                        />
                        {errors.reason && <p className="errorText">{errors.reason}</p>}
                    </div>
                    )}
                  </div>

                </div>
                

          </div>
              <div className="bottomArea">
            <button onClick={() => addScheduleClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handlePolicyAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
            {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
          </div>
            </div>
</Modal>
  )
}

export default AddLocationPolicyModal;