import HeaderSection from "../../../../common/headerSection";
import PageHeader from "../../../../common/pageHeader";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ParetoChart from "../../partials/paretoChart";
import { useEffect, useState } from "react";
import Chart from "../../partials/chart";
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import CustomTable from "../../../../../widgets/table";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ScheduleSummary, DiscrepancySummary, ExceptionSummary} from '../Violation/columns';
import DownloadPdf from "../../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";

const LandingComponent = () => {
  const navigate = useNavigate();
  const { bulletId } = useParams<{ bulletId: string }>();
  const { trendBy } = useParams<{ trendBy: string }>();
  const { t } = useTranslation();
  const lowercasedTrendBy = trendBy ? trendBy.charAt(0).toLowerCase() + trendBy.slice(1) : '';
  const chartData = [200, 150, 150, 100, 150, 200, 140];
  const chartLabels = ["wk1", "wk2", "wk3", "wk4", "wk5", "wk6", "wk7"];
  const lineChartData1 = [190, 150, 150, 100, 150, 180, 140];
  const lineChartData2 = [140, 100, 100, 50, 100, 100, 70];
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [timeRange, setTimeRange] = useState("monthly");
  const [chartType, setChartType] = useState("bar");
  const [xaxisRange, setXaxisRange] = useState(lowercasedTrendBy)
  const [selectedGraph, setSelectedGraph] = useState<string>("Violation");
  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    trendType: "",
    shift: "",
    activityType:"",
  });


  useEffect(() => {
    if (bulletId === "1") {
      setSelectedArea("Schedule Violation");
    } else if (bulletId === "2") {
      setSelectedArea("Exception Violation");
    } else {
      setSelectedArea("");
    }
  }, [bulletId]);

  let columns: any = [];
  const violationTrends = ["1", "2", "3"]
  const violationSummarys = ["4", "5", "6"]
  if (bulletId === undefined) {
    return null; 
  }
  const trimmedBulletId = bulletId.trim();
  const isInViolationTrends = violationTrends.includes(trimmedBulletId);
  const isInViolationSummarys = violationSummarys.includes(trimmedBulletId);



  const handleInputChange = (event : any) => {
    const { name, value } = event.target;
    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const generateLabels = (range: any) => {
    switch (range) {
      case "area":
        return ["Area 1", "Area 2", "Area 3", "Area 4", "Area 5"];
      case "location":
        return ["Location 1", "Location 2", "Location 3", "Location 4", "Location 5"];
      case "operator":
        return ["Operator 1", "Operator 2", "Operator 3", "Operator 4", "Operator 5"];
      case "microbiologist":
        return ["Microbiologist 1", "Microbiologist 2", "Microbiologist 3", "Microbiologist 4", "Microbiologist 5"];
      case "daily":
        return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      case "weekly":
        return ["Week 1", "Week 2", "Week 3", "Week 4"];
      case "monthly":
        return ["January", "February", "March", "April", "May"];
      case "yearly":
        return ["2021", "2022", "2023", "2024", "2025"];
      case "seasonal":
        return ["Spring", "Summer", "Autumn", "Winter"];
      default:
        return [];
    }
  };

  interface ChartData {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      fill?: boolean; // Adjust as needed
    }[];
  }

  function getRandomData(data: number[]) {
    const max = 6;
    return data.map(() => Math.floor(Math.random() * max));
  }

  const originalData = [1, 2, 3, 4, 5];
  const randomizedData1 = getRandomData(originalData);
  const randomizedData2 = getRandomData(originalData);
  const randomizedData3 = getRandomData(originalData);

  let tableData: any[] = []
  const scheduleData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Actual Schedule": "2/20/24 9:00", "Expected Schedule": "2/20/24 9:00" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Exposure", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 0:00", "Actual Schedule": "2/21/24 0:00", "Expected Schedule": "2/21/24 0:00" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Exposure", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 0:00", "Actual Schedule": "2/22/24 0:00", "Expected Schedule": "2/22/24 0:00" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Exposure", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 0:00", "Actual Schedule": "2/23/24 0:00", "Expected Schedule": "2/23/24 0:00" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 0:00", "Actual Schedule": "2/24/24 0:00", "Expected Schedule": "2/24/24 0:00" },
    { "Area": "Area 2", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 0:00", "Actual Schedule": "2/25/24 0:00", "Expected Schedule": "2/25/24 0:00" },
    { "Area": "Area 3", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 0:00", "Actual Schedule": "2/26/24 0:00", "Expected Schedule": "2/26/24 0:00" },
    { "Area": "Area 4", "Location": "Location H", "Activity Type": "Exposure", "Operator": "Operator 8", "Microbiologist": "Microbiologist 8", "Date & Time": "2/27/24 0:00", "Actual Schedule": "2/27/24 0:00", "Expected Schedule": "2/27/24 0:00" }
  ];
  const discrepancyData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Discrepancy Type": "Type 1", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Incubation", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 9:00", "Discrepancy Type": "Type 2", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Incubation", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 9:00", "Discrepancy Type": "Type 3", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Incubation", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 9:00", "Discrepancy Type": "Type 4", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 9:00", "Discrepancy Type": "Type 5", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 9:00", "Discrepancy Type": "Type 6", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" },
    { "Area": "Area 1", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 9:00", "Discrepancy Type": "Type 7", "Discrepancy Reason": "Reason", "Corrective Action": "Remarks" }
  ];
  const exceptionData: any[] = [
    { "Area": "Area 1", "Location": "Location A", "Activity Type": "Exposure", "Operator": "Operator 1", "Microbiologist": "Microbiologist 1", "Date & Time": "2/20/24 9:00", "Exception Type": "Type 1", "Exception Reasons": "Reason 1" },
    { "Area": "Area 1", "Location": "Location B", "Activity Type": "Incubation", "Operator": "Operator 2", "Microbiologist": "Microbiologist 2", "Date & Time": "2/21/24 9:00", "Exception Type": "Type 2", "Exception Reasons": "Reason 2" },
    { "Area": "Area 1", "Location": "Location C", "Activity Type": "Incubation", "Operator": "Operator 3", "Microbiologist": "Microbiologist 3", "Date & Time": "2/22/24 9:00", "Exception Type": "Type 3", "Exception Reasons": "Reason 3" },
    { "Area": "Area 1", "Location": "Location D", "Activity Type": "Incubation", "Operator": "Operator 4", "Microbiologist": "Microbiologist 4", "Date & Time": "2/23/24 9:00", "Exception Type": "Type 4", "Exception Reasons": "Reason 4" },
    { "Area": "Area 1", "Location": "Location E", "Activity Type": "Exposure", "Operator": "Operator 5", "Microbiologist": "Microbiologist 5", "Date & Time": "2/24/24 9:00", "Exception Type": "Type 5", "Exception Reasons": "Reason 5" },
    { "Area": "Area 1", "Location": "Location F", "Activity Type": "Exposure", "Operator": "Operator 6", "Microbiologist": "Microbiologist 6", "Date & Time": "2/25/24 9:00", "Exception Type": "Type 6", "Exception Reasons": "Reason 6" },
    { "Area": "Area 1", "Location": "Location G", "Activity Type": "Exposure", "Operator": "Operator 7", "Microbiologist": "Microbiologist 7", "Date & Time": "2/26/24 9:00", "Exception Type": "Type 7", "Exception Reasons": "Reason 7" }
  ];

  const datasets: any = [];
  if (bulletId === "4") {
    columns = ScheduleSummary;
    tableData = scheduleData;
  } else if (bulletId === "5") {
    columns = DiscrepancySummary;
    tableData = discrepancyData
  } else if (bulletId === "6") {
    columns = ExceptionSummary; 
    tableData = exceptionData
  }

  if (chartType === "pie") {

    // Include only one dataset for pie chart
    datasets.push({
      label: "Sample Data 1",
      data: randomizedData1, // Replace with your actual data
      backgroundColor: ["#adc178", "#a98467","#6c584c","#7b8e8a","#9dab86","#8e776d","#a88d79"],
      borderColor: "#6c757d",
      borderWidth: 1,
      fill: false, 
    });
  } else {

  datasets.push(
    {
      label: "Sample Data 1",
      data: randomizedData1, // Replace with your actual data
      backgroundColor: "#014D4E",
      borderColor: "#014D4E",
      borderWidth: 1,
      fill: chartType === "area", // Adjust as needed
    },
    {
      label: "Sample Data 2",
      data: randomizedData2, // Replace with your actual data
      backgroundColor: "#FFA500",
      borderColor: "#FFA500",
      borderWidth: 1,
      fill: chartType === "area", // Adjust as needed
    },
    {
      label: "Sample Data 3",
      data: randomizedData3, // Replace with your actual data
      backgroundColor: "#013220",
      borderColor: "#013220",
      borderWidth: 1,
      fill: chartType === "area", // Adjust as needed
    },
    {
      label: "Sample Data 4",
      data: randomizedData2, // Replace with your actual data
      backgroundColor: "#339989",
      borderColor: "#339989",
      borderWidth: 1,
      fill: chartType === "area", // Adjust as needed
    },
    {
      label: "Sample Data 5",
      data: randomizedData3, // Replace with your actual data
      backgroundColor: "#f72585",
      borderColor: "#f72585",
      borderWidth: 1,
      fill: chartType === "area", // Adjust as needed
    }
  );
}

  // Now you can use the `datasets` array in your ChartData
  const data: ChartData = {
    labels: generateLabels(xaxisRange),
    datasets: datasets,
  };

  // const data: ChartData = {
  //   labels: generateLabels(timeRange),
   
  //   datasets: [
  //     {
  //       label: "Sample Data 1",
  //       data: [12, 19, 3, 5, 2], // Replace with your actual data
  //       backgroundColor: "red",
  //       borderColor: "",
  //       borderWidth: 1,
  //       fill: chartType === "area", // Adjust as needed
  //     },
  //     {
  //       label: "Sample Data 2",
  //       data: [8, 15, 6, 10, 5], // Replace with your actual data
  //       backgroundColor: "blue",
  //       borderColor: "",
  //       borderWidth: 1,
  //       fill: chartType === "area", // Adjust as needed
  //     },
  //     {
  //       label: "Sample Data 3",
  //       data: [5, 10, 2, 8, 3], // Replace with your actual data
  //       backgroundColor: "yellow",
  //       borderColor: "",
  //       borderWidth: 1,
  //       fill: chartType === "area", // Adjust as needed
  //     },
  //   ],
  // };



  interface ChartType {
    label: string;
    component: any;
    backgroundColors: string[];
    borderColors: string[];
    stack?: boolean;
    fill?: boolean;
    selectedOption?: string;
    selectedGraph?: string;
    graphNameType?:string[];
    selectedGraphName?: string;
    xAxisSelector?:string[];
  }

  interface ChartTypes {
    [key: string]: ChartType;
  }

  const chartTypes: ChartTypes = {
    bar: {
      label: 'Bar Chart',
      component: Bar,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
    },
    line: {
      label: 'Line Chart',
      component: Line,
      backgroundColors: ['rgba(53, 162, 235, 0)', 'rgba(23, 152, 225, 0)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
    },
    area: {
      label: 'Area Chart',
      component: Line,
      backgroundColors: ['rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)'],
      borderColors: ['rgb(75, 192, 192)', 'rgb(54, 162, 235)'],
    },
    stackedBar: {
      label: 'Stacked Bar Chart',
      component: Bar,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
      stack: true,
    },
    pie: {
      label: 'Pie Chart',
      component: Pie,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(255, 205, 86, 0.5)', 'rgba(255, 99, 132, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)', 'rgb(75, 192, 192)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)'],
    },
    // Add more chart types as needed
  };


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Violations Report" />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">{t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Violations">{t('dashboard.violationsReport')}</Link>
            </li>
            <li className="breadcrumb-item active selectedArea" aria-current="page">
              {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <DownloadPdf divId="dashWrap" reportHead={t('dashboard.violationsReport')} />
        </div>
      </nav>

    <div >
    <div className="d-flex justify-content-around mt-3">

      {/* Area filter */}
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_area')}:</label>
        <select className='areaTypeSelect' name="area" value={filterData.area} onChange={(e) =>  handleInputChange(e)} >
          <option value="">Select</option>
          <option value="Area 1" >Area 1</option>
          <option value="Area 2" >Area 2</option>
          <option value="Area 3" >Area 3</option>
          <option value="Area 4" >Area 4</option>
          {/* {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option> */}
          {/* ))} */}
        </select>
      </div>

      {/* location filter */}
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_location')}:</label>
        <select className='areaTypeSelect' name="location" value={filterData.location} onChange={(e) =>  handleInputChange(e)} >
          <option value="">Select</option>
          <option value="Location 1" >Location 1</option>
          <option value="Location 2" >Location 2</option>
          <option value="Location 3" >Location 3</option>
          <option value="Location 4" >Location 4</option>
          {/* {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option> */}
          {/* ))} */}
        </select>
      </div>

      {/* Trend filter */}
      {isInViolationTrends && (
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_trend_type')}:</label>
        <select className='areaTypeSelect' name="trendType" value={xaxisRange} onChange={(e) => setXaxisRange(e.target.value)} >
          <option value="">Select</option>
          <option value="area" >Area</option>
          <option value="location" >Location</option>
          <option value="operator" >Operator</option>
          <option value="microbiologist" >Microbiologist</option>
          {/* {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option> */}
          {/* ))} */}
        </select>
      </div>
      )}
      {isInViolationSummarys && (
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_operator')}:</label>
        <select className='areaTypeSelect' name="trendType" value={filterData.trendType} onChange={(e) =>  handleInputChange(e)} >
          <option value="">Select</option>
          <option value="Exception Trend" >Operator 1</option>
          <option value="Discrepency Trend" >Operator 2</option>
          <option value="Schedule Trend" >Operator 3</option>
          {/* {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option> */}
          {/* ))} */}
        </select>
      </div>
      )}

      {/* Graph Type filter */}
      {isInViolationTrends && (
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_graph_type')}:</label>
        <select className='areaTypeSelect' value={chartType} onChange={(e) => setChartType(e.target.value)}>
              {Object.keys(chartTypes).map((type) => (
                <option key={type} value={type}>
                  {chartTypes[type].label}
                </option>
              ))}
        </select>
      </div>
      )}

      {isInViolationSummarys && (
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_microbiologist')}:</label>
        <select className='areaTypeSelect' value={chartType} onChange={(e) => setChartType(e.target.value)}>
          <option value="">Select</option>
          <option value="Exception Trend" >microbiologist 1</option>
          <option value="Discrepency Trend" >microbiologist 2</option>
          <option value="Schedule Trend" >microbiologist 3</option>
        </select>
      </div>
      )}

      {/* Shift filter */}
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_shift')}:</label>
        <select className='areaTypeSelect' name="shift" value={filterData.shift} onChange={(e) =>  handleInputChange(e)} >
          <option value="">Select</option>
          <option value="Shift 1" >Shift 1</option>
          <option value="Shift 2" >Shift 2</option>
          <option value="Shift 3" >Shift 3</option>
          <option value="Shift 4" >Shift 4</option>
          {/* {graphNameType?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option> */}
          {/* ))} */}
        </select>
      </div>


    </div>

    <div className="d-flex justify-content-around mt-3">

      {/* Period filter */}
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_period')}:</label>
        <select className='areaTypeSelect' value={xaxisRange} onChange={(e) => setXaxisRange(e.target.value)}>
            <option value="">Select</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="seasonal">Quaterly</option>
        </select>
      </div>

      {/* Activity filter */}
      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_activity')} <span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="activityType" value={filterData.activityType} onChange={(e) => handleInputChange(e)} >
            <option value="" >Select</option>
            <option value="Exposure" >Exposure</option>
            <option value="Incubation" >Incubation</option>
            <option value="Finger Dab" >Finger Dab</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

      {/* From Date */}
      <div className="select-container analyticFilterDate ms-1" 
        style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.from_date')}:</label>
          <input type="date" />
      </div>

      {/* To Date */}
      <div className="select-container analyticFilterDate" 
        style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.to_date')}:</label>
        <input type="date" />
      </div>

      <div className="my-3">
            <button className="analyticalFilterClose" title="Click here to Reset Filter">{t('buttons.cancel')}</button>
            <button className="analyticalFilterApply" title="Click here to Apply Filter">{t('buttons.apply')}</button>
        </div>

    </div>

    <div className="row mx-2 mt-5" id="dashWrap" style={{"display":"flex", "justifyContent":"center"}}>
    {isInViolationTrends && (
        <div className="col-9">
          <div className="">
            <Chart
              chartType={chartType}
              data={data}
              selectedOption={selectedArea}
              selectedGraph={selectedGraph}
              xaxisRange={xaxisRange}
              // graphNameType= ''
            />
          </div>
        </div>
    )}

    {isInViolationSummarys && (
        <div className="col-12">
          <div>
            <CustomTable 
              columns={columns}
              data={tableData}
              isActionVisible={false}
              isViewVisible={false}
              totalElements={0}
              tableHead={selectedArea}
            />
          </div>
        </div>
    )}
        </div>

    </div>

    </div>
  );
};

export default LandingComponent;
