export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: "Activity Type",
        accessor: "plate_type",
      },
      {
        Header: 'Incubation Ends In',
        accessor: 'remainingTime',
      },
      {
        Header: 'Incubation Duration',
        accessor: 'incubation_duration',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Incubated by',
        accessor: 'incubated_by',
      },
      // {
      //   Header: 'Exposed Start Time',
      //   accessor: 'exposure_start_time',
      // },
      // {
      //   Header: 'Exposed End Time',
      //   accessor: 'exposureEndTime',
      // },
      // {
      //   Header: 'Exposed Time',
      //   accessor: 'exposed_time',
      // },
      // {
      //   Header: 'Exposed by',
      //   accessor: 'exposed_by',
      // },
      // {
      //   Header: 'Area Name',
      //   accessor: 'exposure_area_name',
      // },     
      // {
      //   Header: 'Expiry Date',
      //   accessor: 'expiry_date',
      // },
  ];


export const columnsHistory: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: "Activity Type",
        accessor: "plate_type",
      },
      {
        Header: 'Incubation Duration',
        accessor: 'incubation_duration',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      // {
      //   Header: 'Exposed Time',
      //   accessor: 'exposed_time',
      // },
      // {
      //   Header: 'Exposed By',
      //   accessor: 'exposed_by',
      // },
      // {
      //   Header: 'Area Name',
      //   accessor: 'exposure_area_name',
      // },
      // {
      //   Header: 'Incubation Start Time',
      //   accessor: 'incubation_start_time',
      // },
      // {
      //   Header: 'Incubation End Time',
      //   accessor: 'IncubationEndTime',
      // },
     
      // {
      //   Header: 'Incubated By',
      //   accessor: 'incubated_by',
      // },
      
      // {
      //   Header: 'Expiry Date',
      //   accessor: 'expiry_date',
      // },
]

  
interface TableColumn {
    Header: string;
    accessor: string;
}