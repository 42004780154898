import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useParams } from "react-router-dom";
import ParetoChart from "../partials/paretoChart";
import { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import DownloadPdf from "../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";

const ExcursionReport = () => {
  const { bulletId } = useParams<{ bulletId: string }>();
  const [selectedArea, setSelectedArea] = useState<string>("");
  const { t } = useTranslation();

  let chartData: number[] = [];
  let chartLabels: string[] = [];
  let lineChartData1: number[] = [];
  let lineChartData2: number[] = [];

  // function getRandomData(length: any, max: any) {
  //   return Array.from({ length }, () => Math.floor(Math.random() * max));
  // }
  
  // if (bulletId === 'daily') {
  //   chartData = getRandomData(7, 200);
  //   chartLabels = ["feb 1", "feb 2", "feb 3", "feb 4", "feb 5", "feb 6", "feb 7"];
  //   lineChartData1 = getRandomData(7, 200);
  //   lineChartData2 = getRandomData(7, 200);
  // } else if (bulletId === 'weekly') {
  //   chartData = getRandomData(7, 200);
  //   chartLabels = ["wk1", "wk2", "wk3", "wk4", "wk5", "wk6", "wk7"];
  //   lineChartData1 = getRandomData(7, 200);
  //   lineChartData2 = getRandomData(7, 200);
  // } else if (bulletId === 'monthly') {
  //   chartData = getRandomData(7, 200);
  //   chartLabels = ["january", "february", "march", "april", "may", "june", "july"];
  //   lineChartData1 = getRandomData(7, 200);
  //   lineChartData2 = getRandomData(7, 200);
  // } else if (bulletId === 'yearly') {
  //   chartData = getRandomData(7, 200);
  //   chartLabels = ["2016", "2017", "2018", "2019", "2020", "2021", "2022"];
  //   lineChartData1 = getRandomData(7, 200);
  //   lineChartData2 = getRandomData(7, 200);
  // } else if (bulletId === 'quarterly') {
  //   chartData = getRandomData(4, 200);
  //   chartLabels = ["QA1", "QA2", "QA3", "QA4"];
  //   lineChartData1 = getRandomData(4, 200);
  //   lineChartData2 = getRandomData(4, 200);
  // }

  if (bulletId === 'day') {
    chartData = [200, 250, 250, 250, 200, 200];
    chartLabels = ["feb 1", "feb 2", "feb 3", "feb 4", "feb 5", "feb 6"];
    lineChartData1 = [250, 300, 250, 200, 100, 150];
    lineChartData2 = [3.5, 1.6, 1.2, 0.8, 1, 0.5];
  } else if (bulletId === 'week') {
    chartData = [200, 250, 250, 250, 200, 200];
    chartLabels = ["wk1", "wk2", "wk3", "wk4", "wk5", "wk6"];
    lineChartData1 = [250, 300, 250, 200, 100, 150];
    lineChartData2 = [3.5, 1.6, 1.2, 0.8, 1, 0.5];
  } else if (bulletId === 'month') {
    chartData = [200, 250, 250, 250, 200, 200];
    chartLabels = ["january", "february", "march", "april", "may", "june"];
    lineChartData1 = [250, 300, 250, 200, 100, 150];
    lineChartData2 = [3.5, 1.6, 1.2, 0.8, 1, 0.5];
  } else if (bulletId === 'year') {
    chartData = [200, 250, 250, 250, 200, 200];
    chartLabels = ["2016", "2017", "2018", "2019", "2020", "2021"];
    lineChartData1 = [250, 300, 250, 200, 100, 150];
    lineChartData2 = [3.5, 1.6, 1.2, 0.8, 1, 0.5];
  } else if (bulletId === 'quarter') {
    chartData = [200, 250, 250, 250];
    chartLabels = ["QA1", "QA2", "QA3", "QA4"];
    lineChartData1 = [250, 300, 250, 200];
    lineChartData2 = [3.5, 1.6, 1.2, 0.8];
  }

  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    activity: "",
    operator: "",
    microbiologist: "",
    shift: "",
    organismFilter: "",
    subFilter: "",
  })

  useEffect(() => {
    if (bulletId === "day") {
      setSelectedArea("Daily");
    } else if (bulletId === "week") {
      setSelectedArea("Weekly");
    } else if (bulletId === "month") {
      setSelectedArea("Monthly");
    } else if (bulletId === "year") {
      setSelectedArea("Yearly");
    } else if (bulletId === "quarter") {
        setSelectedArea("Quarterly");
    } else {
      setSelectedArea("");
    }
  }, [bulletId]);

  const handleInputChange = (event : any) => {
    const { name, value } = event.target;
    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  let subFilterLabel = 'Sub Filter';
  if (filterData.organismFilter === 'Type count') {
    subFilterLabel = 'Type Filter';
  } else if (filterData.organismFilter === 'Genus count') {
    subFilterLabel = 'Genus Filter';
  } else if (filterData.organismFilter === 'Species count') {
    subFilterLabel = 'Species Filter';
  } else if (filterData.organismFilter === 'Strain count') {
    subFilterLabel = 'Strain Filter';
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('dashboard.excursionsReport')} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">{t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Excursion">{t('dashboard.excursionsReport')}</Link>
            </li>
            <li className="breadcrumb-item active selectedArea" aria-current="page">
              {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <DownloadPdf divId="dashWrap" reportHead={t('dashboard.excursionsReport')} />
        </div>
      </nav>

      <div >

      <div className="d-flex justify-content-around mt-3">

        {/* Area filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_area')}:</label>
          <select className='areaTypeSelect' name="area" value={filterData.area} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Area 1" >Area 1</option>
            <option value="Area 2" >Area 2</option>
            <option value="Area 3" >Area 3</option>
            <option value="Area 4" >Area 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* location filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_location')}:</label>
          <select className='areaTypeSelect' name="location" value={filterData.location} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Location 1" >Location 1</option>
            <option value="Location 2" >Location 2</option>
            <option value="Location 3" >Location 3</option>
            <option value="Location 4" >Location 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Activity filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_activity')}:</label>
          <select className='areaTypeSelect' name="activity" value={filterData.activity} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Activity 1" >Activity 1</option>
            <option value="Activity 2" >Activity 2</option>
            <option value="Activity 3" >Activity 3</option>
            <option value="Activity 4" >Activity 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Operator filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_operator')}:</label>
          <select className='areaTypeSelect' name="operator" value={filterData.operator} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Operator 1" >Operator 1</option>
            <option value="Operator 2" >Operator 2</option>
            <option value="Operator 3" >Operator 3</option>
            <option value="Operator 4" >Operator 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Microbiologist filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_microbiologist')}:</label>
          <select className='areaTypeSelect' name="microbiologist" value={filterData.microbiologist} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Microbiologist 1" >Microbiologist 1</option>
            <option value="Microbiologist 2" >Microbiologist 2</option>
            <option value="Microbiologist 3" >Microbiologist 3</option>
            <option value="Microbiologist 4" >Microbiologist 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

      </div>

      <div className="d-flex justify-content-around mt-3">

        {/* Shift filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_shift')}:</label>
          <select className='areaTypeSelect' name="shift" value={filterData.shift} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Shift 1" >Shift 1</option>
            <option value="Shift 2" >Shift 2</option>
            <option value="Shift 3" >Shift 3</option>
            <option value="Shift 4" >Shift 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Organism filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.organism_filter')}<span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="organismFilter" value={filterData.organismFilter} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Type count" >Type count</option>
            <option value="Genus count" >Genus count</option>
            <option value="Species count" >Species count</option>
            <option value="Strain count" >Strain count</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        {/* Sub filter */}
        <div className="select-container" style={{display: "flex", flexDirection: "column", "marginRight":"240px"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{subFilterLabel}<span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="subFilter" value={filterData.subFilter} onChange={(e) =>  handleInputChange(e)} >
            <option value="">Select</option>
            <option value="Sub 1" >sample 1</option>
            <option value="Sub 2" >sample 2</option>
            <option value="Sub 3" >sample 3</option>
            <option value="Sub 4" >sample 4</option>
            {/* {graphNameType?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option> */}
            {/* ))} */}
          </select>
        </div>

        <div className="my-3">
            <button className="analyticalFilterClose" title="Click here to Reset Filter">{t('buttons.cancel')}</button>
            <button className="analyticalFilterApply" title="Click here to Apply Filter">{t('buttons.apply')}</button>
        </div>

      </div>

      <div className="pareto-head-chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <p className="pareto-head" style={{ marginRight: "200px" }}>
      {t('dashboard.excursionsReport')} | By {selectedArea}
         {filterData.organismFilter && <span> | By {filterData.organismFilter}</span>}
         {filterData.activity && <span> | By {filterData.activity}</span>}
      </p>
      {/* <select className="areaTypeSelect" value={""} style={{ marginLeft: "50%" }}>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
        <option value="seasonal">Seasonal</option>
      </select> */}
       </div>

       {/* Chart Section */}
       <div id="dashWrap" style={{ width: "900px", height: "400px", marginLeft: "80px", marginBottom:"25px" }}>
        {/* Pareto Chart Component */}
        <ParetoChart
          barChartData={chartData}
          barChartLabels={chartLabels}
          lineChartData1={lineChartData1}
          lineChartData2={lineChartData2}
        />
      </div>

      </div>
    </div>
  );
};

export default ExcursionReport;
