import React, { useState, useEffect, useRef  } from 'react'

import {BsQrCodeScan} from 'react-icons/bs'
import {TfiClose} from 'react-icons/tfi'
import {BiSearch} from 'react-icons/bi'
import CustomTable from '../../../widgets/table/index';
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import AddException from '../../common/addException';
import { userService } from '../../../service/plateServices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, setLoading } from '../../../store/store';
import { areaService } from '../../../service/areaServices';
import { FileService } from '../../../service/fileServices';
import moment from 'moment';
import CameraApp from './partials/cameraModal';
import { SerielNumberScanner } from '../common/serielNumberScanner';
import Pagination from '../../../widgets/pagination';
import AlertModal from '../../../widgets/alertModal';
import CustomToast from '../../../widgets/toaster';
import { columns } from './partials/columns';
import { useTranslation } from 'react-i18next';
import CustomLoader from '../../../widgets/loader/loader';
import { trendAnalytics } from '../../../service/trendAnalytics';
import LabReportViewModel from '../../lab_operator/labReport/models/labReportViewModel';


const LabTestArea = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState("1");
    const [show, setShow] = useState(false);
    const [serielScanShow, setSerielScanShow] = useState(false);
    const [scanCode, setScanCode] = useState("")
    const areaId = useSelector((state: RootState) => state.area.areaId);
    const areaName = useSelector((state: RootState) => state.area.areaName);
    const [plateStatus, setPlateStatus] = useState(0)
    const [locationId, setLocationId] = useState(areaId)
    const [location, setLocation] = useState("")
    const [keyWord, setkeyWord] = useState("")
    const [userId, setUserId] = useState<number>(0);
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [plateId, setPlateId] = useState("")
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [labAreaName, setLabAreaName] = useState("");
    const [colonyRage, setColonyRange] = useState("")
    const [countAuto, setCountAuto] = useState(false)
    const [colonyCount, setColonyCount] = useState("0")
    const [remark, setRemark] = useState("")
    const [genus, setGenus] = useState("")
    const [species, setSpecies] = useState("")
    const [imageId, setImageId] = useState<string | null>("")
    const [imageIds, setImageIds] = useState<any>([])
    const [labSubmitModal ,setLabSubmitModal] = useState(false)
    const[labCounter , setLabCounter] = useState("")
    interface Errors {
      colonyCount?: string;
      remarks?: string;
    }
    interface LabAreaPolicyData {
      lab_area_policy_min_range: number;
      lab_area_policy_max_range: number;
    }
    const [errors, setErrors] = useState<Errors>({})

    // pagination
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [plateDetailsCompleted, setPlateDetailsCompleted] = useState<Array<any>>([]);
    const [plateBatches, setPlateBatches] = useState([])
    const [plateSerielNum, setPlateSerielNums] = useState([])
    const [labAreaPolicyData, setLabAreaPolicyData] = useState<LabAreaPolicyData>({
      lab_area_policy_min_range: 0,
      lab_area_policy_max_range: 0,
    });
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [spots, setSpots] = useState<any>([]);
    const [isCameraOpen, setIsCameraOpen] = useState(false); 
    const [capturedImage, setCapturedImage] = useState<any | null>("");
    const [authToken , setAuthToken] = useState("");
    const [authUserID , setAuthUserID] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [plateType, setPlateType] = useState("");
    const [showColCountAlert, setShowColCountAlert] = useState(false);
    const [customPlateResponse, setCustomPlateResponse] = useState("");
    const [showViewMoreBtn, setShowViewMoreBtn] = useState(false);
    const [showView, setShowView] = useState(false);
    const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
    const [incubationDetails, setIncubationDetails] = useState<any>([]);
    const [exposureDetails, setExposureDetails] = useState<any>([]);
    const [fingureDabDetails, setFingureDabDetails] = useState<any>([]);
    const [plateSamplingDetails, setPlateSamplingDetails] = useState<any>([]);
    const [rawMaterialDetails, setRawMaterialDetails] = useState<any>([]);
    const [surfaceMonitoringDetails, setSurfaceMonitoringDetails] = useState<any>([]);
    const [labTestDetails, setLabTestDetails] = useState<any>([]);
    const [currentExposureIndex, setCurrentExposureIndex] = useState(0);
    const [currentSamplingIndex, setCurrentSamplingIndex] = useState(0);
    const [currentRawmaterialTestIndex, setCurrentRawmaterialTestIndex] = useState(0);
    const [currentFingerDabIndex, setCurrentFingerDabIndex] = useState(0);
    const [currentIncubationIndex, setCurrentIncubationIndex] = useState(0);
    const [currentLabTestIndex, setCurrentLabTestIndex] = useState(0);
    const [currentSurfaceMonitoringIndex, setCurrentSurfaceMonitoringIndex] = useState(0);


    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.loader.loading);
    const scanAreaRef = useRef<HTMLInputElement>(null);


    //logged in user details 
    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');
  
      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setUserId(storedObject[0].id)
        setAuthToken(storedObject[0].x_auth_token)
        setAuthUserID(storedObject[0].x_userid)

      }
      ListLabTestHistory();
      // ListPlateDetails()
      getAreaByScan(areaId, areaName)
      setUseEffectRequired(false)
      listPlateBatch()
      listSerialNumber()
      // getImage(5)
    }, [useEffectRequired])

    useEffect(() => {
      if (scanAreaRef.current) {
        scanAreaRef.current.focus();
      }
    }, [])

    // useEffect(() => {
    //   ListPlateDetails()
    // }, [colonyRage])

    //get area details from scan qr 
    const getAreaByScan = async (id : any , location_type : any ) => {
        try {
          const response = await areaService.getAreaByScan(id, location_type);
          if(response.status && response.statusCode === 200){
            try {   
                setLabAreaName(response.data.lab_area_name);
                // setColonyRange(` B/W ${response.data.lab_area_policy_min_range} - ${response.data.lab_area_policy_max_range}`);
                // setLabAreaPolicyData({
                //   lab_area_policy_min_range: response.data.lab_area_policy_min_range,
                //   lab_area_policy_max_range: response.data.lab_area_policy_max_range,
                // });
                if (response.data.count_entry_flag === "1"){
                  setCountAuto(false)
                } else if (response.data.count_entry_flag === "0") {
                  setCountAuto(true)
                }
              } catch (error) {
                console.log(error);
              }
          } 
        } catch (error) {
          console.error('user response', error);
        }
      };

    //get labtest history details
    const ListLabTestHistory = async () => {
          try {
            const response = await userService.ListLabTestHistory(page, pageSize, sort, order, keyWord, areaId);
            if(response.status && response.statusCode === 200){
              try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                    setTotalPages(response?.totalPages)
                    setTotalElements(response?.totalElements)
                    let rowData: any = response.data.map((element: any, index: number) => {
                     
                      const statusNumber = parseInt(element.plate_status, 10); 
                      const status = userService.getPlateStatus(statusNumber);
                      interface Exposure {
                       id: string;
                       start_date_time: string;
                       end_date_time: string;
                       updated_by_first_name: string;
                       updated_by_last_name: string;
                       exposure_area_name: string;
                     }
                     // Calculate total exposed time
                     const exposureDetails : Exposure[] = element.exposure_details || [];
                     let totalExposedTime = 0;
     
                     exposureDetails.forEach((exposure) => {
                       const startDate = new Date(exposure.start_date_time);
                       const endDate = new Date(exposure.end_date_time);
                       const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                       totalExposedTime += timeDifferenceMs;
     
     
                     });
                     const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                     const totalExposedTimeMinutes = Math.floor(
                       (totalExposedTime % 3600000) / 60000
                     );
                     const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);
     
                     const fullNames = exposureDetails.map((exposure : Exposure) => {
                       return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                     });
                     const exposure_user = fullNames.join(", ");

                     const exposureAreaName = exposureDetails.map((exposure : Exposure) => {
                      return exposure.exposure_area_name                   
                    });
     
                     //caluculate the total incubated time 
                     interface Incubation {
                       id: string;
                       start_date_time: string;
                       end_date_time: string;
                       created_by_first_name: string;
                       updated_by_first_name: string;
                       updated_by_last_name: string;
                       incubation_area_name: string;
                     }
                     const incubationDetails : Incubation[] = element.incubation_details || [];
                     let totalIncubatedTime = 0;
                     incubationDetails?.forEach((incubation)=>{
                       const startDate = new Date(incubation.start_date_time);
                       const endDate = incubation.end_date_time ? new Date(incubation.end_date_time) : null;
                       if (endDate) {
                         const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                         totalIncubatedTime += timeDifferenceMs;
                       }
                     });
                     const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                     const totalIncubatedTimeMinutes = Math.floor(
                       (totalIncubatedTime % 3600000) / 60000
                     );
                     const totalIncubatedTimeSeconds = Math.floor((totalIncubatedTime % 60000) / 1000);
     
                     const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                       return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                     });
                     const incubated_user = fullNamesIncubation.join(", ");
                    
                    const incubationAreaName = incubationDetails.map((incubation : Incubation) => {
                      return incubation.incubation_area_name
                    });

                    interface Labtest {
                      id: string;
                      completed_by_user_firstname: string;
                      completed_by_user_lastname: string;
                      completed_date_time: string;
                    }
                    const labtestDetails : Labtest[] = element.lab_details || [];
                    const fullNamesLabtest = labtestDetails.map((labtest : Labtest) => {
                      return `${labtest.completed_by_user_firstname} ${labtest.completed_by_user_lastname}`;
                    });
                    const labtest_user = fullNamesLabtest.join(", ");
                    // setPlateId(element.id)
                    const labtestCompletedDate = labtestDetails.map((labtest : Labtest) => {
                      return labtest.completed_date_time
                    });

                    const plateType = parseInt(element.plate_type, 10); 
                      if (plateType == 1){
                        var plate_type = "Q A Plate"
                      }else if (plateType == 2){
                        var plate_type = "Exposure Plate"
                      }else if (plateType == 3){
                        var plate_type = "Finger DAB Plate"
                      } else{
                        var plate_type = "-"
                    }
                    // setLabAreaStatus(plate_type);
                      return { 
                          slno: index + 1,
                          batch_number : element.batch_number,
                          serial_number : element.serial_number,
                          plate_type : plate_type,
                          exposedTime : `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                          exposedBy : exposure_user,
                          exposureAreaName : exposureAreaName,
                          incubationDuration : `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,                    
                          incubatedBy : incubated_user,
                          incubationAreaName : incubationAreaName,
                          labtestUser : labtest_user,
                          labTestCompletionDateTime : labtestCompletedDate,
                          PlateStatus: status,
                          
                      };
                    });
                    setPlateDetailsCompleted(rowData);
                }} catch (error) {
                  console.log(error); 
                }
            } else {
              setPlateDetailsCompleted([])
            }
          } catch (error) {
            console.error('Plate response', error);
          }
    };

    //submit labtest error validation 
    const handleSubmit = () => {
      setShowColCountAlert(false)
      const errors: Errors = {};
      const colonyCountAsNumber = parseInt(colonyCount, 10);
      if (colonyCountAsNumber > labAreaPolicyData.lab_area_policy_max_range
        ) {
        errors.colonyCount = `* only accept colony count ${colonyRage} `;
      }
       if (!remark) {
        errors.remarks = "* please add your remarks";
      }
      if(colonyCountAsNumber > Number(labAreaPolicyData.lab_area_policy_max_range) && remark){
        setLabSubmitModal(true)
      }
      // If there are any errors, display them to the user and disable the submit button.
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      // setShowAddExceptionAlert(true)
      setErrors({});
      startLabTest();
    }

    const handleSubmitValidations = () => {
      if (plateType === "4" && colonyCount > "0") {
        setShowColCountAlert(true)
      } else {
        handleSubmit()
      }
    }

    //start labtest api call 
    const startLabTest = async () => {
      const dataToSend = {
        "user_id" : userId,
        "plate_ids" : plateId,
        "location_id" : areaId,
        "plate_image_id" : imageIds.join(','),
        "colony_count" : colonyCount,
        "test_remarks" : remark,
        "lab_test_counter" : labCounter,
        "genus_details" : genus,
        "strain_details" : species,
        "interface_name" : "Start LabTest"
      }

      try {
        const response = await userService.startPlateLabTest(dataToSend , authToken, authUserID );
        if(response.status && response.statusCode === 200){      
             setToastType("success")
             setToastMessage(response.message)      
        } else{
            setToastType("error")
            setToastMessage(response.message)   
        }
        setShowToast(true)
        handleClearBtn()
      
      } catch (error) {
        console.error('user response', error);
      }
    }

    //handle pagination in labtest history 
    const handlePageChange = (newPage : any, pageSize?: number) => {
      setPage(newPage);
      if(pageSize) {
        setPageSize(pageSize)
      }
      setUseEffectRequired(true)
    };

    //top bar area name listing 
    const data: Record<string, string | number> = {
      "Area Name": labAreaName,
      // "Area Type": labAreaStatus,
    };

    const getPlateStatus = (status: number): string => {
      switch (status) {
        case 1:
          return "Plate in Quarantine Status, Details not found";
        case 2:
        case 5:
        case 11:
        case 8:
        case 9:
        case 12:
        case 27:
        case 28:
        case 24:
        case 25:
          return "Activity In Progress, Incubation not completed";
        case 3:
        case 6:
        case 14:
        case 15:
        case 21:
        case 22:
        case 26:
        case 29:
        case 30:
          return "Plate Lab Test Completed";
        case 4:
        case 7:
        case 10:
        case 23:
          return "Plate in Rejected Status";
        case 16:
        case 19:
          return "Plate in Exception Status";
        case 17:
          return "Plate in Damaged Status";
        case 18:
          return "Plate in Discarded Status";
        default:
          return "";
      }
  };


    //fetch plate details by serial number in scan plate area 
    const handleScanCodeChange = async (e: any) => {
      console.log("8")
      console.log(e , " value of e")
      setScanCode(e);
      setPlateDetails([]);
      if (e.trim() === "") {
        console.log("9");
        setPlateDetails([]);
        return; 
      } else {
        // dispatch(setLoading(true));
        try {
          const response = await userService.listAutoClavePlateBySerielNo(e, "LabTestArea");
          setPlateType(response?.data?.plate_type)
          const plateStatusInt = parseInt(response?.data?.plate_status, 10)
          setCustomPlateResponse(getPlateStatus(plateStatusInt))
          if (response.status && response.statusCode === 200) {
            // dispatch(setLoading(false));
            try {
              let rowData: any;
              const checkIfSameUser = response.data.lab_test_details[0]?.test_done_by_user_id === userId ? 1 : 0
              if (response?.data && checkIfSameUser === 0 ) {
                const element = response.data;
                              interface Exposure {
                                 id: string;
                                 start_date_time: string;
                                 end_date_time: string;
                                 updated_by_first_name: string;
                                 updated_by_last_name: string;
                                 exposure_area_name: string;
                               }
                               // Calculate total exposed time
                               const exposureDetails : Exposure[] = element.exposure_details || [];
                               let totalExposedTime = 0;
               
                               exposureDetails.forEach((exposure) => {
                                 const startDate = new Date(exposure.start_date_time);
                                 const endDate = new Date(exposure.end_date_time);
                                 const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                                 totalExposedTime += timeDifferenceMs;            
               
                               });
                               const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                               const totalExposedTimeMinutes = Math.floor(
                                 (totalExposedTime % 3600000) / 60000
                               );
                               const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);
               
                               const fullNames = exposureDetails.map((exposure : Exposure) => {
                                 return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                               });
               
                               //caluculate the total incubated time 
                               interface Incubation {
                                 id: string;
                                 start_date_time: string;
                                 end_date_time: string;
                                 created_by_first_name: string;
                                 updated_by_first_name: string;
                                 updated_by_last_name: string;
                               }
                               const incubationDetails : Incubation[] = element.incubation_details || [];
                               let totalIncubatedTime = 0;
                               incubationDetails?.forEach((incubation)=>{
                                 const startDate = new Date(incubation.start_date_time);
                                 const endDate = incubation.end_date_time ? new Date(incubation.end_date_time) : null;
                                 if (endDate) {
                                   const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                                   totalIncubatedTime += timeDifferenceMs;
                                 }
                               });
                               const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                               const totalIncubatedTimeMinutes = Math.floor(
                                 (totalIncubatedTime % 3600000) / 60000
                               );
               
                               const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                                 return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                               });
                               const incubated_user = fullNamesIncubation.join(", ");
              
                               const exposure_name = exposureDetails.map((exposure : Exposure) => {
                                return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                              });
                              const exposed_user_name = exposure_name.join(", ");
                              setPlateId(element.id)
                              setLabCounter(String(Number(element.lab_test_counter) + 1));

                              interface Labtest {
                                colony_count: string;
                                test_done_by_user_id: string
                              }
                              // Calculate total exposed time
                              const labDetails : Labtest[] = element.lab_test_details || [];
                              const ColonyCount = labDetails.map((labtest : Labtest) => {
                                return `${labtest.colony_count}`;
                              });
                              const testDoneUserCheck = labDetails.map((labtest : Labtest) => {
                                return `${labtest.test_done_by_user_id}`;
                              });

                              setLabAreaPolicyData({
                                lab_area_policy_min_range: response.data.min_count,
                                lab_area_policy_max_range: response.data.max_count,
                              });

                if (element.plate_status === "13" || element.plate_status === "20" || element.plate_status === "24" || element.plate_status === "25") {
                console.log(element, "element--")
                  rowData = {
                      "Plate Serial Num": element.serial_number,
                      "Batch ID": element.batch_number,    
                      // "Exposed Time": `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                      // "Collected Time": moment(element?.exposure_details[0]?.end_date_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'),
                      // "Collected By": exposed_user_name,
                      // "Incubation Star Time": moment(element?.incubation_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'),
                      // "Incubation End Time": moment(element?.incubation_details[0]?.end_date_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'),
                      // "Incubation Done By": incubated_user,
                      // "Plate Experiy Date":  element.expiry_date,
                      "Accepted Colony Count": ` B/W ${response.data.min_count} - ${response.data.max_count}  ${response.data.uom_name}`,
                      // "Area Name": element?.exposure_details[0]?.exposure_area_name,
                      // "Colony Count by QA1" : ColonyCount
                };
              }
            }
            else if (checkIfSameUser === 1){
              setToastMessage("Labtest already added for the same user");
              setToastType("error")
              setShowToast(true)
            }
              setPlateDetails(rowData);
              setShowViewMoreBtn(true)
            } catch (error) {
              console.log(error);
            }
          } else {
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
          // else if(response.statusCode === 400) {
          //   console.log("12")
          //   setPlateDetails([]);
          //   // dispatch(setLoading(false));
          // }
        } catch (error) {
          console.error('Plate response', error);
        }
      }
    };

    //handle filter section 
    const handleScheduleFilter = (key: string, value: any) => {
      if (key === "search") {
        setkeyWord(value)
      }
      setUseEffectRequired(true)
    }
  
    //list batch details drop down 
    const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }
    
    //list serial number details drop down 
    const listSerialNumber = async () => {
      try {
        const response = await userService.listSerialNumber();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      value: element.serial_number,
                      label: element.serial_number ,
                  };
                });
                setPlateSerielNums(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //toaster message after scaning plate 
    const handleScanCode = (serielNumber : any) => {
        if(serielNumber) {
          console.log("16")
          handleScanCodeChange(serielNumber)
          setToastMessage(`Plate Seriel scan successfull`);
          setToastType("success");
        } else {
          setToastMessage("Plate Seriel scan failed");
          setToastType("error");
        }
        setShowToast(true)
    }

    //pass the serial number if it is entered in text area 
    const handleScanCodeInput = (result: any) => {
    
      // try {
      //   const parsedResult = JSON.parse(result);
      //   if (typeof parsedResult === 'object') {
      //     if (parsedResult.hasOwnProperty('serial_number')) {
      //       handleScanCodeChange(parsedResult.serial_number)
      //     }
      //   }
      // } catch (error) {
      //   handleScanCodeChange(result)
      //   console.error('Error parsing JSON:', error);
      // }


      // Check if the result is not empty
      if (result.trim() !== "") {     
        // If the result is in JSON format
        try {      
          const parsedResult = JSON.parse(result);
          if (typeof parsedResult === 'object') {
            // Extract serial number from the parsed JSON
            const serialNumber = parsedResult.serial_number;
            if (serialNumber) {           
              handleScanCodeChange(serialNumber);
              return; // Exit the function after getting plate details
            }
          } else {      
          }
        } catch (error) {
        }
        // If the result is not in JSON format or parsing failed, treat it as a serial number string
        handleScanCodeChange(result);
      } else {
      }
    }
  

    //handle submit button 
    const handleAddAreaSubmit = () => {
      setLabSubmitModal(false)
      startLabTest();
    }

    //on click cancel button in labtest 
    const handleClearBtn = () => {
      handleScanCodeChange("")
      setColonyCount("")
      setRemark("")
      setSpecies("")
      setGenus("")
      setLabCounter("")
      setIsCameraOpen(false)
      setSpots([])
      setCapturedImage(null)
      setImageIds([])
      setPlateId("")
    }

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    const handleViewBtnClick = () => {
      setShowView(true)
      scanPlateById(plateId)
    }

    const scanPlateById = async (id:any) => {
      const dataToSend = {
        "id" : id
      }
      const response = await userService.scanPlateById(dataToSend);
      if(response.status && response.statusCode === 200){
        console.log(response, "response--")
  
  
        const plateType = parseInt(response?.data.plate_type, 10); 
                      if (plateType == 1){
                        var plate_type = "Q A Plate"
                      }else if (plateType == 2){
                        var plate_type = "Exposure Plate"
                      }else if (plateType == 3){
                        var plate_type = "Finger DAB Plate"
                      }else if (plateType == 4){
                        var plate_type = "Sampling Plate"
                      }else if (plateType == 5){
                        var plate_type = "Material Test Plate"
                      }else if (plateType == 6){
                        var plate_type = "Surface Monitoring Plate"
                      } else{
                        var plate_type = "-"
                      }
  
        const statusNumber = parseInt(response?.data.status, 10); 
                     if (statusNumber == 21){
                      var status = "pending"
                     }else{
                      var status = "unknown"
                     }
  
        const basicDetails = {
          "id": response.data.id,
          "serial_number": response.data.serial_number,
          "batch_number": response.data.batch_number,
          "plate_status": response.data.plate_status,
          "status": status,
          "expiry_date": response.data.expiry_date,
          "manufacturing_date": response.data.manufacturing_date,
          "current_location_id": response.data.current_location_id,
          "plate_type": plate_type,
        }
  
        let exposureDetails:any = response.data?.exposure_details?.map((element: any, index: number) => {
          return {
            exposedAreaName : element.exposure_area_name || "-",
            exposedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
            exposedDate : element.start_date_time,
            collectedBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
            collectedDate : element.end_date_time || "-",
          }
        })
  
        let fingureDabDetails = response.data?.fingerdab_details?.map((element: any, index: number) => {
          return {
            fingerDabAreaName : element.location_name || "-",
            fingerDabbededBy : element.finger_dab_operator_name || "-",
            dabbedDate : element.completed_date_time || "-",
            fingerDabType: element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
            figerDabOption : element.finger_dab_option_name || "-",
  
          }
        })
  
        let plateSamplingDetails:any = response.data?.plate_sampling_details?.map((element: any, index: number) => {
          return {
            plateSamplingType : element.test_type_name || "-",
            sampledBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
            sampledDate : element.start_date_time || "-",
          }
        })
  
        let rawMaterialDetails:any = response.data?.raw_material_test_details?.map((element: any, index: number) => {
          return {
            rawMaterialTypeName : element.material_type_name || "-",
            conductedBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
            testDate : element.start_date_time || "-",
          }
        })
  
        let surfaceMonitoringDetails:any = response.data?.surface_monitoring_details?.map((element: any, index: number) => {
          return {
            surfaceMonitoringAreaName : element.location_name || "-",
            surfaceMonitoringStartDate : element.start_date_time || "-",
          }
        })
  
  
        let incubationDetails:any = response.data?.incubation_details?.map((element: any, index: number) => {
          return {
            incubationAreaName: element.incubator_name || "-",
            incubatedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
            incubationDateTime : element.start_date_time || "-",
            incubationOutBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
            incubationoutDateTime : element.end_date_time || "-",
          }
        })
  
        let labTestDetails:any = response.data?.lab_test_details?.map((element: any, index: number) => {
          return {
            conductedBy: element.completed_by_user_firstname + " " + element.completed_by_user_lastname || "-",
            dateAndTime : element.completed_date_time || "-",
            colonyCount : element.colony_count || "-",
            imageId : element.plate_image_id || "-",
            id: element.id || "-",
            species: element.species || "-",
            genus: element.genus || "-",
          }
        })
  
  
        setSinglePlateDetails(basicDetails)
        setExposureDetails(exposureDetails)
        setFingureDabDetails(fingureDabDetails)
        setPlateSamplingDetails(plateSamplingDetails)
        setRawMaterialDetails(rawMaterialDetails)
        setIncubationDetails(incubationDetails)
        setLabTestDetails(labTestDetails)
        setSurfaceMonitoringDetails(surfaceMonitoringDetails)
      }
    }

    const handleNextClick = (currentIndex : number) => {
      if (currentIndex === 1){
        if (currentExposureIndex < exposureDetails.length - 1) {
          setCurrentExposureIndex(currentExposureIndex + 1);
        }
      } else if (currentIndex === 2) {
        if (currentIncubationIndex < incubationDetails.length - 1) {
          setCurrentIncubationIndex(currentIncubationIndex + 1);
        }
      } else if (currentIndex === 3) {
        if (currentLabTestIndex < labTestDetails.length - 1) {
          setCurrentLabTestIndex(currentLabTestIndex + 1);
        }
      } else if (currentIndex === 4) {
        if (currentFingerDabIndex < fingureDabDetails.length - 1) {
          setCurrentFingerDabIndex(currentFingerDabIndex + 1);
        }
      } else if (currentIndex === 5) {
        if (currentSamplingIndex < plateSamplingDetails.length - 1) {
          setCurrentSamplingIndex(currentSamplingIndex + 1);
        }
      } else if (currentIndex === 6) {
        if (currentRawmaterialTestIndex < rawMaterialDetails.length - 1) {
          setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex + 1);
        }
      } else if (currentIndex === 7) {
        if (currentSurfaceMonitoringIndex < surfaceMonitoringDetails.length - 1) {
          setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex + 1);
        }
      }
    };
  
    const handlePreviousClick = (currentIndex : number) => {
      if (currentIndex === 1){
        if (currentExposureIndex > 0) {
          setCurrentExposureIndex(currentExposureIndex - 1);
        }
      } else if (currentIndex === 2) {
        if (currentIncubationIndex > 0) {
          setCurrentIncubationIndex(currentIncubationIndex - 1);
        }
      } else if (currentIndex === 3) {
        if (currentLabTestIndex > 0) {
          setCurrentLabTestIndex(currentLabTestIndex - 1);
        }
      } else if (currentIndex === 4) {
        if (currentFingerDabIndex > 0) {
          setCurrentFingerDabIndex(currentFingerDabIndex - 1);
        }
      } else if (currentIndex === 5) {
        if (currentSamplingIndex > 0) {
          setCurrentSamplingIndex(currentSamplingIndex - 1);
        }
      } else if (currentIndex === 6) {
        if (currentRawmaterialTestIndex > 0) {
          setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex - 1);
        }
      } else if (currentIndex === 7) {
        if (currentSurfaceMonitoringIndex > 0) {
          setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex - 1);
        }
      }
    };

    const handleClose = () => {
      setShowView(false)
      setSinglePlateDetails({})
      setExposureDetails([])
      setFingureDabDetails([])
      setPlateSamplingDetails([])
      setRawMaterialDetails([])
      setIncubationDetails([])
      setLabTestDetails([])
    };
  

  return (
    <div>
        <HeaderSection />

        <PageHeader pageHeader='Labtest Area' />
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
        <div className='exceptionHandlingTab'>
            <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('labtestManagement.test_details')}</button>
            <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('labtestManagement.history')}</button>
        </div>

        {tab === "1" && (
          <>
          {loading ? (
                 <CustomLoader />
                ) : ( 
            <div>
                <div className='scanWrapLg'>
                    <div className='scanInputWrapLg p-1'>
                        <input type='text' ref={scanAreaRef} value={scanCode} onChange={(e) => handleScanCodeInput(e.target.value)} placeholder='Scan Code' />
                        {scanCode ? (
                              <p onClick={() => handleClearBtn()} className='scanAddText py-2 me-2 my-0 '>{t('buttons.cancel')}</p>
                            ) : (
                              <div onClick={() => setSerielScanShow(true)} className='scanBg '><BsQrCodeScan /></div>
                            )}
                    </div>
                    <div className='pt-3 mx-2'>
                          <p onClick={() => setShow(true)} className='exceptionBtn ms-3' >{t('Exception.exception')}</p>
                    </div>
                    <div className='scannedDetailsWrap ps-3' style={{"marginLeft":"15%"}}>
                         <table className=''>
                            <tbody>
                              {Object.keys(data).map((key) => (
                                  <tr key={key}>
                                    <td className='keyText'>{key}</td>
                                    <td className='px-3'>:</td>
                                    <td className='valueText'>{data[key]}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                        </table>
                        {/* <p className='keyText m-0'>Area Name : <span className='valueText'> Name</span></p>
                        <p className='keyText my-1'>Activity Status : <span className='valueText'> Status</span></p> */}
                    </div>
                        {/* {scanCode && (
                          <div className='pt-3 mx-2'>
                            <p onClick={() => setShow(true)} className='exceptionBtn ms-3' >Exception</p>
                          </div>
                        )} */}
           
                </div>
                <div className='row'>
                    <div className='col-4 '>
                        {plateDetails ? (
                            <p className='plateDetailHead m-2 ps-3'>{t('labtestManagement.plate_details')}</p>
                          ) : (
                            // <p className='plateDetailHead m-2'>{t('labtestManagement.plate_details_found')}</p>
                            <p className='plateDetailHead m-2'><span className='text-danger'>* </span>{customPlateResponse}</p>
                          )}
                        <table className='mx-2'>
                        <tbody>
                          {/* {plateDetails && (
                            Object.keys(plateDetails[0]).map((key) => (
                              <tr key={key}>
                                <td className='plateDetailText py-1 w-50'>{key}</td>
                                <td className='px-3'>:</td>
                                <td className='plateDetailBoldText'>{plateDetails[0][key]}</td>
                              </tr>
                            ))
                          )} */}
                          {plateDetails && (
                            Object.keys(plateDetails).map((key: any) => (
                              <tr key={key}>
                                <td className='plateDetailText py-1 w-50'>{key}</td>
                                <td className='px-3'>:</td>
                                <td className='plateDetailBoldText'>{plateDetails[key]}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                        </table>
                        {showViewMoreBtn && (
                          <div className="px-2 mt-3">
                              <p onClick={() => handleViewBtnClick()} className="plate-detail-flora-modal">View More</p>                         
                          </div> 
                        )}
                        
                        {/* <div className='m-2'>
                            <p onClick={() => setShow(true)} className='exceptionBtn' >Add Exception</p>
                        </div> */}
                    </div>
                    <div className='labtestRightWrap col-8 p-2'>
                      <div className='my-2'>
                        <CameraApp 
                          setColonyCount={setColonyCount} 
                          setImageId={setImageId} 
                          imageIds={imageIds}
                          setImageIds={setImageIds}
                          countAuto={countAuto} 
                          setSpots={setSpots}
                          spots={spots}
                          isCameraOpen={isCameraOpen}
                          setIsCameraOpen={setIsCameraOpen}
                          capturedImage={capturedImage}
                          setCapturedImage={setCapturedImage}
                        />
                      </div>
                        <div className='labtestInputWrap p-2'>
                          <div className='d-flex mt-3 '>
                              <div className='colonyCountWrap p-2'>
                                  <p className={`colonyCountText m-0 `}>{t('labtestManagement.colony_count')}</p>
                                  <div>
                                  <input readOnly={countAuto} className={`colonyCount m-0`} type='number' placeholder='0' value={colonyCount}  onChange={(e) => {const inputVal = parseInt(e.target.value);
                                  if (!isNaN(inputVal) && inputVal >= 0) {
                                    setColonyCount(inputVal.toString()); 
                                  }
                                  }}/>
                                  </div>
                              </div>
                              <div className='w-75 mx-3'>
                                  <textarea value={remark} onChange={(e) => (setRemark(e.target.value))} className="colonyRemarkTextArea" placeholder='Remarks will be here'></textarea>
                              </div>
                          </div>
                          <div className='d-flex mt-3 '>
                              {/* <div className='w-50 mx-2'>
                                  <textarea value={species} onChange={(e) => (setSpecies(e.target.value))} className="colonyRemarkTextArea" placeholder='Strain'></textarea>
                              </div>
                              <div className='w-50 mx-2'>
                                  <textarea value={genus} onChange={(e) => (setGenus(e.target.value))} className="colonyRemarkTextArea" placeholder='Genus'></textarea>
                              </div> */}
                              {/* <div className='w-15 mx-2'>
                                  <textarea value={textarea1} onChange={(e) => (setTextarea1(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 1'></textarea>
                              </div>
                              <div className='w-15 mx-2'>
                                  <textarea value={textarea2} onChange={(e) => (setTextarea2(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 2'></textarea>
                              </div>
                              <div className='w-15 mx-2'>
                                  <textarea value={textarea3} onChange={(e) => (setTextarea3(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 3'></textarea>
                              </div> */}
                          </div>
                          <div className='' style={{"height":"7vh"}}>
                            {errors.colonyCount && <p className="errorText p-0 m-0" >{errors.colonyCount}</p>}
                            {errors.remarks && <p className="errorText p-0 m-0" >{errors.remarks}</p>}
                          </div>
                          <div className='colonyCountBottomSec pe-3 py-1'>
                                  <button onClick={() => handleClearBtn()}  className='modalCancelBtn'>{t('buttons.cancel')}</button>
                                  <button onClick={() => handleSubmitValidations()} className='squareOrangeBtn3'>{t('buttons.submit')}</button>
                                  {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
                          </div>
                        </div>
                    </div>

                </div>

            </div>)}
          </>
        )}

        {tab === "2" && (
            <div>
                  <div className=' mx-3 pe-2'>
                      <CustomTable  
                          data={plateDetailsCompleted} 
                          columns={columns} 
                          isEditable={false} 
                          totalElements={totalElements}  
                          isActionVisible={false} 
                          isViewVisible={false} 
                          isSeachable={true}
                          plateSerialNumber={plateSerielNum}
                          plateBatch={plateBatches}
                          tableHead='History'
                          onUseEffectRequired={() => setUseEffectRequired(true)}
                          onFilterClick={handleScheduleFilter} 
                          onSortClick={handleSort}/>
                          
                  </div>
                  <div>
                    <Pagination
                      page={page}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                     />
          </div>
            </div>
        )}

        {/* add exception modal */}
        <AddException showModel={show} setShowModel={() => setShow(false)} />

        <SerielNumberScanner show={serielScanShow} setShow={setSerielScanShow} handleScanCode={handleScanCode} />
        
        <LabReportViewModel 
          showView={showView}
          singlePlateDetails={singlePlateDetails}
          currentExposureIndex={currentExposureIndex}
          currentFingerDabIndex={currentFingerDabIndex}
          currentSamplingIndex={currentSamplingIndex}
          currentRawmaterialTestIndex={currentRawmaterialTestIndex}
          currentIncubationIndex={currentIncubationIndex}
          currentLabTestIndex={currentLabTestIndex}
          currentSurfaceMonitoringIndex={currentSurfaceMonitoringIndex}
          exposureDetails={exposureDetails}
          fingureDabDetails={fingureDabDetails}
          plateSamplingDetails={plateSamplingDetails}
          rawMaterialDetails={rawMaterialDetails}
          incubationDetails={incubationDetails}
          labTestDetails={labTestDetails}
          surfaceMonitoringDetails={surfaceMonitoringDetails}
          showApproveRejectBtn={false}
          handleClose={handleClose}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />

        <AlertModal   
          show={labSubmitModal}
          onHide={() => setLabSubmitModal(false)}
          title='Alert'
          message={`submit with CFU count greater than Max Range ?`}
          onConfirm={handleAddAreaSubmit}
        />

        {/* Alert modal for download QR */}
        <AlertModal
          show={showColCountAlert}
          onHide={() => setShowColCountAlert(false)}
          title='Alert'
          message={`Submit, Plate batch will be rejected as colony count is greater than zero`}
          onConfirm={handleSubmit}
        />
    </div>
    
  )
}

export default LabTestArea