//table columns for user management table listing 
export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'equipmentCalibration.tableColumns.name',
        accessor: 'name',
      },
      {
        Header: 'equipmentCalibration.tableColumns.equipment_code',
        accessor: 'equipment_code',
      },
      {
        Header: 'tableCommon.equipment_type',
        accessor: 'type_name',
      },
      {
        Header: 'equipmentCalibration.tableColumns.calibrated_details',
        accessor: 'calibration_details',
      },
      {
        Header: 'equipmentCalibration.tableColumns.capacity',
        accessor: 'capacity',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
