import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const uploadImage = async (imageSrc : any) => {

  const base64Data = imageSrc.split(',')[1];
  const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then((res) => res.blob());

  // Create a FormData object and append the image file
  const formData = new FormData();
  formData.append('file', blob, 'captured_image.jpeg');

    try {
      const response = await axiosInstance.post(`${baseUrl}/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': authToken,
          'x-userid': authUserID
        }})

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


const downloadImage = async (
    file_id: any
  ) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/files/download?file_id=${file_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }




const FileService = {
    uploadImage,
    downloadImage,
};
  


export {FileService}