import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


  const listDeviceList = async (page : any, pageSize: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/device/list_all?page=${page}&size=${pageSize}&sort=&order=desc&status=active,inactive&keyword=&type_ids=&id`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}


const registerDevice = async (dataToSend: any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/device/create`, dataToSend,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const changeDeviceStatus = async (id: string, status: string, x_auth_token: string, x_user_id: string , interface_name : string) => {
  try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/device/change_status`, {
          id: id,
          status: status,
          interface_name: interface_name
      }, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
  }
}

const listLogDetails = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/system_logs/list`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}



  const deviceRegistration = {
    listDeviceList,
    registerDevice,
    changeDeviceStatus,
    listLogDetails
  };
  
  
export { deviceRegistration };