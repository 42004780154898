import React, {useState, useEffect, forwardRef, useImperativeHandle, Ref} from 'react';
import Modal from 'react-bootstrap/Modal';
import {TfiClose} from 'react-icons/tfi'
import {useTable, useRowSelect, useFilters, useGlobalFilter, Column, Row } from 'react-table';
import {MdEdit} from 'react-icons/md'
import {FaEye, FaSortDown, FaSortUp, FaTrashAlt} from 'react-icons/fa'
import {IoMdClose, IoMdDownload} from 'react-icons/io'
import {BiSearch} from 'react-icons/bi'
import {MdLockReset} from 'react-icons/md'
import AlertModal from '../alertModal';
import { Form } from 'react-bootstrap';
import { userService } from '../../service/userService';
import Select from 'react-select';
import FilterSec from './partials/filterSec';
import TableButtonSec from './partials/tableButtonSec';
import { FaSort } from "react-icons/fa";
import { ImArrowUpRight2 } from "react-icons/im";
import { useTranslation } from 'react-i18next';




interface CustomTableProps {
    columns: Column[];
    data: any[];
    isEditable?: boolean;
    isActionVisible : boolean;
    isViewVisible : boolean;
    removable? : boolean; 
    isSeachable?: boolean;
    isTempDeletable?: boolean;
    isToggleStatus?:boolean;
    isResetPassword?:boolean;
    isNavigateFlora?:boolean;
    addButton?:boolean;
    addButtonText?:string;
    buttonWithCloseIcon?: boolean;
    buttonWithCloseIconText?: string;
    discardButton?:boolean;
    discardButtonText?:string;
    startButton?:boolean;
    startButtonText?:string;
    endButton?:boolean;
    endButtonText?:string;
    approveButton?:boolean;
    approveButtonText?:string;
    rejectButton?:boolean;
    rejectButtonText?:string;
    plateBatch?:any[];
    plateSerialNumber?:any[];
    areaListNumber?:any[];
    areaList?: any[];
    totalElements: number;
    tableHead:string;
    isQrDownloadable?:boolean;
    changeRoleButton?:boolean;
    changeRoleButtonText?:string;
    addPlateUploadBtn?:boolean;
    addPlateUploadText?:string;
    showBlueBtn?:boolean;
    blueBtnText?:string;
    showBatchFilter?:boolean;
    showPlateStatus?: boolean
    showSerielNoFilter?:boolean;
    showPlateTypeFilter?:boolean;
    // onSelectRow: (selectedRows: string[]) => void;
    onViewClick?: (id: string, viewValue: boolean) => void;
    onStartClick?: (selectedRows: string[]) => void;
    onEndClick?: (selectedRows: string[]) => void;
    onFilterClick?: (key: string, value: any) => void;
    onSortClick?: (key: string, value: boolean) => void;
    onUseEffectRequired?: () => void;
    onAddClick?: () => void;
    onDiscardClick?: () => void;
    onEditClick?: (id: string) => void;
    onRoleDEpartmentClick?: (id: string[]) => void;
    onActionClick?: (selectedRows: string[]) => void;
    onRemoveClick?: (rowId: string) => void;
    onStatusChange?: (id:any , status : string) => void;
    onCellClick?: (rowData: any[]) => void;
    onResetPassClick?: (id:any) => void;
    onNavigateFloraClick?:(id:any) => void; 
    onQrDownloadClick?: (slno:string[], type:string) => void;
    onUploadClick?: () => void;
    onBlueBtnClick?: () => void;
    onRowClick?: (id: string) => void;
    onCloseBtnClick?: () => void;
    onTempDeleteClick?: (id: string) => void;
    emptyTableText?: string;
}

const CustomTable: React.FC<CustomTableProps> = ({ 
    data, 
    columns, 
    totalElements,
    isEditable, 
    isActionVisible , 
    isViewVisible,
    tableHead,
    removable, 
    isSeachable,
    isTempDeletable,
    isToggleStatus,
    isResetPassword,
    isNavigateFlora,
    addButton,
    addButtonText,
    buttonWithCloseIcon,
    buttonWithCloseIconText,
    discardButton,
    discardButtonText,
    startButton,
    startButtonText,
    endButton,
    endButtonText,
    approveButton,
    approveButtonText,
    rejectButton,
    rejectButtonText,
    plateBatch,
    plateSerialNumber,
    areaListNumber,
    isQrDownloadable,
    changeRoleButton,
    changeRoleButtonText,
    addPlateUploadBtn,
    addPlateUploadText,
    showBlueBtn,
    blueBtnText,
    showBatchFilter,
    showSerielNoFilter,
    showPlateStatus,
    areaList,
    showPlateTypeFilter,
    // onSelectRow,
    onViewClick,
    onStartClick,
    onEndClick,
    onUseEffectRequired,
    onFilterClick,
    onSortClick,
    onCellClick,
    onAddClick,
    onUploadClick,
    onBlueBtnClick,
    onDiscardClick,
    onEditClick,
    onActionClick,
    onRemoveClick,
    onStatusChange,
    onResetPassClick,
    onNavigateFloraClick,
    onQrDownloadClick,
    onRoleDEpartmentClick,
    onRowClick,
    emptyTableText,
    onCloseBtnClick,
    onTempDeleteClick,
  }) => {


    const [newData, setData] = useState(data);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [showStartAlert, setShowStartAlert] = useState(false)
    const [showEndAlert, setShowEndAlert] = useState(false)
    const [showApproveAlert, setShowApproveAlert] = useState(false)
    const [showRejectAlert, setShowRejectAlert] = useState(false)

    const [showRemoveAlert, setShowRemoveAlert] = useState(false)
    const [isAnyRowChecked, setIsAnyRowChecked] = useState(false);
    const [actionClass, setActionClass] = useState("")
    const [rowId, setRowId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [selectedRole, setSelectedRole] = useState("")
    const [searchKey, setSearchKey] = useState("")
    const [sortValue, setSortValue] = useState(true);
    const { t } = useTranslation();


    


    console.log(data, "table data")


    useEffect(() => {
      if (removable) {
        setActionClass("fixed-column3");
      } else if (isViewVisible) {
        setActionClass("fixed-column1");
      } else {
        setActionClass("fixed-column2");
      }
    }, [removable, isViewVisible]);

    useEffect(() => {
      if (onActionClick) {
        onActionClick(selectedRows);
      }
    }, [selectedRows]);


    const statusColorMap: { [key: string]: string } = {
      InProgress: 'inprogress',
      Pending: 'pending',
      Completed: 'completed',
      "Waiting for QA Approval" : "qa_waiting_approval",
      "QA APPROVED" : "ready_to_use",
      "READY TO USE" : "ready_to_use",
      "ACTIVITY INPROGRESS" : "under_test",
      "QA REJECTED" : "rejected",
      "QUARANTINE" : "quarantine"
      // Add mappings for other status values here
    };

    const filteredItem = data.filter((item: any) => {
      return item.slno === selectedRows[0];
    });

   
    const isHeaderCheckboxChecked = selectedRows.length === data.length;



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
        setGlobalFilter,
        state,
      } = useTable({
        columns,
        data,
    },useFilters,
    useGlobalFilter);

    const { globalFilter } = state;


    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || undefined;
      setGlobalFilter(value);
    };

    function handleEdit(areaCode : any) {
        // Here, you can perform the edit action using the areaCode
        console.log('Edit button clicked for area code:', areaCode);
        // Implement your edit logic here
      }

    const toggleRowSelected = (rowId: string) => {
      // console.log(rowId, "rowId")
        setSelectedRows(prevSelectedRows => {
          const updatedSelectedRows = prevSelectedRows.includes(rowId)
            ? prevSelectedRows?.filter(id => id !== rowId)
            : [...prevSelectedRows, rowId];
    
          // onSelectRow(updatedSelectedRows); 
          return updatedSelectedRows; // Return the updated state value
        });
      };

    const toggleSelectAll = () => {
      if (selectedRows.length === data.length) {
        // All rows are selected, so unselect all
        setSelectedRows([]);
        // onSelectRow([]);
      } else {
        // Not all rows are selected, so select all
        // const allRowIds = data.map((row) => row.slno);
        const adminSlNos = data.filter((row) => row.role !== "Admin")
                       .map(row => row.slno);
        setSelectedRows(adminSlNos);
        // onSelectRow(allRowIds);
      }

    };

    const clearAllSelected = () => {
      setSelectedRows([]);
      console.log("clear alll")
    };

    const handleViewClick = (id: string) => {
        const viewValue = true; // Change this based on your logic
        console.log(onViewClick, "onViewClick")
        if (onViewClick) {
          onViewClick(id, viewValue);
        }
        // onViewClick(id, viewValue); // Call the callback with id and viewValue
    };

    const handleStartButton = () => {
      setShowStartAlert(false)
      setShowApproveAlert(false)
      if (onStartClick) {
        onStartClick(selectedRows);
      }
      setSelectedRows([]);
    }

    const handleAddButton = () => {
      if (onAddClick) {
        onAddClick();
      }
    }

    const handleUploadButton = () => {
      if (onUploadClick) {
        onUploadClick();
      }
    }

    const handleBlueBtnClick = () => {
      if (onBlueBtnClick) {
        onBlueBtnClick();
      }
    }

    const handleStatusChange = (id: string, status: string) => {
      if(onStatusChange){
        onStatusChange(id, status);
       
      }
    }

    const handleDiscardButton = () => {
      if (onDiscardClick) {
        onDiscardClick();
      }
    }

    const handleEditClick = (id: string) => {
      if (onEditClick) {
        onEditClick(id);
      }
    }

    const handleRoleDepartment = () => {
      if (onRoleDEpartmentClick) {
        onRoleDEpartmentClick(selectedRows);
      }
    }

    

    const handleRemoveButton = () => {
      if (onRemoveClick) {
        onRemoveClick(rowId);
        setShowRemoveAlert(false)
      }
    }

    const handleUseEffectRequired = () => {
      if (onUseEffectRequired) {
        onUseEffectRequired();
      }
    }

    const handleEndButton = () => {
      setShowEndAlert(false)
      setShowRejectAlert(false)
      if (onEndClick) {
        onEndClick(selectedRows);
      }
      setSelectedRows([]);
    }
    
    const handleRemoveClick = (id: any) => {
      setShowRemoveAlert(true)
      setRowId(id)
    }

    // const confirmRowRemove = () => {
    //   const updatedData = data.filter((row) => row.slno !== rowId);
    //   // Update the data source
    //   console.log(updatedData, "updatedData")
    //   setData(updatedData); 
    //   setShowRemoveAlert(false)
    // }

   

    const handleRoleChange = (columName: string, e: any) => {
      console.log(e ,"---->")
      if (onFilterClick) {
        onFilterClick(columName, e);
      }
    };

    const handleSearchKey = (e: any) => {
      setSearchKey(e)
      if(!e) {
        handleRoleChange("search", e)
      }
    }

    const handleCellClick = (cell: any) => {
      if (onCellClick) {
        onCellClick(cell);
      }
    };

    const handleSortClick = (column: any) => {
      if (onSortClick) {
        onSortClick(column.id, sortValue);
        setSortValue(!sortValue)
      }
    }

   
      // const deleteUserTemp = async (id: string) => {
      //   console.log(id , "userStatus");
      //   try {
      //     const response = await userService.deleteUserTemp(id);
          
      //     if (response.status && response.statusCode === 200) {
      //       handleUseEffectRequired();
      //     }
      //     console.log(response, "response");
      //   } catch (error) {
      //     console.error(error);
      //   }
      // };
    


  return (
    <>

    <div className='areaManagementSearchSec'>
      <div className='table-left-head'>
        <TableButtonSec
          selectedRows={selectedRows}
          tableHead={tableHead}
          totalElements={totalElements}
          startButton={startButton}
          startButtonText={startButtonText}
          endButton={endButton}
          endButtonText={endButtonText}
          addButton={addButton}
          addButtonText={addButtonText}
          buttonWithCloseIcon={buttonWithCloseIcon}
          buttonWithCloseIconText={buttonWithCloseIconText}
          discardButton={discardButton}
          discardButtonText={discardButtonText}
          approveButton={approveButton}
          approveButtonText={approveButtonText}
          changeRoleButton={changeRoleButton}
          changeRoleButtonText={changeRoleButtonText}
          rejectButton={rejectButton}
          rejectButtonText={rejectButtonText}
          addPlateUploadText={addPlateUploadText}
          showBlueBtn={showBlueBtn}
          blueBtnText={blueBtnText}
          addPlateUploadBtn={addPlateUploadBtn}
          isQrDownloadable={isQrDownloadable}
          data={data}
          setShowStartAlert={setShowStartAlert}
          setShowEndAlert={setShowEndAlert}
          setShowApproveAlert={setShowApproveAlert}
          setShowRejectAlert={setShowRejectAlert}
          clearAllSelected={clearAllSelected}
          handleAddButton={handleAddButton}
          handleUploadButton={handleUploadButton}
          handleBlueBtnClick={handleBlueBtnClick}
          handleDiscardButton={handleDiscardButton}
          handleRoleDepartment={handleRoleDepartment}
          onCloseBtnClick={onCloseBtnClick}
          onQrDownloadClick={onQrDownloadClick}
        />
        </div>    
        <div className='d-flex'>
            <FilterSec 
                columns={columns} 
                handleRoleChange={handleRoleChange} 
                plateBatch={plateBatch} 
                plateSerialNumber={plateSerialNumber} 
                areaListNumber={areaListNumber}
                showBatchFilter={showBatchFilter}
                showSerielNoFilter={showSerielNoFilter}
                showPlateStatus={showPlateStatus}
                areaList={areaList}
                showPlateTypeFilter={showPlateTypeFilter}
                
            />

            {isSeachable && (
              <div>
                <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Search</label> 
                </div>
                <div className='areaManagementSearch ms-2'>
                    <input type='search' placeholder='Search' onChange={(e) => handleSearchKey(e.target.value)} className='searchInput ps-2' />
                    <BiSearch 
                      style={{cursor: "pointer"}} 
                      onClick={(e) => handleRoleChange("search", searchKey)} 
                      className='m-2' 
                      fill='rgba(6, 21, 43, 0.6)' 
                      fontSize={20}/>
                </div>
              </div>
            )}
           
        </div>
    </div>
  <div className='d-flex justify-content-end mx-2'>
    {/* {columns.some((column : any) => column.Header === 'Incubated by') && (
                <select className='areaTypeSelect mx-3 py-2 mb-2' onChange={(e) => handleRoleChange("Area Name", e.target.value)} >
                    <option>Activity Filter</option>
                    <option value={9}>Pending</option>
                    <option value={12}>Inprogress</option>
                    <option value={13}>Completed</option>
                </select>
            )} */}
    </div>

    <div className='test12'>
    <table className="custom-table" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr className='tableHeadRow' {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th className='tableHeadData2' {...column.getHeaderProps()}>{t(column.Header)}
                <span className='ms-1' style={{ verticalAlign: 'middle' }}>
                  <FaSort onClick={() => handleSortClick(column)} />
                </span>
              </th>
            ))}

            {isResetPassword && (
                <>
                  <th className='tableHeadData2'>Password</th>
                </>
            )}
            {isNavigateFlora && (
                <>
                  <th className='tableHeadData2'>Navigate</th>
                </>
            )}
            {isTempDeletable && (
               <>
               <th className='tableHeadData2'>Active / Inactive</th>
             </>
            )}
            {isEditable && (
                <>
                  {/* <th className='tableHeadData2'>Edit</th> */}
                  <th className='tableHeadData2'>Edit</th>
                </>
            )}
             {isToggleStatus && (
                <><th className='tableHeadData2 fixed-column2'>Status</th></>
            )}
            {isActionVisible && (
                    <>
                        <th className={`tableHeadData2 ${actionClass}`}>
                          <div className='d-flex'>
                            <span>Action</span>
                            <label className="custom-checkbox ">
                              <input onClick={toggleSelectAll} checked={isHeaderCheckboxChecked} type="checkbox" />
                              <span className="checkbox-checkmark"></span>
                            </label>
                          </div>
                         
                          {/* <input  onClick={toggleSelectAll} checked={isHeaderCheckboxChecked}  className='headerCheckBox' type="checkbox" /> */}
                        </th>

                    </>
            )}
            {isViewVisible && (
                <><th className='tableHeadData2 fixed-column2'>View</th></>
            )}
            {/* {isQrDownloadable && (
                <><th className='tableHeadData2 fixed-column2'>Download QR</th></>
            )} */}
            {removable && (
                <><th className='tableHeadData2 fixed-column2'></th></>
            )}
          
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.map((row : any)=> {
          prepareRow(row);
          return (
            <tr className='tableDataRow' onClick={() => onRowClick && onRowClick(row.original.id)} {...row.getRowProps()}>
              {row.cells.map((cell : any) => {
                const cellValue = cell.value;
                const content = cellValue !== undefined && cellValue !== '' ? cellValue : '-';
                const isUsernameColumn = cell?.column?.Header === 'plateInventory.tableColumns.quantity';

                if (isUsernameColumn) {
                  const statusClass = 'quantity'; 
                  return <td className={`tableHeadData ${statusClass}`} onClick={() => handleCellClick(cell)} {...cell.getCellProps()}>{content}</td>;
                } else {
                  const statusClass = statusColorMap[cellValue] || '';
                  return <td className={`tableHeadData ${statusClass}`} onClick={() => handleCellClick(cell)} {...cell.getCellProps()}>{content}</td>;
                }
              })}
              {isResetPassword && (
                <td className={`tableHeadData ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <div style={{cursor: "pointer"}} onClick={() => onResetPassClick && onResetPassClick(row.original.user_id)} className='ps-3'>
                      <MdLockReset fill='rgba(6, 21, 43, 0.6)' fontSize={25} />
                    </div>
                </td>
              )}
              {isNavigateFlora && (
                <td className={`tableHeadData ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <div style={{cursor: "pointer"}} onClick={() => onNavigateFloraClick && onNavigateFloraClick(row.original.serial_number)} className='ps-3'>
                      <ImArrowUpRight2 fill='rgba(6, 21, 43, 0.6)' fontSize={20} title='Click here to Navigate'/>
                    </div>
                </td>
              )}
              {
                isTempDeletable && (
                  <td style={{paddingTop: "12px"}} className={`tableHeadData d-flex ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <>
                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className='custom-switch-class'
                        checked={!row.original?.is_deleted}
                        // onChange={() => deleteUserTemp(row.original?.id)}
                        onClick={() => onTempDeleteClick && onTempDeleteClick(row.original.id)}
                        
                      />
                    </Form>
                    <p>{!row.original?.is_deleted ? 'Active' : 'Inactive'}</p>
                    </>

                    {/* <div className='ps-2'><FaTrashAlt className='tableDataDelete' fill='rgba(6, 21, 43, 0.6)' fontSize={18} /></div> */}
                </td>
                )
              }

              {isEditable && (
                <>
               <td className={`tableHeadData ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <div style={{cursor: "pointer"}} className='ps-2 ' onClick={() => handleEditClick(row.original.slno)} >
                      <MdEdit fill='rgba(6, 21, 43, 0.6)' fontSize={18} /></div>
                </td>
                {/* <td className={`tableHeadData d-flex ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <>
                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className='custom-switch-class'
                        checked={!row.original?.is_deleted}
                        onChange={() => deleteUserTemp(row.original?.id)}
                      />
                    </Form>
                    <p>{!row.original?.is_deleted ? 'Active' : 'Inactive'}</p>
                    </>

                    <div className='ps-2'><FaTrashAlt className='tableDataDelete' fill='rgba(6, 21, 43, 0.6)' fontSize={18} /></div>
                </td> */}
              </>
              )}
              {isToggleStatus && (
                <>
                  <td className={`tableHeadData ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className='custom-switch-class'
                        checked={row.original?.status === "active"}
                        onClick={() => handleStatusChange(row.original?.id, row.original?.status)}
                      />
                    </Form>
                  </td>
                </>
              )}
              {isActionVisible && (
                <>
                  <td className={`tableHeadData ${actionClass} ${row.original.role === "Admin" ? 'disabledArea' : ''}`}>
                      <label className="custom-checkbox">
                        <input checked={selectedRows.includes(row.original.slno)} type="checkbox" onClick={() => toggleRowSelected(row.original.slno)} />
                        <span className="checkbox-checkmark"></span>
                      </label>
                    {/* <input className='ps-2 headerCheckBox' checked={selectedRows.includes(row.original.slno)}  type="checkbox" onClick={() => toggleRowSelected(row.original.slno)} style={{marginLeft : "15px"}}/> */}
                  </td>
                </>
              )}
              {isViewVisible && (
                <>
                  <td className='tableHeadData fixed-column2'>
                    <div style={{cursor: "pointer"}} className='ps-2' onClick={() => handleViewClick(row.original.slno)}>
                      <FaEye fill='rgba(6, 21, 43, 0.6)' fontSize={18} /></div>
                  </td>
                </>
              )}
              {/* {isQrDownloadable && (
                <>
                  <td className='tableHeadData'>
                    <div style={{cursor: "pointer"}} className='ps-2' onClick={() => onQrDownloadClick && onQrDownloadClick([row.original.slno], "single")}>
                      <IoMdDownload fill='rgba(6, 21, 43, 0.6)' fontSize={18} />
                    </div>
                  </td>
                </>
              )} */}
              {removable && (
                <>
                  <td className='tableHeadData fixed-column2'>
                    <div className='ps-2' onClick={() => handleRemoveClick(row.original.slno)}>
                      {row.original?.status?.includes("Pending") ?(
                        <button className='tableRemoveBtn'>Remove</button>
                      ) : (<div className='whiteBackground'></div>)}
                    </div>
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
    {data.length < 1 && (<p className='emptyTableText'>Table is empty. No data to display</p>)}
    </div>

        <AlertModal
          show={showStartAlert}
          onHide={() => setShowStartAlert(false)}
          title='Alert'
          message={`Start Selected ${selectedRows.length} plates ${tableHead}?`}
          onConfirm={handleStartButton}
        />

        <AlertModal
          show={showEndAlert}
          onHide={() => setShowEndAlert(false)}
          title='Alert'
          message={`End Selected ${selectedRows.length} plates ${tableHead}?`}
          onConfirm={handleEndButton}
        /> 

        <AlertModal
          show={showApproveAlert}
          onHide={() => setShowApproveAlert(false)}
          title='Alert'
          message={`Approve Selected ${selectedRows.length} plates ${tableHead}?`}
          onConfirm={handleStartButton}
        />

        <AlertModal
          show={showRejectAlert}
          onHide={() => setShowRejectAlert(false)}
          title='Alert'
          message={`Reject Selected ${selectedRows.length} plates ${tableHead}?`}
          onConfirm={handleEndButton}
        /> 

      {/* Alert modal for Remove */}
      <Modal centered size='sm' show={showRemoveAlert} onHide={() => setShowRemoveAlert(false)} >
          <div >
                <div className='modalHead'>
                  <p className='modalHeadText2 mx-3 my-2'>Alert</p>
                  <div style={{cursor: "pointer"}} onClick={() => setShowRemoveAlert(false)} className='p-2 mx-2 '>
                    <TfiClose fill="#FFFFFF" />
                  </div>
                </div>
                <div className='text-center mt-3 mb-5'>
                  <p className='normalText2 '>Are you sure want to <br/> 
                    <span><strong> Remove the Plate </strong></span>
                  </p>
                </div>
                <div className='d-flex justify-content-around pb-3'>
                  <button onClick={() => setShowRemoveAlert(false)} className='modalCancelBtn2'>Cancel</button>
                  <button onClick={() => handleRemoveButton()} className='squareOrangeBtn3'>Confirm</button>
                  {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}

                </div>
            </div>
        </Modal>

  
    </>
  )
}

export default CustomTable