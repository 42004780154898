import React, { useState ,useEffect } from "react";
import CustomTable from "../../../widgets/table/index";
import { BiSearch } from "react-icons/bi";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import {userService as userRoleServive} from '../../../service/userService';
import { userService as plateRelatedService } from '../../../service/plateServices';
import Pagination from "../../../widgets/pagination";
import Plates from "./partials/plates";
import { columns, discardColumns } from './partials/columns';
import Discards from "./partials/discards";
import { Modal } from 'react-bootstrap';
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../widgets/alertModal";
import CustomToast from "../../../widgets/toaster";
import {plateModels} from "./partials/plateModels"
import { useTranslation } from 'react-i18next';
import { BatchService } from "../../../service/plateServices/discard";


const PlateInventory = () => {

  const { t } = useTranslation();
  const [tab, setTab] = useState("1");
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  
  // pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)

  const [plateStatus, setPlateStatus] = useState<any | null>("");
  const [plateBatches, setPlateBatches] = useState([])
  const [plateSerielNum, setPlateSerielNums] = useState([])
  const [keyWord, setkeyWord] = useState("")

  const [userId, setUserId] = useState<number>(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [showAddUserAlert, setShowAddUserAlert] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadAddModal] = useState(false)
  const [addPlateData, setAddPlateData] = useState({
    batchCode:"",
    serialNumber: "",
    quantity: "",
    manufactureDate: "",
    expiryDate : "",
    plateType : "",
    manufactureDetails: ""
  });
  interface Errors {
    batchCode?: string;
    serialNumber?: string;
    quantity?: string;
    manufactureDate?: string;
    expiryDate?: string;
    plateType?: string;
    manufactureDetails?:string;

  }
  interface NextSerial{
    serial_number?: string;
    next_serial_value?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [plateTypes, setPlateTypes] = useState([]);
  const [nextSerial, setNextSerial] = useState<NextSerial>({});
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [batchList, setBatchList] = useState([])
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [permission, setPermission] = useState<any>([]);



  useEffect(() => {
    // getPlateList();
    getBatchList(sort , order);
    setUseEffectRequired(false)
    listPlateBatch()
    listSerialNumber()
}, [useEffectRequired])

//handle table pagination 
const handlePageChange = (newPage : any, pageSize?: number) => {
  setPage(newPage);
  if(pageSize) {
    setPageSize(pageSize)
  }
  setUseEffectRequired(true)
};

//api call for table plate listing 
const getPlateList = async () => {
    try {
      const response = await plateRelatedService.getPlateList(page, pageSize, sort, order, plateStatus, keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                const role = element.role_master_id === "1" ?
                 "Admin" : element.role_master_id === "2" ?
                 "Operator" : element.role_master_id === "3"? 
                 "Lab Operator": null
                 const statusNumber = parseInt(element.plate_status, 10); 
                 const status = plateRelatedService.getPlateStatus(statusNumber);
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_number,
                    serial_number: element.serial_number,
                    manufacturing_date: element.manufacturing_date,
                    expiry_date: element.expiry_date,
                    status: status,
                };
              });
              setPlateDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setPlateDetails([])
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

//api call for table plate listing 
const getBatchList = async (sort:any , order:any) => {
  try {
    // const response = await userService.getPlateList(page, pageSize, sort, order, plateStatus, keyWord);
    const response = await BatchService.getBatchDetails(page, pageSize, sort,order , keyWord, plateStatus)
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              const StatusConstants = {
                QUARANTINE: "0",
                QA_APPROVED: "1",
                READY_TO_USE: "2",
                ACTIVITY_INPROGRESS: "3",
                QA_REJECTED : "4",
                UNDER_TEST:"5"
              };

              let status;

              // Mapping numeric status to string representation
              switch (element.batch_status) {
                  case StatusConstants.UNDER_TEST:
                      status = "Waiting for QA Approval";
                      break;
                  case StatusConstants.QA_APPROVED:
                      status = "QA APPROVED";
                      break;
                  case StatusConstants.READY_TO_USE:
                      status = "READY TO USE";
                      break;
                  case StatusConstants.ACTIVITY_INPROGRESS:
                      status = "ACTIVITY INPROGRESS";
                      break;
                  case StatusConstants.QA_REJECTED:
                      status = "QA REJECTED";
                      break;
                  case StatusConstants.QUARANTINE:
                      status = "QUARANTINE";
                      break;
                  default:
                      status = "UNKNOWN STATUS";
              }

              return { 
                  id : element.id,
                  slno: index + 1, 
                  batch_number: element.batch_no,
                  manufacturer_details: element.manufacturer_details,
                  plate_type: element.plate_size_type_name,
                  quantity: element.plate_count,
                  status: status
              };
            });
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    } else {
      setPlateDetails([])
    }
  } catch (error) {
    console.error('Plate response', error);
  }

}

  //handle table filters
const handleFilter = (key: string, value: string) => {
    if(key === "status") {
      setPlateStatus(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setUseEffectRequired(true)
}

//batch list for filter dropdown 
const listPlateBatch = async () => {
  try {
    const response = await plateRelatedService.listPlateBatch();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.batch_number,
                  label: element.batch_number ,
              };
            });
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}

//serial number listing for table drop down 
const listSerialNumber = async () => {
  try {
    const response = await plateRelatedService.listSerialNumber();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.serial_number,
                  label: element.serial_number ,
              };
            });
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}

//handle upload button click 
const onUploadClick = () => {
  getPlateTypeList();
  listNextPlateSerial();
  setShowAddModal(true)
}

//handle add modal 
const onAddClick =() => {
  setShowBulkUploadAddModal(true)
}

const addExceptionClose = () => {
  setShowBulkUploadAddModal(false)
  setUploadedFile(null)
  setUseEffectRequired(true)
  // setClearData(true)
}

//clear add plate modal after close 
const addPlateClose = () => {
  setShowAddModal(false)
  setErrors({})
  setAddPlateData({
    batchCode:"",
    serialNumber: "",
    quantity: "",
    manufactureDate: "",
    expiryDate : "",
    plateType : "",  
    manufactureDetails: ""
  });
  setNextSerial({
    serial_number: "",
    next_serial_value: ""
  })
}

//onchage add plate event 
const handleAddPlateChange = (e : any) => {
  const { name, value } = e.target;
  setAddPlateData((prevFormData : any) => ({
    ...prevFormData,
    [name]: value,
  }));
};

//handle plate alert 
const handlePlateAlert = () => {
  const errors: Errors = {};
  if (!addPlateData.batchCode) {
    errors.batchCode = "Please enter Batch code";
  }
  // if (!addPlateData.serialNumber) {
  //   errors.serialNumber = "Please enter Serial Number";
  // }
  if (!addPlateData.quantity) {
    errors.quantity = "Please enter Quantity";
  }
  if (!addPlateData.manufactureDate) {
    errors.manufactureDate = "Please Enter manufacture Date";
  }
  if (!addPlateData.expiryDate) {
    errors.expiryDate = "Please Enter Expiry Date";
  }
  if (!addPlateData. manufactureDetails) {
    errors.manufactureDetails = "Please enter Manufacturer Details";
  }
  
  if (Object.keys(errors).length > 0) {
    setErrors(errors);
    return;
  }
  setShowAddUserAlert(true)
  setErrors({});
}

//api call on add plate event
const handleAddPlateSubmit = async () => {
  const dataToSend = {
    user_id: userId,
    batch_number : addPlateData.batchCode,
    range_from : nextSerial.next_serial_value,
    plate_count : addPlateData.quantity,
    manufacturing_date : addPlateData.manufactureDate,
    expiry_date : addPlateData.expiryDate,
    plate_type : addPlateData.plateType,
    manufacturer_details : addPlateData.manufactureDetails,
    interface_name : "Plate Inventory"
  }
  
  try {
    const response = await plateRelatedService.createPlate(dataToSend);
    if (response.status && response.statusCode === 200) {
      setToastType("success")
      setToastMessage(response.message)
      // getPlateList();
      getBatchList(sort ,order)
    } else {
      setToastType("error")
      setToastMessage(response.message)
    }
    setShowAddUserAlert(false)
    setShowToast(true)
    addPlateClose()
    setUseEffectRequired(true)
  } catch (error) {
    console.error(error);
  }
}

//api call to get the plate types 
const getPlateTypeList = async () => {
  try {
    const response = await plateRelatedService.listAllPlateTypes();
    if(response.status && response.statusCode === 200){
      setPlateTypes(response.data)
    }
  } catch (error) {
    console.error('user response', error);
  }
}
// function to sort table
// const handleSort = (key: string, value: boolean) => {
//   setSort(key)
//   if (value) {
//     setOrder("asc")
//   } else {
//     setOrder("desc")
//   }
//   setUseEffectRequired(true)
// }

const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")  
    getBatchList(key,"asc")    
  } else {
    setOrder("desc")
    getBatchList(key,"desc")
  }
 
}
//set the serial number by default in add modal
const listNextPlateSerial = async () => {
  try {
    const response = await plateRelatedService.listNextPlateSerial();
    if(response.status && response.statusCode === 200){
      setNextSerial(response.data)
    }
  } catch (error) {
    console.error('user response', error);
  }
}

//get permission by role 
const getPermissionByRole = async (role : number) => {
  try {
    const response = await userRoleServive.getPermissionByRole(role);
    if(response.status && response.statusCode === 200){
      setPermission(response.data.permissions)
      // try {
      //     if ( Array.isArray(response.data) && response.data?.length > 0) {
      //       let rowData: any = response.data.map((element: any, index: number) => {

      //         return { 
      //           // slno: index + 1, 
      //           label: element.title,
      //           value : element.id,
      //           // status : element.status,
      //         };
      //       });
      //       // setUserRoleList(rowData);
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
    }
  } catch (error) {
    console.error(error);
  }
}

useEffect (() => {
  var storedData = localStorage.getItem('userDetails');

  if(storedData) {
    var storedObject = JSON.parse(storedData);
    setUserId(storedObject[0].id);
    getPermissionByRole(storedObject[0].role_master_id);
  }
  }, [])


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Plate Inventory" />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t('plateInventory.plates')}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
          >
            {t('plateInventory.discard')}
        </button>
      </div>
      {tab === "1" && (
         <Plates
          plateDetails={plateDetails}
          columns={columns}
          plateSerielNum={plateSerielNum}
          plateBatches={plateBatches}
          handleFilter={handleFilter}
          handleSort={handleSort}
          handlePageChange={handlePageChange}
          totalElements={totalElements}
          page={page}
          totalPages={totalPages}
          onUploadClick={onUploadClick}
          onAddClick={onAddClick}
          setUseEffectRequired={setUseEffectRequired}
         />
      )}

      {tab === "2" && (
        <Discards
          columns={discardColumns}
          plateSerielNum={plateSerielNum}
          plateBatches={plateBatches}
          setToastType={setToastType}
          setToastMessage={setToastMessage}
          setShowToast={setShowToast}
        />
      )}





<Modal
              className="top-right-modal"
              centered={false}
              show={showAddModal}
              onHide={() => addPlateClose()}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{t('plateInventory.bulk')}</p>
                <div onClick={() => addPlateClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.batch_code')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="batchCode"
                       value={addPlateData.batchCode}
                       name="batchCode"
                       type='text'
                       placeholder='Batch Code'
                       className="placeholder-style"
                       onChange={handleAddPlateChange}
                    />
                    {errors.batchCode && <p className="errorText">{errors.batchCode}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label>{t('plateInventory.starting_serial')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="serialNumber"
                       value={nextSerial?.serial_number || ''}
                       name="serialNumber"
                       type='text'
                       placeholder='Starting Serial Number'
                       className="placeholder-style"
                       onChange={handleAddPlateChange} 
                       disabled
                       style={{backgroundColor: "lavenderblush", fontWeight : "600"}}
                    />
                    {errors.serialNumber && <p className="errorText">{errors.serialNumber}</p>}
      
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.quantity')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="quantity"
                      value={addPlateData.quantity}
                      name="quantity"
                      type='text'
                       placeholder='Quantity'
                       className="placeholder-style"
                      onChange={handleAddPlateChange} 
                    />
                    {errors.quantity && <p className="errorText">{errors.quantity}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.manufactureDate')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="manufactureDate"
                       value={addPlateData.manufactureDate}
                       name="manufactureDate"
                       type='date'
                       placeholder='Manufacture Date'
                       className="placeholder-style"
                       onChange={handleAddPlateChange} 
                      //  className="custom-time-input"
                    />
                    {errors.manufactureDate && <p className="errorText">{errors.manufactureDate}</p>}
                  </div>
                </div>
                <div className="selectWrap">
                <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.expiryDate')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="expiryDate"
                       value={addPlateData.expiryDate}
                       name="expiryDate"
                       type='date'
                       placeholder='Expiry Date'
                       onChange={handleAddPlateChange} 
                       min={addPlateData.manufactureDate}
                       disabled={!addPlateData.manufactureDate}
                      //  className="custom-time-input"
                      className="placeholder-style"
                    />
                    {errors.expiryDate && <p className="errorText">{errors.expiryDate}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.plateType')}</label>
                    <br />
                    {/* <input 
                      id="plateType"
                      value={addPlateData.plateType}
                      name="plateType"
                      type='text'
                      placeholder='Plate Type'
                      className="placeholder-style"
                      onChange={handleAddPlateChange} 
                    /> */}
                    <select
                      id="plateType"
                      value={addPlateData.plateType}
                      name="plateType"
                      onChange={handleAddPlateChange} 
                    >
                      <option value="">select</option>
                      {plateTypes.map((obj : any, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.plate_type}
                          </option>
                      ))}
                    </select>
                    {errors.plateType && <p className="errorText">{errors.plateType}</p>}
                  </div>               
                </div>
  
                <div className="selectWrap-email">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.manufacture_details')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="manufactureDetails"
                      value={addPlateData.manufactureDetails}
                      name="manufactureDetails"
                      type='text'
                       placeholder='Manufacturer Details'
                       className="placeholder-style"
                      onChange={handleAddPlateChange} 
                    />
                    {errors.manufactureDetails && <p className="errorText">{errors.manufactureDetails}</p>}
                  </div>
                </div>
                <div className="form-group my-1"></div>
              </div>
              <div className="bottomArea">
            <button onClick={() => addPlateClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handlePlateAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
</Modal>
        {/*Modal for Bulk upload */}
        <plateModels.BulkUploadModel 
          showBulkUploadModel={showBulkUploadModal}
          addExceptionClose={addExceptionClose}
          setUploadedFile={setUploadedFile}
          uploadedFile={uploadedFile}
        />
        {/*Alert for Bulk upload */}
        <AlertModal 
          show={showAddUserAlert}
          onHide={() => setShowAddUserAlert(false)}
          title='Alert'
          message={`Add Bulk Upload`}
          onConfirm={handleAddPlateSubmit}
        />
    </div>
  );
};
export default PlateInventory;
