import { authenticationService } from "../service/authentication";



    let authToken = ""
    let authUserID = ""
    let userId = ""

    const storedData = localStorage.getItem('userDetails');

    if (storedData) {
      try {
        const storedObject = JSON.parse(storedData);
        if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
          authToken = storedObject[0].x_auth_token
          authUserID = storedObject[0].x_userid
          userId = storedObject[0].id
        }
      } catch (error) {
        console.error('Failed to parse user details:', error);
      }
    }



    export const handleLogOut =  async () => {
      localStorage.removeItem('userDetails');
      localStorage.removeItem('loginDetails');
      window.location.replace("/");
        // try {
        // const response = await authenticationService.LogoutApi(userId, authToken, authUserID);
        // if (response.status && response.statusCode === 200) {
        //     localStorage.removeItem('userDetails');
        //     localStorage.removeItem('loginDetails');
        //     window.location.replace("/");
        // }else{
        // }
        // } catch (error) {
        // console.error("Error signing in:", error);
        // }
    }