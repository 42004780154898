import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { useTranslation } from 'react-i18next';
import { historicalServices } from '../../../../service/historicalDashboard'
import {useFilter} from '../../dashbord/partials/FilterContext'
import { setLoading } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import DownloadPdf from '../../../../widgets/downloadPdf'
const moment = require('moment');



//Column name for labtest table 
const columns : any  = [
      {
        Header: "tableCommon.sl_no",
        accessor: "slno",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.plateSerialNumber",
        accessor: "plate_serial_number",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.batchNumber",
        accessor: "plate_batch_number",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.autoclaveNo",
        accessor: "location_name",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.autoclaveStartDateTime",
        accessor: "aut_started_date_time",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.autoclaveStartedBy",
        accessor: "aut_started_by",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.autoclaveEndDateTime",
        accessor: "aut_ended_date_time",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.autoclaveEndedBy",
        accessor: "aut_ended_by",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.discrepanciesIfAny",
        accessor: "descrepencies_any",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.deviationsIfAny",
        accessor: "deviations_any",
      },
      {
        Header: "historicalDashboard.autoclaveHistoryColumns.status",
        accessor: "status",
      },
]

type LabtestStatType = {
  total_autoclaves: number;
  plates_completed: number;
  discrepencies: number;
  deviations: number;
};



const AutoclaveHistoryTable = () => {
  const { filters } = useFilter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [page, setpage] = useState(1)
  const [pageSize, setpagesize] = useState(5)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  //handle table pagination 
const handleSchedulePageChange = (newPage : any, pageSize?: number) => {  
    setpage(newPage);
    if(pageSize) {
      setpagesize(pageSize)
    }   
    setUseEffectRequired(true)
};

//api call to get the labtest stat details 
const getLabtestStatuDetails = async () => {
  try {
    const response = await historicalServices.getAutoclaveStatus(filters.startDate, filters.endDate, filters.building,filters.plant,filters.unit,filters.parentArea); 
      if(response.status && response.statusCode === 200){
        setLabTestStat({
          total_autoclaves : response.data.total_autoclave_areas,
          plates_completed: response.data.completed_plates,
          discrepencies : response.data.discrepancies,
          deviations : response.data.deviations,
          });
          dispatch(setLoading(false));
    }
  } catch (error) {
    console.error('getLabtestStatuDetails', error);
  }
};

//api call to list plate details under the labtest area 
const ListLabtestPlates = async () => {
    try {
      const response = await historicalServices.ListPlateForAutoclave(page, pageSize,sort,order,filters.startDate, filters.endDate, filters.building,filters.plant,filters.unit,filters.parentArea);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              settotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
                return { 
                  slno: index + 1, 
                  id: element.id,
                  plate_serial_number : element.plate_serial_number,
                  plate_batch_number : element.plate_batch_number,
                  location_name : element.location_name,
                  aut_started_date_time : element.start_time,
                  aut_started_by : element.started_user,
                  aut_ended_date_time : element.end_time,
                  aut_ended_by : element.ended_user,
                  descrepencies_any : element.descrepencies_any,
                  deviations_any : element.deviations_any,
                  status : element.status
              };
            });
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    } else {
      setPlateDetails([])
      console.log("no data")
    }
  } catch (error) {
    console.error('Plate response', error);
  }
};

// function to sort table
const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")
  } else {
    setOrder("desc")
  }
  setUseEffectRequired(true)
}


useEffect(() => {
  dispatch(setLoading(true));
  getLabtestStatuDetails();
  ListLabtestPlates();
  setUseEffectRequired(false)
}, [useEffectRequired])

  return (
   <div>
    <HeaderSection />
    <PageHeader pageHeader="Dashboard" />
    <div id='dashWrap'>
    <nav aria-label="breadcrumb" className="breadcrumb-container row">
      <div className='col-10 d-flex'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover"><Link to="/*">{t('dashboard.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('dashboard.autoclave')}</li>
        </ol>
      </div>
      <div className='col-2 d-flex'>
        <DownloadPdf divId="dashWrap" reportHead="Trend Report" />
      </div>
    </nav>

    {/*Map the lab test area stat values */}
    <div className="key-values-container">
      {labtestStat && Object.entries(labtestStat).map(([key, value]) => (
          <div  key={key} className="key-value">
            <div className="key">
              {key.replace(/_/g, ' ')}
            </div>
            <div className='value'>{value}</div>
          </div>
        ))}
      </div>


    <div className='row'>
          <div className='col-md-12 table-footer mx-3 pe-5' style={{"height":"45vh"}}>
                <CustomTable
                    tableHead='Autoclave Plate Details' 
                    data={plateDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}     
                    onUseEffectRequired={() => setUseEffectRequired(true)} 
                    onSortClick={handleSort}
                />
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
    </div>

 </div>

  )
}

export default AutoclaveHistoryTable;
