import React, { useState, useEffect, useRef } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import CustomTable from "../../../widgets/table/index";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import Modal from "react-bootstrap/Modal";
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { areaService } from "../../../service/areaServices";
import Pagination from "../../../widgets/pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setAreaId, setAreaName } from "../../../store/store";
import { userService } from "../../../service/plateServices";
import CustomToast from "../../../widgets/toaster";
import { columns } from "./partials/columns";
import moment from 'moment';

const ScanArea = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const textFieldRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  const [scanCode, setScanCode] = useState("");
  const [isAnyRowChecked, setIsAnyRowChecked] = useState(false);
  const [showScan, setShowScan] = useState(false);
  const [webcamResult, setWebCamResult] = useState<string | null>(null);
  const [stream, setStream] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [useEffectRequired, setUseEffectRequired] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const areaId = useSelector((state: RootState) => state.area.areaId);
  const areaName = useSelector((state: RootState) => state.area.areaName);
  const [capacityDetails , setCapacityDetails] = useState("")

  //pagination in Scan Area
  const [pageHistory, setPageHistory] = useState(1)
  const [pageSizeHistory, setPageSizeHistory] = useState(5)
  const [totalPagesHistory, setTotalPagesHistory] = useState(0)
  const [totalElementsHistory, setTotalElementsHistory] = useState(0)
  //Scan Area
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [plateStatusHistory, setPlateStatusHistory] = useState("")
  const [plateSerielNumHistory, setPlateSerielNumsHistory] = useState([])
  const [keyWordHistory, setkeyWordHistory] = useState("")
  const [currentLocationId , setCurrentLocationId] = useState(null);
  const [autoclaveStatus , setAutoclavestatus] = useState(14)
  //fields to store the values get from getScanbyArea Api
  const [areaByScan, setAreaByScan] = useState({
    area_name: "",
    available_incubators: "",
    incubator_capacity: "",
    area_id : "",
    incubator_policy_duration: "",
    activity_count : "",
    available_node_count: "",
    capacity : ""
  });
  const [scanAreaName, setScanAreaName] = useState("");
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")
  const [toastType, setToastType] = useState("")


  //handle webcam errors in scan qr section 
  const webCamError = (error: any) => {
    console.log(error);
  };

  //handle web cam result 
  const webCamScan = (result: any) => {
    if (result) {
      const scanData = JSON.parse(result);
      dispatch(setAreaId(scanData.id));
      dispatch(setAreaName(scanData.location_type));
      setWebCamResult(result);
      setShowScan(false);
      closeCamera();
      if(scanData.location_type){
        setToastMessage("Area scan successfull");
        setToastType("success");
      }else{
        setToastMessage("Area Scan Failed");
        setToastType("error");
      }
      
      setShowToast(true)
      getAreaByScan(scanData.id , scanData.location_type);
    }
  };

  //handle open camera modal 
  const handleModalOpen = () => {
    setWebCamResult("");
    setShowScan(true);
    openCamera();
  };

  //set the live stream when modal opens 
  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      const track = stream.getVideoTracks()[0];

      if (track) {
        const settings = track.getSettings();
        const width = settings.width || 0;
        const height = settings.height || 0;
        setVideoDimensions({ width, height });
        setStream(true);
      } else {
        console.error("Video track not found.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //on close click camera modal 
  const closeCamera = () => {
    // Access the MediaStream from the video element
    const videoElement = document.querySelector("video");
    const mediaStream = videoElement?.srcObject;

    if (mediaStream instanceof MediaStream) {
      // Get the tracks and stop them if the mediaStream exists
      const tracks = mediaStream.getTracks();

      tracks.forEach((track) => track.stop());

      // Close the camera
      setStream(false);
    } else {
      console.error("Video element or media stream not found.");
    }
  };

  //clear the entered scanned data 
  const clearSelectedData = () => {
    // setScanCode("");
    // setWebCamResult("");
    // setAreaId("");
    // setAreaName("");
    window.location.reload();
  };

  //change in the qr values trigger api for fetch area details 
  const handleScancodeChange = (result:any) => {
    setScanCode(result)
    try {
      const parsedResult = JSON.parse(result);
      if (typeof parsedResult === 'object') {
        webCamScan(result);
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }

  //api call for fetch the area details 
  const getAreaByScan = async (id : any , location_type : any ) => {
    try {
      const response = await areaService.getAreaByScan(id, location_type);
      if(response.status && response.statusCode === 200){
        try {   
              setAreaByScan(response.data);
              setCurrentLocationId(response.data.id);
              if (location_type == 8) {
                setScanAreaName(response.data.lab_area_name);     
                setCapacityDetails("");  
              } else if (location_type == 9) {      
                setScanAreaName(response.data.autoclave_area_name);  
                setCapacityDetails(`${response.data.busy_node_count} / ${response.data.capacity}`);     
              } else {
                setScanAreaName(response.data.incubator_name);
                setCapacityDetails(`${response.data.busy_node_count} / ${response.data.incubator_capacity}`)
              }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  };

  //display area details in the scan area nav 
  const data: Record<string, any> = {
    "Area Name": scanAreaName,
    // "Capacity": capacityDetails
  };

  if (capacityDetails && capacityDetails.length > 0) {
    data["Capacity"] = capacityDetails;
  }
 
  //list inprogress plate details for incubation area 
  const ListPlateDetails = async () => {
      try {
        const response = await userService.ListPlateDetails(pageHistory, pageSizeHistory, "id", "12", keyWordHistory, currentLocationId);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPagesHistory(response?.totalPages)
                setTotalElementsHistory(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                  const plateType = parseInt(element.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Finger DAB Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    } else{
                      var plate_type = "-"
                    };
                    
                    const statusNumber = parseInt(element.plate_status, 10); 
                    if(statusNumber == 9 || statusNumber == 1){
                      var status = "Pending"
                    }else if (statusNumber == 12){
                      var status = "In Progress"
                    }else if (statusNumber == 13){
                      var status = "Completed"
                    }else{
                      var status = "Unknown status"
                    }
                    interface Exposure {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                    exposure_area_name: string;
                  }
                  // Calculate total exposed time
                  const exposureDetails : Exposure[] = element.exposure_details || [];
                  let totalExposedTime = 0;
                  let startTime, endTime;

                  exposureDetails.forEach((exposure) => {
                    const startDate = new Date(exposure.start_date_time);
                    const endDate = new Date(exposure.end_date_time);
                    const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                    totalExposedTime += timeDifferenceMs;
  
                  });
                  const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                  const totalExposedTimeMinutes = Math.floor(
                    (totalExposedTime % 3600000) / 60000
                  );
                  const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);
                  const fullNames = exposureDetails.map((exposure : Exposure) => {
                    return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                  });
                  const exposed_user = fullNames.join(", ");
                  const exposureAreaName = element.exposure_details[0]?.exposure_area_name || '-';
                  
                  const formattedStartDateTime = exposureDetails.map((exposure) => moment(exposure.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                  const formattedEndDateTime = exposureDetails.map((exposure) => moment(exposure.end_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                  //caluculate the total incubated time 
                  interface Incubation {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    created_by_first_name: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                  }
                  const incubationDetails : Incubation[] = element.incubation_details || [];
                  
                  const incubationDurationMinutes = parseInt(areaByScan.incubator_policy_duration, 10);
                  const hours = Math.floor(incubationDurationMinutes / 60);
                  const minutes = incubationDurationMinutes % 60;
                  const seconds = 0;

                  const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                    return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                  });
                  const incubated_user = fullNamesIncubation.join(", ");
                  const incubationAreaName = element.incubation_details[0]?.incubation_area_name || '-';
  
                  return { 
                      slno: index + 1, 
                      batch_id: element.batch_number,
                      serial_number: element.serial_number,
                      plate_type: plate_type,
                      exposure_start_time : formattedStartDateTime,
                      exposure_end_time : formattedEndDateTime,
                      exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                      exposed_by : exposed_user,
                      exposure_area_name : exposureAreaName,
                      incubation_duration: `${hours} : ${minutes} : ${seconds}` ,
                      incubated_by : incubated_user,
                      incubation_area_name : incubationAreaName ,
                      status : status
                  };
                });
                setPlateDetailsHistory(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetailsHistory([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  };

  //list inprogress plates in autoclave area 
  const ListAutoclaveDetails = async () => {
      try {
        const response = await userService.ListAutoclaveDetails(pageHistory, pageSizeHistory, "id", "", autoclaveStatus, keyWordHistory, "", "");
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPagesHistory(response?.totalPages)
                setTotalElementsHistory(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                    const statusNumber = parseInt(element.plate_status, 10); 
                    let status: string;
                    switch (statusNumber) {
                    case 22:
                    case 17:
                    case 10:
                    case 7:
                    case 4:
                      status = "Pending";
                      break;
                    case 14:
                      status = "In Progress";
                      break;
                    case 15:
                      status = "Completed";
                      break;
                    default:
                      status = "Unknown status";
                  }
                    interface Exposure {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                    exposure_area_name: string;
                  }
                  // Calculate total exposed time
                  const exposureDetails : Exposure[] = element.exposure_details || [];
                  let totalExposedTime = 0;
  
                  exposureDetails.forEach((exposure) => {
                    const startDate = new Date(exposure.start_date_time);
                    const endDate = new Date(exposure.end_date_time);
                    const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                    totalExposedTime += timeDifferenceMs;
  
  
                  });
                  const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                  const totalExposedTimeMinutes = Math.floor(
                    (totalExposedTime % 3600000) / 60000
                  );
                  const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);

                  const fullNames = exposureDetails.map((exposure : Exposure) => {
                    return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                  });
                  const exposed_user = fullNames.join(", ");
                  const exposureAreaName = element.exposure_details[0]?.exposure_area_name || '-';
                  
  
                  //caluculate the total incubated time 
                  interface Incubation {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    created_by_first_name: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                  }
                  const incubationDetails : Incubation[] = element.incubation_details || [];
                  
                  const incubationDuration = incubationDetails.map((incubation) => {
                    const startDatetime = new Date(incubation.start_date_time);
                    const endDatetime = new Date(incubation.end_date_time);
                
                    const timeDifference = endDatetime.getTime() - startDatetime.getTime();
                    const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
                    const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds
                    const seconds = Math.floor((timeDifference % 60000) / 1000);
                    return `${hours} : ${minutes} : ${seconds}`
                  });             
                  
  
                  const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                    return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                  });
                  const incubated_user = fullNamesIncubation.join(", ");
                  const incubationAreaName = element.incubation_details[0]?.incubator_name || '-';
  
                  // const plateType = parseInt(areaByScan.activity_count, 10);
                  // if(plateType == 1){
                  //   var plate_type = "Q A"
                  // }else if (plateType == 2){
                  //   var plate_type = "Exposure"
                  // } else if (plateType == 3){
                  //     var plate_type = "Fingure Dab"
                  // }else{
                  //   var plate_type = "Unknown"
                  // }
  
                  return { 
                      slno: index + 1, 
                      batch_id: element.batch_number,
                      serial_number: element.serial_number,
                      // plate_type: plate_type,
                      exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                      exposed_by : exposed_user,
                      exposure_area_name : exposureAreaName,
                      incubation_duration: incubationDuration ,
                      incubated_by : incubated_user,
                      incubation_area_name : incubationAreaName ,
                      status : status
                  };
                  
                });
                setPlateDetailsHistory(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetailsHistory([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  };
  
  //handle pagination 
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPageHistory(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true);
  };

  //trigger the function call when the page loads
  useEffect(() => {
    getAreaByScan(areaId , areaName);
  }, [useEffectRequired]);

  useEffect(() => {
    if (areaName == "9") {
      ListAutoclaveDetails()
        .then((response) => { 
        })
        .catch((error) => {     
          console.error(error);
        });
    } else {
      ListPlateDetails()
        .then((response) => { 
        })
        .catch((error) => {     
          console.error(error);
        });
    }
  }, [currentLocationId]);

  return (
    <div>
      <HeaderSection />

      <PageHeader pageHeader={t("operator.scanArea")} />
      <CustomToast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          message={toastMessage}
          toastType={toastType}
      /> 

      <div className="scanWrapLg">
        <div className="scanInputWrapLg p-1">
          <input
            type="text"
            value={scanCode}
            // onChange={(e) => setScanCode(e.target.value)}
            onChange={(e) => handleScancodeChange(e.target.value)}

            placeholder="Scan Code"
            ref={textFieldRef}
          />
          {webcamResult ? (
            <p
              onClick={() => clearSelectedData()}
              className="scanAddText py-2 me-2 my-0 "
            >
              Clear
            </p>
          ) : (
            <div onClick={handleModalOpen} className="scanBg ">
              <BsQrCodeScan />
            </div>
          )}
        </div>
        {scanAreaName !== '' && (
          <div
            className="scannedDetailsWrap ps-3"
            style={{ marginLeft: "20%" }}
          >
            <table className="mx-2">
              <tbody>
                {Object.keys(data).map((key) => (
                  <tr key={key}>
                    <td className="keyText">{key}</td>
                    <td className="px-3">:</td>
                    <td className="valueText">{data[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <p className='keyText m-0'>Area Name : <span className='valueText'> Name</span></p>
                        <p className='keyText my-1'>Activity Status : <span className='valueText'> Status</span></p> */}
          </div>
        )}
      </div>

      {scanAreaName !== '' && (
        <>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              data={plateDetailsHistory}
              columns={columns}
              isEditable={false}
              totalElements={totalElementsHistory}
              isActionVisible={false}
              isViewVisible={false}
              tableHead=""
              showBatchFilter={false}
              showSerielNoFilter={false}
            />
          </div>
          <div>
            <Pagination
              page={pageHistory}
              totalPages={totalPagesHistory}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}

      <Modal show={showScan} onHide={() => setShowScan(false)}>
        <div className="scanModal">
          <div className="modalHead">
            <p className="modalHeadText2 mx-3 my-2">Scan</p>
            <div onClick={() => setShowScan(false)} className="p-2 mx-2 ">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          {/* <div className='bgQRWrapp'>
                        <div className='bgQRCode'></div>
                      </div> */}
          <QrReader
            className="scan-web"
            delay={300}
            onError={webCamError}
            onScan={webCamScan}
            facingMode={"user"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ScanArea;
