// import React, { createContext, useContext, useState, ReactNode } from 'react';

// interface FilterContextProps {
//   filters: any; // Replace 'any' with the actual type of your filters
//   setFilters: React.Dispatch<React.SetStateAction<any>>;
// }

// const FilterContext = createContext<FilterContextProps | undefined>(undefined);

// export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const [filters, setFilters] = useState<any>({});

//   return (
//     <FilterContext.Provider value={{ filters, setFilters }}>
//       {children}
//     </FilterContext.Provider>
//   );
// };

// export const useFilter = () => {
//   const context = useContext(FilterContext);
//   if (!context) {
//     throw new Error('useFilter must be used within a FilterProvider');
//   }
//   return context;
// };
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface FilterContextProps {
  filters: Filters; 
  setFilters: Dispatch<SetStateAction<Filters>>;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>({} as Filters);

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};

export type Filters = {
  plant: string;
  unit: string;
  building: string;
  parentArea: string;
  startDate: string;
  endDate: string;
  // Add more properties if needed
};