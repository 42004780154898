import React,{ useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { TfiClose } from 'react-icons/tfi'
import { useDropzone, FileRejection  } from 'react-dropzone';
import {FaTimes } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import { AiOutlineFileText } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import {CSVLink} from  "react-csv";
import {generateCSVData} from '../../../../assets/files/sample'
import { Accept } from 'react-dropzone';
import CustomToast from '../../../../widgets/toaster';
import { userService } from '../../../../service/plateServices';
import AlertModal from '../../../../widgets/alertModal';

interface FilterSecProps {
    showBulkUploadModel: boolean;
    addExceptionClose: () => void;
    uploadedFile: File | null;
    setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const BulkUploadModel: React.FC<FilterSecProps> = ({
    showBulkUploadModel,
    addExceptionClose,
    setUploadedFile,
    uploadedFile,
}) => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [showBulkUploadAlert, setBulkUploadAlert] = useState(false)
    const [error, setError] = useState("")

    const acceptFileType = ['.csv'] as unknown as Accept;
    const csvData = generateCSVData();
    const { t } = useTranslation();
    const dropzoneStyles: React.CSSProperties = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        textAlign: 'center',
        padding: '20px',
        margin: '45px 25px',
        cursor: 'pointer',
      };

    // const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    //     if (acceptedFiles && acceptedFiles.length > 0) {
    //       const file = acceptedFiles[0];
    //       setUploadedFile(file);
    //     }
    //     // Handle file rejections or errors if needed
    //     if (fileRejections && fileRejections.length > 0) {
    //       console.error('File rejected:', fileRejections[0].file);
    //     }
    // }, []);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
          if (acceptedFiles && acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              // Check if the file extension is .csv
              if (file.name.toLowerCase().endsWith('.csv')) {
                  setUploadedFile(file);
              } else {
                setShowToast(true)
                setToastType("error")
                setToastMessage('Invalid file type. Only CSV files are allowed.')
              }
          }
          // Handle file rejections or errors if needed
          if (fileRejections && fileRejections.length > 0) {
              console.error('File rejected:', fileRejections[0].file);
          }
      }, []);



    const removeFile = () => {
        setUploadedFile(null);
      };

      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptFileType 
    });

    const submitFileUpload = async () => {
      try {
        const response = await userService.plateBulkUpload(uploadedFile);
        if(response.status && response.statusCode === 200){
          setToastType("success")
          setToastMessage(response.message)
          setShowToast(true)
        } else {
          setToastType("error")
          setToastMessage(response.message)
          setShowToast(true)
        }
        setBulkUploadAlert(false)
        addExceptionClose()


      } catch (error) {
        console.error('Plate response', error);
      }
    }

     //handle exception alerts 
     const handleExceptionAlert = () => {
      if (!uploadedFile) {
        setError("*Please select a csv file");
        setTimeout(() => {
          setError("");
        }, 2000);
        return;
      } else {
        setBulkUploadAlert(true)
      }
    }



  return (
    <div>
    <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
    <Modal
              className="top-right-modal"
              centered={false}
              show={showBulkUploadModel}
              onHide={() => addExceptionClose()}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{t('plateInventory.fileUpload')}</p>
                <div onClick={() => addExceptionClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <div>
                        {uploadedFile ? (
                        <div>
                            <div>
                            <AiOutlineFileText size={50} fill='#FF7344'/>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p>{uploadedFile.name}</p>
                                <FaTimes onClick={removeFile} className='mt-1 ms-3' fill='red' />
                            </div>
                        </div>
                        ) : isDragActive ? (
                        <p>Drop the files here...</p>
                        ) : (
                        <div>
                            <div><MdFileUpload size={40} fill='#FF7344' /></div>
                            <br />
                            {t('plateInventory.drag_files')}
                        </div>
                        )}
                    </div>
                </div>
                <p className="errorText text-center" >{error}</p>

                            
                <div className="selectWrap justify-content-center ps-3">
                    <div className="form-group ms-2 ps-1">
                        <label htmlFor="">{t('plateInventory.click_csv')}</label>
                        <br />
                        <CSVLink data={csvData} filename={'sample.csv'}>Download CSV</CSVLink>
                    </div>
                </div>
      
              <div className="bottomArea">
            <button onClick={() => addExceptionClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handleExceptionAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
    </Modal>

    {/* Alert modal for Add bulk upload */}
    <AlertModal 
          show={showBulkUploadAlert}
          onHide={() => setBulkUploadAlert(false)}
          title='Alert'
          message={`Bulk upload plates`}
          onConfirm={submitFileUpload}
        />

    </div>
  )
}



const plateModels1 = () => {
    return (
      <div>plateModels1</div>
    )
}

  
const plateModels = {
    BulkUploadModel,
    plateModels1,
};
  
export { plateModels };