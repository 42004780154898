import React from 'react'



const CustomLoader = () => {
  return (
    <div className="loader-container">
        <div className="loader"></div>
        <p className="">please wait</p>
    </div>
  )
}

export default CustomLoader