import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

    const getPlateStatus = (status: number): string => {
        switch (status) {
          case 1:
            return "NEW";
          case 2:
            return "QA INPROGRESS";
          case 3:
            return "QA APPROVED";
          case 4:
            return "QA REJECTED";
          case 5:
            return "FINGER DAB INPROGRESS";
          case 6:
            return "FINGER DAB APPROVED";
          case 7:
            return "FINGER DAB REJECTED";
          case 8:
            return "EP INPROGRESS";
          case 9:
            return "EP APPROVED";
          case 10:
            return "EP REJECTED";
          case 12:
            return "INCUBATION INPROGRESS";
          case 13:
            return "INCUBATION COMPLETED";
          case 14:
            return "AUTOCLAVE INPROGRESS";
          case 15:
            return "AUTOCLAVE COMPLETED";
          case 16:
            return "EXCEPTION REQUESTED";
          case 17:
            return "DAMAGED";
          case 18:
            return "DISCARDED";
          case 19:
            return "EXCEPTION REJECTED";
          case 20:
            return "LAB INPROGRESS";
          case 21:
            return "LAB COMPLETED";
          case 22:
            return "LAB APPROVED";
          case 23:
            return "LAB REJECTED";
          case 24:
            return "PLATE SAMPLING";
          case 25:
            return "PLATE MATERIAL TEST INPROGRESS";
          case 26:
            return "PLATE MATERIAL TEST COMPLETED";
          case 27:
            return "PLATE MULTIPLE INCUBATION INPROGRESS";
          case 28:
            return "PLATE SURFACE MONITORING INPROGRESS";
          case 29:
            return "PLATE SURFACE MONITORING COMPLETED";
          case 30:
            return "ENVIRONMENTAL FLORA COMPLETED";
          default:
            return "UNKNOWN STATUS";
        }
    };

  //get all plate list
  const getPlateList = async (page :number, size :number, sort :string, order : string, status: number, keyWord: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&plate_status=${status}&keyword=${keyWord}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const listPlateBatch = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_batch?status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listSerialNumber = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_serial?status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getExceptionPlates = async (page :number, size :number, sort :string, order : string, status: string, keyWord: any, reasonId: any, x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_exception?page=${page}&size=${size}&sort=${sort}&order=${order}&exception_status=16&status=${status}&keyword=${keyWord}&reason_id=${reasonId}`, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getExceptionPlateHistory = async (page :number, size :number, sort :string, order : string, status: string, keyWord: any, reasonId: any, x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_exception?page=${page}&size=${size}&sort=${sort}&order=${sort}&exception_status=17,19&status=${status}&keyword=${keyWord}&reason_id=${reasonId}`,{ headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getPlateDetailsBySerielNo = async (serial_number : string, process : string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/empats/get_plate_slno?serial_number=${serial_number}&process=${process}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }
  
  const getExceptionReasons = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_exception_reason?status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getSamplingTestType = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_sampling_test_type?status=active&sort=test_type&order=asc`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getRawMaterialType = async () => {
    try {
      const headers = {
        'x-auth-token': authToken,
        'x-userid': authUserID,
      };
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_raw_material_type?status=active&sort=material_type&order=asc`,{headers});
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const addPlateException = async (dataToSend : any , x_auth_token: string, x_user_id: string) => {
    try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/plates/add_plate_exception`, 
        dataToSend, { headers }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const changeExceptionStatus = async (dataToSend : any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/plate_exception_status_change`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListPlateDetails = async (page :number, size :number, sort :string, status: string, keyWord: any, current_location_id : any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=asc&plate_status=${status}&keyword=${keyWord}&current_location_id=${current_location_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListPlateByStatus = async (page :number, size :number,sort :string, order : string, plate_status:string, locationId:any, keyword:any, location: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=${plate_status}&keyword=${keyword}&current_location_id=${locationId}&location=${location}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listPlateBySerielNo =async (serial_number : string, location_id : any, process: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=1&size=10&sort=id&order=asc&status=active&serial_number=${serial_number}&process=${process}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

 const listAutoClavePlateBySerielNo =async (serial_number : string, process : string ) => {
  const dataToSend = {
    "serial_number" : serial_number,
    "process" : process
  }
    try {
      const response = await axiosInstance.post(`${baseUrl}/scan/plate`,
      dataToSend,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const startPlateIncubation = async (dataToSend : any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/start_plate_incubation`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const endPlateIncubation = async (dataToSend : any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/stop_plate_incubation`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const startPlateLabTest = async (dataToSend : any , x_auth_token:string , x_user_id:string) => {
    try {
      const headers = {
        'x-auth-token': x_auth_token,
        'x-userid': x_user_id,
      };
      const response = await axiosInstance.post(`${baseUrl}/plates/start_lab_test`, dataToSend , { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getLabApprovedPlates =  async (page :number, size :number, keyword:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/lab/list_plates?page=${page}&size=${size}&sort=id&order=asc&batch_number=&serial_number=&keyword=${keyword}&completed_by=&approved_by=&lab_status=21&lab_completed_from_date&lab_completed_to_date&lab_approved_from_date&lab_approved_to_date`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getLabPlatesHistory =  async (page :number, size :number, keyword:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/lab/list_plates?page=${page}&size=${size}&sort=id&order=asc&batch_number=&serial_number=&keyword=${keyword}&completed_by=&approved_by=&lab_status=22,23&lab_completed_from_date&lab_completed_to_date&lab_approved_from_date&lab_approved_to_date`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const scanPlateById = async (dataToSend :any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/scan/plate`, dataToSend ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const changeLabReportStatus = async (dataToSend :any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/stop_lab_test`, dataToSend ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListAutoclaveDetails = async (page :number, size :number, sort :string, order : string, status: any, keyWord: any, serial_number:any, current_location_Id:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=${status}&keyword=${keyWord}&batch_number=&serial_number=${serial_number}&current_location_id=${current_location_Id}&location=` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListLabTestHistory = async (page :number, size :number, sort :string, order : string, keyword:any, location: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=21&keyword=${keyword}&current_location_id=&location=${location}` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListIncubationHistory =  async (page :number, size :number,sort :string, order : string, keyword:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=13&keyword=${keyword}&current_location_id=&location=` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const ListAutoclaveHistory =  async (page :number, size :number,sort :string, order : string, keyword:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=${sort}&order=${order}&status=active&plate_status=15&keyword=${keyword}&current_location_id=&location=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const createPlate = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/create_new_plates`, 
       dataToSend ,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    }catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
  }
  }

  const createTestTube = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/test_tubes/save`, 
       dataToSend ,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    }catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
  }
  }


  const listAllPlateTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_plate_types?status=active` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listAllTestTubeTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master_test_tubes/list_all_test_tube_types?sort=id&order=asc&page=1&size=10&status=active` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listAllPlateGrades =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_plate_grades?status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listAllFingerDabTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_finger_dab_option?status=active,inactive` ,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listFingerDabAssessmentTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_fingerdab_assesment_types?status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listNextPlateSerial =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/get_next_plate_serial`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listNextTestTubeSerial =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/test_tube/get_next_test_tube_serial`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const assignSamplingPlates = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/assign_sampling_plates`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const assignRawMaterialTest = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/plates/start_raw_material_test`, 
       dataToSend,
       {
         headers: {
           'x-auth-token': authToken,
           'x-userid': authUserID
         }
       }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const plateBulkUpload = async (dataToSend:any) => {
    try {
      const formData = new FormData();
      formData.append('file', dataToSend); // 'file' should match the name expected by the API
  
      const response = await axiosInstance.post(`${baseUrl}/files/import_plate_details`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', 
            'x-auth-token': authToken,
            'x-userid': authUserID
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const getRawMaterialSubCtegory = async (material_id : string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_all_raw_material_sub_type?sort=name&order=asc&status=active&material_type_id=${material_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  
  const userService = {
    getPlateList,
    getPlateStatus,
    listPlateBatch,
    listSerialNumber,
    getExceptionPlates,
    getExceptionPlateHistory,
    getPlateDetailsBySerielNo,
    getExceptionReasons,
    addPlateException,
    changeExceptionStatus,
    ListPlateDetails,
    ListPlateByStatus,
    listPlateBySerielNo,
    listAutoClavePlateBySerielNo,
    startPlateIncubation,
    endPlateIncubation,
    startPlateLabTest,
    getLabApprovedPlates,
    scanPlateById,
    getLabPlatesHistory,
    changeLabReportStatus,
    ListAutoclaveDetails,
    ListLabTestHistory,
    ListIncubationHistory,
    ListAutoclaveHistory,
    createPlate,
    createTestTube,
    listAllPlateTypes,
    listAllTestTubeTypes,
    listAllPlateGrades,
    listAllFingerDabTypes,
    listFingerDabAssessmentTypes,
    listNextPlateSerial,
    assignSamplingPlates,
    getSamplingTestType,
    plateBulkUpload,
    getRawMaterialType,
    assignRawMaterialTest,
    getRawMaterialSubCtegory,
    listNextTestTubeSerial
  };

  export { userService };
