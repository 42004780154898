import React, {useRef, useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { Column } from 'react-table';
import { FileService } from '../../../../service/fileServices';
import { Button, Modal } from 'react-bootstrap';
import { TfiClose } from 'react-icons/tfi';
import { FaRegClosedCaptioning } from 'react-icons/fa';
import AlertModal from '../../../../widgets/alertModal';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../../service';
import { BsQrCodeScan } from 'react-icons/bs';
import { AddExceptionModel } from '../../../common/addException/models';
import {userService as userRoleServive} from '../../../../service/userService';
import { userService as plateRelatedService } from '../../../../service/plateServices';

import AddException from '../../../common/addException';
import { BatchService } from '../../../../service/plateServices/discard';
import { columnsByBatch } from './columns';

interface PlatesProps {
    plateDetails: string[];
    columns: Column[];
    plateSerielNum: string[];
    plateBatches: string[];
    totalElements: number;
    page: number;
    totalPages: number;
    onUploadClick: () => void;  
    onAddClick: () => void;
    setUseEffectRequired: (value: boolean) => void;
    handleFilter: (key: string, value: any) => void;
    handleSort: (key: string, value: boolean) => void;
    handlePageChange: (newPage: any) => void;
}

const Plates: React.FC<PlatesProps> = ({
    plateDetails,
    columns,
    plateSerielNum,
    plateBatches,
    totalElements,
    page,
    totalPages,
    handleFilter,
    handleSort,
    handlePageChange,
    onUploadClick,
    onAddClick,
    setUseEffectRequired
}) => {

  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [showGenerateQrAlert, setShowGenerateQrAlert] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0)
  const [selectedSerielNumbers, setSelectedSerielNumbers] = useState<string[]>([]);
  // const [qrPrintModalShow, setQrPrintModalShow] = React.useState(false);
  const [qrColumns, setQrColumns] = useState(7);
  const [cellWidth, setCellWidth] = useState(1);
  const [imageId, setImageId] = useState("");
  const [showPlateSampleModal, setShowPlateSampleModal] = useState(false);
  interface Errors {
    remarks?: string;
    reason_id?: string;
    other_reason?: string;
    scan_plate?: string;

  }

  const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
  const [errors, setErrors] = useState<Errors>({})
  const [exceptionReasons, setExceptionReasons] = useState<Array<any>>([]);
  const [addExceptionData, setAddExceptionData] = useState({
    remarks: "",
    reason_id: "",
    other_reason: "",
    user_id : 0,
    area_id : "",
  });
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [closeIconText, setCloseIconText] = useState("");
  const [batchPlateList, setBatchPlateList] = useState([]);
  const [keyWord, setkeyWord] = useState("");

   // pagination
   const [BatchByPage, setBatchByPage] = useState(1)
   const [BatchByPageSize, setBatchByPageSize] = useState(5)
   const [BatchByTotalPages, setBatchByTotalPages] = useState(0)
   const [BatchByTotalElements, setBatchByTotalElements] = useState(0)
   const [sort, setSort] = useState("id");
   const [order, setOrder] = useState("desc");
   const [permission, setPermission] = useState<any>([]);





  //handle qr section 
  const handleQrDownload = async (slno: string[], type: string) => {
    const filteredUserDetails = batchPlateList.filter((item: any) => slno.includes(item.slno));
    const selectedIds = filteredUserDetails.map((item: any) => item.id).join(',');
    const selectedSerielNo = filteredUserDetails.map((item: any) => item.serial_number);
    setSelectedSlnoLen(slno.length)
    if (type === "single") {
      setShowGenerateQrAlert(true)
       setImageId(selectedIds)
    } else if (type === "multi") {
      setModalShow(true)
      console.log(selectedSerielNo, "selectedSerielNo")
      setSelectedSerielNumbers(selectedSerielNo)
    }


  }

  //generate Qr
  const handleGenerateQr = () => {
    setShowGenerateQrAlert(false)
    const imageUrl = `${baseUrl}/plates/download_plate_qr/?plate_id=${imageId}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'downloaded_image.jpg';
    link.click();
  }

  //create grid with size 
  const createGrid = () => {
    return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedSerielNumbers.map((cellIndex) => (
                <div key={cellIndex} style={{ display: "flex", border: '0.5px solid grey', margin: "15px 15px", "alignItems" : "center"}}>
                  <img src={`${baseUrl}/uploads/qr/plates/${cellIndex}.png`} alt={`Image ${cellIndex}`} style={{ width: `${cellWidth}cm`, height: `${cellWidth}cm`, pageBreakInside: 'avoid' }} />
                  <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px",}} >{cellIndex}</p>
                </div>
              ))}
            </div>
    )
  }

  //download QR
  const handleDownloadBtn = () => {
    const content : any = document.getElementById('innerDiv');
    const pri: any = window.open('', '', 'height=600,width=800');
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setModalShow(false)
    };
    pri.print();

  }

  const handlePlateSampleClick = () => {
    setShowPlateSampleModal(true)
  }

  //list plate batch detail 
  const listBatchPlateDetails = async (BatchByPage: any, BatchByPageSize: any, sort:any , order:any, plateStatus: any, batchId: string, keyWord: any) => {
    try {
      const response = await BatchService.listPlateDetails(BatchByPage, BatchByPageSize, sort, order, plateStatus, batchId, keyWord);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setBatchByTotalPages(response?.totalPages)
            setBatchByTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              const role = element.role_master_id === "1" ?
                 "Admin" : element.role_master_id === "2" ?
                 "Operator" : element.role_master_id === "3"? 
                 "Lab Operator": null
                 const statusNumber = parseInt(element.plate_status, 10); 
                 const status = plateRelatedService.getPlateStatus(statusNumber);
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_number,
                    serial_number: element.serial_number,
                    manufacturing_date: element.manufacturing_date,
                    expiry_date: element.expiry_date,
                    status: status,
                };
            });
            setBatchPlateList(rowData);
          }
      } else {
        setBatchPlateList([]);
      }
    } catch (error) {
      return error;
    }

  }

  //on cell click 
  const handleCellClick = (rowData: any) => {
    if(rowData.column.id === "quantity"){
      setShowButtonWithCloseIcon(true)
      setCloseIconText(rowData?.row?.original?.batch_number)
      listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, 0, rowData?.row?.original?.batch_number, keyWord)
    }
  }


  //on click on selected incubator list 
  const handleCloseBtnClick = () => {
    setShowButtonWithCloseIcon(false)
  }

  const handleBatchByPageChange = (newPage : any, pageSize?: number) => {
    setBatchByPage(newPage);
    if(pageSize) {
      setBatchByPageSize(pageSize)
      listBatchPlateDetails(newPage, pageSize,sort,order, 0, closeIconText, keyWord)
    }
    listBatchPlateDetails(newPage, BatchByPageSize,sort,order, 0, closeIconText, keyWord)
  }

  //handle PlateListFilter filters
  const handlePlateListFilter = (key: string, value: any) => {
    if(key === "search") {
      setkeyWord(value)
      listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, 0, closeIconText, value)
    }
}


  // function to sort table
  const handleSortClicked = (key: string, value: boolean) => {
    setSort(key)
    if (value) {
      setOrder("asc")  
      listBatchPlateDetails(BatchByPage, BatchByPageSize,key,"asc", 0, closeIconText, keyWord)    
    } else {
      setOrder("desc")
      listBatchPlateDetails(BatchByPage, BatchByPageSize,key,"desc", 0, closeIconText, keyWord)
    }
   
}

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userRoleServive.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handlePlateSampleClose = () => {
    setShowPlateSampleModal(false)
    setUseEffectRequired(true)
  }


  useEffect (() => {
    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
  }, [])



  return (
    <div className="me-3" style={{ marginTop: "10px" }}>
        {!showButtonWithCloseIcon ? (
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              tableHead="Plates"
              data={plateDetails}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              addButton={permission?.includes("add_plates")}
              addButtonText={t('plateInventory.fileUpload')}
              onAddClick={onAddClick}
              addPlateUploadBtn={permission?.includes("add_plates")}
              addPlateUploadText={t('plateInventory.bulk')}
              showBlueBtn={permission?.includes("plate_sampling_test")}
              blueBtnText='Plate Sampling'
              onBlueBtnClick={handlePlateSampleClick}
              onUploadClick={onUploadClick}
              plateSerialNumber={plateSerielNum}
              plateBatch={plateBatches}
              isSeachable={false}
              showPlateStatus={true}
              onFilterClick={handleFilter}
              // isQrDownloadable={true}
              // onQrDownloadClick={handleQrDownload}
              onSortClick={handleSort}
              onCellClick={handleCellClick}
            />
          </div>
        ):(
          <div className='mx-3 pe-3' style={{"height":"45vh"}}>
              <CustomTable
                tableHead='Plate Details' 
                data={batchPlateList} 
                columns={columnsByBatch} 
                isEditable={false} 
                isActionVisible={true} 
                isViewVisible={false} 
                totalElements={BatchByTotalElements}
                isSeachable={true}
                isToggleStatus={false}  
                plateSerialNumber={plateSerielNum}    
                // onViewClick={handleViewClick}
                onFilterClick={handlePlateListFilter}
                buttonWithCloseIcon={showButtonWithCloseIcon}
                buttonWithCloseIconText={closeIconText}
                onCloseBtnClick={() => handleCloseBtnClick()}
                isQrDownloadable={true}
                onQrDownloadClick={handleQrDownload}
                onSortClick={handleSortClicked}
            />
          </div>
        )}
          
          <div>
            {!showButtonWithCloseIcon ? (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            ) : (
              <Pagination
                page={BatchByPage}
                totalPages={BatchByTotalPages}
                handlePageChange={handleBatchByPageChange}
              />
            )}
          </div>

          <Modal
              // size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
              onHide={() => setModalShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('plateInventory.download')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Selected {selectedSlnoLen} plates to download QR</p>
                <div className="selectWrap">
                 
                  <div className="form-group my-1">
                    <label>{t('plateInventory.cell_width')}</label>
                    <br />
                    <input 
                       type="number"
                       id="cellWidth"
                       value={cellWidth}
                       min="0"
                       onChange={(e) => setCellWidth(parseInt(e.target.value))} 
                    />
      
                  </div>
                </div>
                <div id="innerDiv" hidden style={{ border: '1px solid black', padding: '5px', margin:"5px", overflow: "scroll",height: "80vh"}}>
                  {createGrid()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handleDownloadBtn()}>{t('buttons.submit')}</Button>
              </Modal.Footer>
          </Modal>


      <AddException showModel={showPlateSampleModal} isPlateSampling={true} setShowModel={() => handlePlateSampleClose()} />

          {/* Alert modal for reset password */}
        <AlertModal
          show={showGenerateQrAlert}
          onHide={() => setShowGenerateQrAlert(false)}
          title='Alert'
          message={`Download QR`}
          onConfirm={handleGenerateQr}
        /> 

    </div>
  )
}

export default Plates