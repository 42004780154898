import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import ExposureDetailsLayout from "./partials/ExposureDetails";
import IncubationDetailsLayout from "./partials/IncubationDetail";
import AutoclaveDetailsLayout from "./partials/AutoclaveDetails";
import LabtestDetailsLayout from "./partials/LabtestDetails";
import Calendar from "../../../widgets/calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ExceptionDetailsLayout from "./partials/EceptionsDetails";
import HistoricalDashboard from "../historicalDashboard";
import { FaFilter } from "react-icons/fa";
import { BiFilterAlt } from "react-icons/bi";
import { dashboardServices } from "../../../service/dashboard";
import PlateReconciliation from "./partials/PlateRecoilination";
import DownloadPdf from "../../../widgets/downloadPdf";
import AlertModal from "../../../widgets/alertModal";
import { userService } from "../../../service/userService";

type ArrowValue = {
  exposed_plates: any;
  incubated_plates: any;
  lab_plates: any;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [tab, setTab] = useState("1");
  const [showFilters, setShowFilters] = useState(false);
  const [arrowValues, setArrowValues] = useState<ArrowValue[]>([]);
  const [permission, setPermission] = useState<any>([]);


  const { t } = useTranslation();


  useEffect(() => {
    if (tab === "3") {
      // Analytics Dashboard
      navigate("/analytics-dashboard");
    }
    if (tab === "4") {
      // /Operator Schedule
      navigate("/operator-schedule");
    }
  }, [tab, navigate]);

  const getArrowCount = async () => {
    try {
      const response = await dashboardServices.getArrowCount();
      if (response.status && response.statusCode === 200) {
        try {
          if (response.data && typeof response.data === "object") {
            const {
              ready_for_incubation,
              ready_for_lab_test,
              ready_for_autoclave,
            } = response.data;

            const rowData: ArrowValue = {
              exposed_plates: ready_for_incubation,
              incubated_plates: ready_for_lab_test,
              lab_plates: ready_for_autoclave,
            };

            setArrowValues([rowData]);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setArrowValues([]);
      }
    } catch (error) {
      console.error("exposure response", error);
    }
  };

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    getArrowCount();
  }, []);


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("dashboard.title")} />
      <div className="row realTimeAdminTab">
        <div className="col-10 d-flex">
          <button
            onClick={() => setTab("1")}
            className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
          >
            {t("dashboard.realtime")}
          </button>
          {permission?.includes("historical_dashboard") && (
            <button
              onClick={() => setTab("2")}
              className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
            >
              {t("dashboard.historical")}
            </button>
          )}

          {permission?.includes("analytics_dashboard") && (
            <button
              onClick={() => setTab("3")}
              className={tab === "3" ? "colTabBtnSel" : "colTabBtn"}
            >
              {t("dashboard.analyticalDashboard")}
            </button>
          )}
          {/* <button
            onClick={() => setTab("4")}
            className={tab === "4" ? "colTabBtnSel" : "colTabBtn"}
          >
            {t("dashboard.OperatorSchedule")}
          </button> */}
        </div>
        <div className="col-2 d-flex">
          {tab === "2" && (
            <BiFilterAlt
              onClick={() => setShowFilters(!showFilters)}
              fontSize={30}
              fill="black"
              className="mx-3 mt-2"
              title="Click here to show filter"
            />
            //  <button onClick={() => setShowFilters(!showFilters)} className='squareOrangeBtn3 my-2'> </button>
          )}
            <DownloadPdf divId="dashWrap" reportHead="Dashboard Report" />
        </div>
      </div>


{tab === "1" && (
  <div id="dashWrap">
    <div className="row" >
      <div id="flow-chart">
       
        {arrowValues.map((value, index) => (
          <React.Fragment key={index}>
            <div className="flow-item" id={`exposure-${index}`}>
              <div className="flow-content">
                <ExposureDetailsLayout
                  selectedDate={selectedDate}
                  heading={t("common.exposure")}
                />
              </div>
            </div>
            <div className="custom-arrow">
                 <span className="arrow-number">{value.exposed_plates}</span>
                 <span className="arrow-symbol">&#8680;</span>
                 <div className="custom-arrow-text"> {t("dashboard.ready_for_incubation")}</div>
            </div>

            <div
              className="flow-item"
              id={`incubation-${index}`}
            >
              <div className="flow-content">
                <IncubationDetailsLayout
                  selectedDate={selectedDate}
                  heading={t("common.incubation")}
                />
              </div>
            </div>

            <div className="flow-item" id={`labtest-${index}`}>
              <div className="flow-content">
                <ExceptionDetailsLayout
                  selectedDate={selectedDate}
                  heading={t("common.exceptions")}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>

    <div className="row" >
    <div id="flow-chart" style={{ display: "flex", justifyContent: "center" }}>
      {arrowValues.map((value, index) => (
          <React.Fragment key={index}>
            <div className="custom-arrow" style={{marginTop:"10px", marginLeft:"15%"}}>
              <span className="arrow-number">{value.incubated_plates}</span>
              <span className="arrow-symbol">&#8681;</span>
              <div className="custom-arrow-text"> {t("dashboard.ready_for_lab_test")}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>

    
    <div className="row">
      <div id="flow-chart">
        {arrowValues.map((value, index) => (
          <React.Fragment key={index}>
            <div className="flow-item" id={`autoclave-${index}`}>
              <div className="flow-content">
                <AutoclaveDetailsLayout
                  selectedDate={selectedDate}
                  heading={t("common.auto_clave")}
                />
              </div>
            </div>
            <div className="custom-arrow">
                 <span className="arrow-number">{value.lab_plates}</span>
                 <span className="arrow-symbol">&#8678;</span>
                 <div className="custom-arrow-text">{t("dashboard.ready_for_autoclave")}</div>
               </div>
            <div className="flow-item" id={`discrepancy-${index}`}>
              <div className="flow-content">
                <LabtestDetailsLayout
                  selectedDate={selectedDate}
                  heading={t("common.lab_test")}
                />
              </div>
            </div>
            <div
              className="flow-item"
              id={`discrepancy-${index}`}
              style={{ position: "relative" }}
             
            >            
              <div className="flow-content" style={{visibility: "hidden"}}>
                <PlateReconciliation
                  selectedDate={selectedDate}
                  heading={t("common.plate_reconciliation")}
                />  
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  </div>
)}

      {tab === "2" && (
        // historical dashboard
        <HistoricalDashboard showFilters={showFilters} />
      )}

    </div>
  );
};

export default Dashboard;
