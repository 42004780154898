import moment from 'moment';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  endTime: string;
  status?: string;
}

const AutoclaveTimer: React.FC<CountdownTimerProps> = (props: CountdownTimerProps) => {
  const { endTime, status } = props;
  const [timeLeft, setTimeLeft] = useState(moment.duration(0));

  useEffect(() => {
    const updateTimer = () => {
      const now = moment();
      const end = moment(endTime, 'HH:mm:ss');
      const diff = moment.duration(end.diff(now));

      if (diff.asSeconds() >= 0) {
        setTimeLeft(diff);
      } else {
        // End time has passed
        setTimeLeft(moment.duration(0));
      }
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  const formatTime = (duration: moment.Duration) => {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  return (
    <div>
      {!timeLeft.asSeconds() ? (
        <>
          {status === 'In Progress' && <div className='overdue'>Over Due</div>}
          {status === 'Pending' && <div className='overdue'>Not Started</div>}
          {status === 'Completed' && <div className='overdue'>00:00:00</div>}
        </>
      ) : (
        <div className='counter-timer'>{formatTime(timeLeft)}</div>
      )}
    </div>
  );
};

export default AutoclaveTimer;
