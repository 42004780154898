import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {TfiClose} from 'react-icons/tfi'




interface ResetPasswordProps {
    show: boolean;
    onHide: () => void;
    title: string;
    message: string;
    onConfirm: () => void;
  }


const ResetPassword: React.FC<ResetPasswordProps> = ({
    show,
    onHide,
    title,
    message,
    onConfirm,
}) => {
  return (
    <Modal centered lassName='customModal-alert-expiry' show={show} onHide={onHide}>
        <div>
        <div className='modalHead'>
            <p className='modalHeadText2 mx-3 my-2'>{title}</p>
            <div onClick={onHide} className='p-2 mx-2'>
            <TfiClose fill="#FFFFFF" />
            </div>
        </div>
        <div className='text-center mt-3 mb-5'>
            <p className='normalText2 '><br/> 
                <span><strong>{message}</strong></span>
            </p>
        </div>
        <div className='d-flex justify-content-around pb-3'>
            <button onClick={onHide} className='modalCancelBtn2'>
            Cancel
            </button>
            <button onClick={onConfirm} className='squareOrangeBtn3'>
            Reset
            </button>
        </div>
        </div>
    </Modal>
  )
}

export default ResetPassword