import React, {useState, useRef, useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineLeft } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { IoIosNotifications, IoIosNotificationsOutline } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toggleNotifi } from '../../../store/store';
import { authenticationService } from '../../../service/authentication';
import moment from 'moment';


interface notificationProps {
  notificationCount: number

}


const Notification :React.FC<notificationProps> = ({notificationCount}) => {

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [tab, setTab] = useState("1")
    const dispatch = useDispatch();
    const [notificationList, setNotificationList] = useState([])
    const [selectedNotification, setSelectedNotification] = useState({
      caption: "",
      updatedAt: "",
      content: "",
      id: "",
    })
    const [userId, setUserId] = useState<string>("");


    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');
  
      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setUserId(storedObject[0].id)
      }
  
    }, [])



    const listNotification = async (completeStatus:string, read_status:string) => {
      try {
        const response = await authenticationService.listNotification(completeStatus, read_status, userId);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  const updatedAt = moment(element.updated_at);
                  const convertedDate = updatedAt.format('DD-MM-YYYY - HH:mm:ss');

                  return { 
                      slno: index + 1, 
                      id: element.id,
                      caption: element.caption,
                      content: element.content,
                      updatedAt: convertedDate,
                  };
                });
                setNotificationList(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        } else {
          setNotificationList([]);
        }
      } catch (error) {
        console.error('user response', error);
      }
    }

    const updateNotification = async (completedStatus: string, notificationId: string) => {
      const dataToSend = {
        id : notificationId,
        read_status:"1",
        complete_status: completedStatus,
        user_id: userId
      }

      try {
        const response = await authenticationService.updateNotification(dataToSend);
        if(response.status && response.statusCode === 200){
          listNotification("pending", "0")
        } else {
          setNotificationList([]);
        }
      } catch (error) {
        console.error('user response', error);
      }


    }

    const handleNotificationClick = () => {
        setShow(true)
        listNotification("pending", "0");
        dispatch(toggleNotifi())
    }
  
    const handleNotificationClose = () => {
      setShow(false)
      dispatch(toggleNotifi())
      setTab("1")
    }

    const notificationDetails = (selectedItem: any) => {
      setShow2(true)
      setSelectedNotification(selectedItem)
    }

    const handleRead = () => {
      listNotification("completed", "1")
      setTab("2")
    }

    const handleNew = () => {
      listNotification("pending", "0")
      setTab("1")
    }

    const handleDenied= () => {
      listNotification("denied", "1")
      setTab("3")
    }

    const closeSelectedNotifi = () => {
      setShow2(false)
      setSelectedNotification({
        caption: "",
        updatedAt: "",
        content: "",
        id: "",
      })
    }

    interface notificationProps {
      content : any
    }

    const NotificationContent : React.FC<notificationProps> = ({ content }) => {
      if (!content) return null;
    
      // Split the content by <br> tags
      const contentItems = content.split('<br>');
    
      return (
        <div className='m-2'>
          <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
            {contentItems.map((item : any, index: number) => (
              <li key={index} className='normalText2'>{item}</li>
            ))}
          </ul>
        </div>
      );
    };




  return (
    <div>

      <div className='bottomLine mb-5'>
        <a type='button' onClick={() => handleNotificationClick()}> <IoIosNotificationsOutline className='sidebarIconBell mx-2' /><span className='notifiCount'>{notificationCount}</span></a>
      </div>

      <Modal show={show} onHide={() => handleNotificationClose()} centered={false} className="custom-modal">
        <div className='notificationModal'>
          <div className='notifyModalText p-2'>
              <p className='boldText1 m-2'>Notifications</p>
              <div onClick={() => handleNotificationClose()} className='py-1 px-2'><GrClose /></div>
          </div>
          <div className='colTab mt-2'>
              <button onClick={() => handleNew()} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>New</button>
              <button onClick={() => handleRead()} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>Read</button>
              <button onClick={() => handleDenied()} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>Denied</button>
          </div>

            <div className='notification'>
              {tab === "1" && notificationList?.map((obj: any, index) => (
              <div key={index} onClick={() => notificationDetails(obj)} 
                className={`notifiCard p-2 ${obj?.id === selectedNotification?.id ? 'notifiCardSel' : ''}`}>
                <div className='notifiUserBg m-1'></div>
                <div className='w-100'>
                  <p className='normalText1 d-flex m-0'>{obj?.caption} </p>
                  <p className='lightText1 d-flex mb-1'>{obj?.updatedAt}</p>
                  <div className='d-flex'>
                    <button onClick={() => updateNotification("completed", obj?.id)} className='squareOrangeBtn1 py-0 me-2'>Make a read</button>
                    <button className='squareLightBtn1 py-0 mx-2' onClick={() => updateNotification("denied", obj?.id)}>Deny</button>
                  </div>
                </div>
              </div>
              ))}

              { tab === "2" && notificationList?.map((obj: any, index) => (
                <div key={index} onClick={() => notificationDetails(obj)} 
                  className={`notifiCard p-2 ${obj?.id === selectedNotification?.id ? 'notifiCardSel' : ''}`}>
                  <div className='notifiUserBg m-1'></div>
                  <div className='w-100'>
                    <p className='normalText1 d-flex m-0'>{obj?.caption} </p>
                    <p className='lightText1 d-flex mb-1'>{obj?.updatedAt}</p>
                    <div className='d-flex'>
                      <button onClick={() => updateNotification("completed", obj?.id)} className='squareOrangeBtn1 py-0 me-2'>Make a read</button>
                      <button className='squareLightBtn1 py-0 mx-2'>Deny</button>
                    </div>
                  </div>
                </div>
                ))}

              { tab === "3" && notificationList?.map((obj: any, index) => (
                <div key={index} onClick={() => notificationDetails(obj)} 
                  className={`notifiCard p-2 ${obj?.id === selectedNotification?.id ? 'notifiCardSel' : ''}`}>
                  <div className='notifiUserBg m-1'></div>
                  <div className='w-100'>
                    <p className='normalText1 d-flex m-0'>{obj?.caption} </p>
                    <p className='lightText1 d-flex mb-1'>{obj?.updatedAt}</p>
                    <div className='d-flex'>
                      <button onClick={() => updateNotification("completed", obj?.id)} className='squareOrangeBtn1 py-0 me-2'>Make a read</button>
                      <button className='squareLightBtn1 py-0 mx-2'>Deny</button>
                    </div>
                  </div>
                </div>
                ))}
            </div>


          </div>
      </Modal>

      <Modal  show={show2} onHide={() => closeSelectedNotifi()} centered={false} className="custom-modal2">
          <div>
              <div className='notifiCard py-3'>
                  <div onClick={() => closeSelectedNotifi()} className='pt-2 px-2'><AiOutlineLeft fill='#FD7243' /></div>
                  <div className='d-flex'>
                    <div className='notifiUserBg m-1'></div>
                    <div className='m-1'>
                      <p className='normalText1 m-0'>{selectedNotification?.caption}</p>
                      <p className='lightText1'>{selectedNotification?.updatedAt}</p>
                    </div>
                  </div>
              </div>
              <div className='m-2' >
                <NotificationContent content={selectedNotification?.content} />
                {/* <p className='normalText2' style={{textAlign: "left"}}>
                    {selectedNotification?.content}
                </p> */}
              </div>
              {/* <div>
                 <p className='boldText2 m-2'>files</p>
                 <div className='d-flex'>
                  <div className='filesBg m-2'></div>
                  <div className='filesBg m-2'></div>
                 </div>
              </div> */}
              <div className='bottomRight'>
                <div className='d-flex justify-content-end p-3'>
                  {/* <button className='squareOrangeBtn1 me-2'>Make a read</button> */}
                  <button className='squareLightBtn1 mx-2' onClick={() => closeSelectedNotifi()}>Close</button>
                </div>
              </div>
          </div>
      </Modal>
    </div>
  )
}


export default Notification