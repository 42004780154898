import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { userService } from "../../../service/plateServices";
import moment from "moment";
import CustomToast from "../../../widgets/toaster";
import { columns, columnsHistory } from './partials/columns';
import Requests from "./partials/requests";
import History from "./partials/history";
import AddException from "../../common/addException";


const ExceptionHandling = () => {
  const [tab, setTab] = useState("1");
  const [show, setShow] = useState(false);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [userId, setUserId] = useState<number>(0);
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("success")
  const [toastType, setToastType] = useState("success")
  const [authToken , setAuthToken] = useState("")
  const [authUserID , setAuthUserID] = useState("")

  //pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  //Exception listing
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateStatus, setPlateStatus] = useState("16")
  const [plateSerielNum, setPlateSerielNums] = useState([])
  const [keyWord, setkeyWord] = useState("")

  //pagination in Exception History Area
  const [pageHistory, setPageHistory] = useState(1)
  const [pageSizeHistory, setPageSizeHistory] = useState(5)
  const [totalPagesHistory, setTotalPagesHistory] = useState(0)
  const [totalElementsHistory, setTotalElementsHistory] = useState(0)
  //Exception History
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [plateStatusHistory, setPlateStatusHistory] = useState("")
  const [keyWordHistory, setkeyWordHistory] = useState("")
  const [reasonId, setReasonId] = useState("");
  const [historyReasonId, setHistoryReasonId] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  const { t } = useTranslation();

  //get user details
  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
    }

    getExceptionPlates();
    getExceptionPlateHistory();
    listSerialNumber();
    setUseEffectRequired(false)
  }, [useEffectRequired])

  
//api call to list exception plate detail in request tab 
  const getExceptionPlates = async () => {
      try {
        const response = await userService.getExceptionPlates(page, pageSize,sort, order, plateStatus, keyWord, reasonId ,authToken ,authUserID);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
  
                   const statusNumber = parseInt(element.status, 10); 
                   const status = userService.getPlateStatus(statusNumber);
                   const datetimeParts = element.exception_requested_datetime
                   const datePart = moment(datetimeParts).format('YYYY-MM-DD');
                   const requestUser = `${element.exception_requested_user_firstname} ${element.exception_requested_user_lastname}`;
                  return { 
                      slno: "EX_" + element.id, 
                      batch_number: element.batch_number,
                      serial_number: element.serial_number,
                      reason: element.reason,
                      date : datePart,
                      location_name : element.location_name,
                      exception_requested_user_firstname : requestUser,
                      exception_requested_by : element.requested_user_login_id,
                      status: status,
                      id : element.id
                  };
                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

    //api call to list exception plate detail in history tab 
  const getExceptionPlateHistory = async () => {
        try {
          const response = await userService.getExceptionPlateHistory(pageHistory, pageSizeHistory, sort, order, plateStatusHistory, keyWordHistory, historyReasonId, authToken ,authUserID);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPagesHistory(response?.totalPages)
                  setTotalElementsHistory(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {
    
                     const statusNumber = parseInt(element.status, 10); 
                     if(statusNumber == 17){
                        var status = "Approved"
                     }else if (statusNumber == 19){
                        var status = "Rejected"
                     }else{
                        var status = "Unknown status"
                     }

                     const datetimeParts = element.approved_datetime;
                     let approvedDate = null;
                 
                     if (datetimeParts !== null) {
                         const approvedDatePart = moment(datetimeParts).format('DD-MM-YYYY'); 
                         approvedDate = approvedDatePart;
                     }
                     const requestUser = `${element.exception_requested_user_firstname} ${element.exception_requested_user_lastname}`;
                    
                    let approvedUser = null;
                    if (element.approver_firstname !== null && element.approver_lastname !== null) {
                        approvedUser = `${element.approver_firstname} ${element.approver_lastname}`;
                    }
                
                    return { 
                        slno: "EX_" + element.id, 
                        batch_number: element.batch_number,
                        serial_number: element.serial_number,
                        reason: element.reason,
                        location_name : element.location_name,
                        operator_name : requestUser,
                        exception_requested_by : element.requested_user_login_id,
                        approved_by : approvedUser,
                        approved_on : approvedDate,
                        status: status,
                    };
                  });
                  setPlateDetailsHistory(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setPlateDetailsHistory([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    };

//api call to list the serial number for filter dropdown 
  const listSerialNumber = async () => {
        try {
          const response = await userService.listSerialNumber();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
      
                    return { 
                        value: element.serial_number,
                        label: element.serial_number ,
                    };
                  });
                  setPlateSerielNums(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    }

// handle on approve button click 
  const handleApproveClick = async (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    
    const dataToSend = {
      "exception_ids" : selectedIds,
      "user_id" : userId,
      "status" : "17",
      "interface_name" : "Approve Exception"
    }

    try {
      const response = await userService.changeExceptionStatus(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      getExceptionPlateHistory();
    } catch (error) {
      console.error(error);
    }
  }

  // handle on reject button click in request tab 
  const handleRejectClick = async (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');
    
    const dataToSend = {
      "exception_ids" : selectedIds,
      "user_id" : userId,
      "status" : "19",
      "interface_name" : "Reject Exception"
    }

    try {
      const response = await userService.changeExceptionStatus(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //handle table pagination in request view table 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle table pagination in history view table 
  const handleHistoryPageChange = (newPage : any, pageSize?: number) => {
    setPageHistory(newPage);
    if(pageSize) {
      setPageSizeHistory(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle filter in request table section 
  const handleFilter = (key: string, value: any) => {
    if (key === "ExceptionReason"){
      setReasonId(value)
    }
    if (key === "search") {
      setkeyWord(value)
    }
    setUseEffectRequired(true)
  }

  // handle filter in history view table 
  const handleHistoryFilter = (key: string, value: any) => {
    if (key === "ExceptionReason"){
      setHistoryReasonId(value)
    }
    if (key === "search") {
      setkeyWordHistory(value)
    }
    setUseEffectRequired(true)
  }

  // function to sort table
  const handleSortHistory = (key: string, value: boolean) => {
    setSort(key)
    if (value) {
      setOrder("asc")
    } else {
      setOrder("desc")
    }
    setUseEffectRequired(true)
  }

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")
  } else {
    setOrder("desc")
  }
  setUseEffectRequired(true)
}


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Exception Handling" />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />  
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t('exceptionHandling.request')}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
              {t('exceptionHandling.history')}
        </button>
      </div>

      {tab === "1" && (
        <Requests
          plateDetails={plateDetails}
          columns={columns}
          plateSerielNum={plateSerielNum}
          totalElements={totalElements}
          page={page}
          totalPages={totalPages}
          handleApproveClick={handleApproveClick}
          handleRejectClick={handleRejectClick}
          handleAddClick={() => setShow(true)}
          handlePageChange={handlePageChange}
          handleFilter={handleFilter}
          handleSort={handleSort}
        />
      )}

      {tab === "2" && (
        <History 
          plateDetailsHistory={plateDetailsHistory}
          columnsHistory={columnsHistory}
          totalElementsHistory={totalElementsHistory}
          pageHistory={pageHistory}
          totalPagesHistory={totalPagesHistory}
          handleHistoryPageChange={handleHistoryPageChange}
          handleFilter={handleHistoryFilter}
          handleSort={handleSortHistory}
        />
      )}

      {/* add exception modal */}
      <AddException showModel={show} setShowModel={() => setShow(false)} setRefreshPage={() => setUseEffectRequired(true)} />


    </div>
  );
};

export default ExceptionHandling;
