import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Column } from "react-table";
import { BsQrCodeScan } from "react-icons/bs";
import { TfiClose } from "react-icons/tfi";
import { CiCircleRemove } from "react-icons/ci";
import QrReader from "react-qr-reader";
import { useTranslation } from "react-i18next";

interface AddExceptionModelProps {
  show: boolean;
  isPlateSampling?: boolean;
  isRawMaterialTest?: boolean;
  exceptionReasons: { id: string; reason: string }[];
  samleTestType: { id: string; test_type: string }[];
  rawMaterialType: { id: string; material_type: string }[];
  rawMaterialSub: { id: string; material_type: string }[];
  plateBySerielNo: {
    plateSerielNo: string;
    plateType: string;
    batchId: string;
  }[];
  addExceptionData: {
    remarks: string;
    reason_id: string;
    other_reason: string;
    user_id: number;
    area_id: string;
    subCategory_id :string;
  };
  errors: {
    scan_plate?: string;
    reason_id?: string;
    other_reason?: string;
    remarks?: string;
    subCategory_id?:string;
  };

  handleExceptionAlert: () => void;
  handleAddExceptionChange: (e: any) => void;
  addExceptionClose: () => void;
  handleModalOpen: () => void;
  getPlateDetailsBySerielNo: (slNo: string) => void;
  setPlateBySerielNo: (e: any) => void;
}

export const AddExceptionModel: React.FC<AddExceptionModelProps> = ({
  show,
  isPlateSampling,
  isRawMaterialTest,
  plateBySerielNo,
  errors,
  addExceptionData,
  exceptionReasons,
  samleTestType,
  rawMaterialType,
  rawMaterialSub,

  addExceptionClose,
  getPlateDetailsBySerielNo,
  handleModalOpen,
  handleAddExceptionChange,
  handleExceptionAlert,
  setPlateBySerielNo
}) => {
  const { t } = useTranslation();
  const [newResult, setResult] = useState("");
  //handle result to get plate details by serial number api
  // const handleScanSerielNo = (result: any) => {
  //   console.log("Original Result:", result);
  //   try {
  //     const parsedResult = JSON.parse(result);
  //     if (typeof parsedResult === "object") {
  //       if (parsedResult.hasOwnProperty("serial_number")) {
  //         getPlateDetailsBySerielNo(parsedResult.serial_number);
  //       }
  //     }
  //   } catch (error) {
  //     getPlateDetailsBySerielNo(result);
  //     console.error("Error parsing JSON:", error);
  //   }
  // };

  const handleScanSerielNo = (result: any) => {
    console.log('Original Result:', result);
    if (result.trim() !== "") {
      setResult(result);
      try {
        const parsedResult = JSON.parse(result);
        console.log('Parsed Result:', parsedResult);
  
        if (typeof parsedResult === 'object') {
          let serialNumber;
  
          if (parsedResult.hasOwnProperty('serial_number')) {
            serialNumber = parsedResult.serial_number;
          } else {
           
            console.log('No serial number property found in the parsed result.');
          }
          if (serialNumber) {
            getPlateDetailsBySerielNo(serialNumber);
          }
        } else {
          console.log('Parsed result is not an object.');    
        }
      } catch (error) {
        // getPlateDetailsBySerielNo(result);
        console.error('Error parsing JSON:', error);
      }
    }
  };

  const handleRemovePlate = (id: any) => {
    setPlateBySerielNo((prevState: any) => prevState.filter((plate: any )=> plate.plateSerielId !== id));
  }


  return (
    <Modal
      className="top-right-modal"
      centered={false}
      show={show}
      onHide={() => addExceptionClose()}
      size="lg"
    >
      <div>
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">
            {isPlateSampling
              ? "Plates Sampling"
              : isRawMaterialTest
              ? "Sample Type"
              : t("Exception.add_exception")}
          </p>
          <div onClick={() => addExceptionClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div>
          <div className="scanWrap">
            <div className="scanInputWrap p-2">
              <input
                onChange={(e) => handleScanSerielNo(e.target.value)}
                placeholder="Scan Code"
              />
              <div onClick={handleModalOpen} className="scanBg ">
                <BsQrCodeScan />
              </div>
            </div>
            {errors.scan_plate && (
              <p className="errorText p-0 m-0">{errors.scan_plate}</p>
            )}
          </div>

          {plateBySerielNo.length >= 1 && (
            <div className="px-3 mx-2" style={{"overflow" : "scroll", "height": "180px"}}>
              <table className="table">
                <thead >
                  <tr>
                    <th scope="col">Sl.No ({plateBySerielNo.length})</th>
                    <th scope="col">Plate Seriel No</th>
                    <th scope="col">Plate Type</th>
                    <th scope="col">Batch Id</th>
                    <th scope="col">Remove</th>
                  </tr>
                </thead>
                <tbody >
                  {plateBySerielNo.map((obj : any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{obj.plateSerielNo}</td>
                    <td>{obj.plateType}</td>
                    <td>{obj.batchId}</td>
                    <td><CiCircleRemove 
                        onClick={() => handleRemovePlate(obj.plateSerielId)} 
                        fontSize={25} 
                        color="red"
                        style={{"cursor": "pointer"}}
                      /></td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
           )}

          {/* <div className="selectWrap">
            <div className="form-group my-1">
              <label htmlFor="">{t("Exception.serial_id")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0
                    ? plateBySerielNo[0].plateSerielNo
                    : ""
                }
              />
            </div>

            <div className="form-group my-1">
              <label>{t("Exception.plate_type")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0 ? plateBySerielNo[0].plateType : ""
                }
              />
            </div>
          </div> */}
          {/* <div className="selectWrap">
            <div className="form-group my-1">
              <label htmlFor="">{t("Exception.batch")}</label>
              <br />
              <input
                disabled
                value={
                  plateBySerielNo.length > 0 ? plateBySerielNo[0].batchId : ""
                }
              />
            </div>

            <div className="form-group my-1">
              {!isPlateSampling && !isRawMaterialTest && (
                <>
                  <label htmlFor="">
                    {t("Exception.reason")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {exceptionReasons.map((reason, index) => (
                      <option key={index} value={reason?.id}>
                        {reason?.reason}
                      </option>
                    ))}
                    <option value="0">others</option>
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}

              {isPlateSampling && (
                <>
                  <label htmlFor="">
                    Plate Sampling Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {samleTestType.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.test_type}
                      </option>
                    ))}
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}

              {isRawMaterialTest && (
                <>
                  <label htmlFor="">
                    Sample Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.reason_id}
                    name="reason_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {rawMaterialType.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.material_type}
                      </option>
                    ))}
                  </select>
                  {errors.reason_id && (
                    <p className="errorText">{errors.reason_id}</p>
                  )}
                </>
              )}
            </div>
          </div> */}
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-1">
                {!isPlateSampling && !isRawMaterialTest && (
                  <>
                    <label htmlFor="">
                      {t("Exception.reason")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {exceptionReasons.map((reason, index) => (
                        <option key={index} value={reason?.id}>
                          {reason?.reason}
                        </option>
                      ))}
                      <option value="0">others</option>
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}

                {isPlateSampling && (
                  <>
                    <label htmlFor="">
                      Plate Sampling Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {samleTestType.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.test_type}
                        </option>
                      ))}
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}

                {isRawMaterialTest && (
                  <>
                    <label htmlFor="">
                      Sample Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id=""
                      value={addExceptionData.reason_id}
                      name="reason_id"
                      onChange={handleAddExceptionChange}
                    >
                      <option value="select">select</option>
                      {rawMaterialType.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.material_type}
                        </option>
                      ))}
                    </select>
                    {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )}
                  </>
                )}
              </div>
            <div className="form-group my-1 mx-3">
              {isRawMaterialTest && (
                <>
                  <label htmlFor="">
                   Sub Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id=""
                    value={addExceptionData.subCategory_id}
                    name="subCategory_id"
                    onChange={handleAddExceptionChange}
                  >
                    <option value="select">select</option>
                    {rawMaterialSub.map((type, index) => (
                      <option key={index} value={type?.id}>
                        {type?.material_type}
                      </option>
                    ))}
                  </select>
                  {errors.subCategory_id && (
                    <p className="errorText">{errors.subCategory_id}</p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start">
              {addExceptionData.reason_id === "0" && (
                <div className="selectWrap justify-content-start ps-3">
                  <div className="form-group ms-2 ps-1 ">
                    <label htmlFor="">
                      {t("Exception.other")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <textarea
                      placeholder="Other"
                      className="otherReasonTextArea p-2"
                      value={addExceptionData.other_reason}
                      name="other_reason"
                      onChange={handleAddExceptionChange}
                      // Add any necessary props for the text area
                    />
                    {errors.other_reason && (
                      <p className="errorText m-0 p-0">{errors.other_reason}</p>
                    )}
                  </div>
                </div>
              )}

              {!isPlateSampling && !isRawMaterialTest && (
                <div className="selectWrap justify-content-start ms-1 ps-3">
                  <div className="form-group ms-2 ps-1 ">
                    <label htmlFor="">
                      {t("Exception.remarks")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <textarea
                      placeholder="Remarks"
                      className="otherReasonTextArea p-2"
                      value={addExceptionData.remarks}
                      name="remarks"
                      onChange={handleAddExceptionChange}
                      // Add any necessary props for the text area
                    />
                    {errors.remarks && (
                      <p className="errorText m-0 p-0">{errors.remarks}</p>
                    )}
                  </div>
                </div>
              )}
          </div>


          {/* {addExceptionData.reason_id === "0" && (
            <div className="selectWrap justify-content-start ps-3">
              <div className="form-group ms-2 ps-1 ">
                <label htmlFor="">
                  {t("Exception.other")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <textarea
                  placeholder="Other"
                  className="otherReasonTextArea p-2"
                  value={addExceptionData.other_reason}
                  name="other_reason"
                  onChange={handleAddExceptionChange}
                  // Add any necessary props for the text area
                />
                {errors.other_reason && (
                  <p className="errorText m-0 p-0">{errors.other_reason}</p>
                )}
              </div>
            </div>
          )} */}
        </div>
        {/* {!isPlateSampling && !isRawMaterialTest && (
          <div className="selectWrap justify-content-start ps-3">
            <div className="form-group ms-2 ps-1 ">
              <label htmlFor="">
                {t("Exception.remarks")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <textarea
                placeholder="Remarks"
                className="otherReasonTextArea p-2"
                value={addExceptionData.remarks}
                name="remarks"
                onChange={handleAddExceptionChange}
                // Add any necessary props for the text area
              />
              {errors.remarks && (
                <p className="errorText m-0 p-0">{errors.remarks}</p>
              )}
            </div>
          </div>
        )} */}
        <div className="bottomArea">
          <button
            onClick={() => addExceptionClose()}
            className="modalCancelBtn"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleExceptionAlert()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
          {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
        </div>
      </div>
    </Modal>
  );
};

interface ScanAreaModelProps {
  show: boolean;
  onHide: () => void;
  webCamError: (error: any) => void;
  webCamScan: (result: string | null) => void;
}

export const ScanAreaModel: React.FC<ScanAreaModelProps> = ({
  show,
  onHide,
  webCamError,
  webCamScan,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <div className="scanModal">
        <div className="modalHead">
          <p className="modalHeadText2 mx-3 my-2">Scan</p>
          <div onClick={onHide} className="p-2 mx-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <QrReader
          className="scan-web"
          delay={300}
          onError={webCamError}
          onScan={webCamScan}
          facingMode={"user"}
        />
      </div>
    </Modal>
  );
};
