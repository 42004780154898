
//table columns for lab test 
export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: 'Plate Type',
        accessor: 'plate_type',
      },
      {
        Header: 'Exposed Time',
        accessor: 'exposedTime',
      },
      {
        Header: 'Exposed By',
        accessor: 'exposedBy',
      },
      {
        Header: 'Exposure Area name',
        accessor: 'exposureAreaName',
      },
      {
        Header: 'Incubation Duration',
        accessor: 'incubationDuration',
      },
      {
        Header: 'Incubated By',
        accessor: 'incubatedBy',
      },
      {
        Header: 'Incubation Area name',
        accessor: 'incubationAreaName',
      },
      {
        Header: 'Lab Test Done by',
        accessor: 'labtestUser',
      },
      {
        Header: 'Lab Test Done Date',
        accessor: 'labTestCompletionDateTime',
      },
      {
        Header: 'Plate Status',
        accessor: 'PlateStatus',
      }, 
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  