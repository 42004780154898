import React, {useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { Column } from 'react-table';
import { Modal } from 'react-bootstrap';
import { TfiClose } from 'react-icons/tfi';
import { BatchService } from '../../../../service/plateServices/discard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../service/userService';


interface DiscardsProps {
    columns: Column[];
    plateSerielNum: string[];
    plateBatches: string[];
    setToastType: (type:string) => void;
    setToastMessage: (message: string) => void;
    setShowToast: (show: boolean) => void;

}

const Discards: React.FC<DiscardsProps> = ({
    columns,
    plateSerielNum,
    plateBatches,
    setToastType,
    setToastMessage,
    setShowToast,
}) => {

  const { t } = useTranslation();
  const [show, setShow] = useState(false)
  const [showBatch, setShowBatch] = useState(false)
  const [batchList, setBatchList] = useState([])
  const [pendingBatchList, setPendingBatchList] = useState([])
  const [batchPlateList, setBatchPlateList] = useState([])
  const [selectedBatch, setSelectedBatch] = useState({
    batch_id: '', 
    manufacturingDate: '',
  })
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [batchIds, setBatchIds] = useState<any>([])
  const [userId, setUserId] = useState<number>(0);

  // pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)

  // pagination
  const [discardPage, setDiscardPage] = useState(1)
  const [discardPageSize, setDiscardPageSize] = useState(5)
  const [discardTotalPages, setDiscardTotalPages] = useState(0)
  const [discardTotalElements, setDiscardTotalElements] = useState(0)
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);




//get user details
  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      getPermissionByRole(storedObject[0].role_master_id);
    }

    getBatchList(18, keyword)
    setUseEffectRequired(false)
  }, [useEffectRequired])


  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return { 
        //           // slno: index + 1, 
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  }
  
//handle discard click 
  const handleDiscardClick = () => {
    setShow(true)
    setTotalElements(0)
    getPendingBatchList(1, keyword, discardPage, discardPageSize)
  }

  //on close button click 
  const handleClose = () => {
    setShow(false)
    getBatchList(18, "")
  }

  //api call to get batch list 
  const getBatchList = async (plateStatus: any, keyword:string) => {
    try {
      const response = await BatchService.getBatchList(page, pageSize, keyword, plateStatus);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
            let statusText = plateStatus === 18 ? "Discard" : plateStatus === 1 ? "Exposure" : "Unknown";

             return { 
                 slno: index + 1, 
                 batch_id: element.batch_no,
                 manufacturingDate: moment(element.created_at).format('DD-MM-YYYY - HH:mm:ss'),
                 quantity: element.plate_count,
                 status: statusText,
                 id: element.id
             };
           });

           setBatchList(rowData);
          }
      } else {
        setBatchList([])
      }
    } catch (error) {
      return error;
    }
  }

  //api call to get pending batch list for inner modal 
  const getPendingBatchList = async (plateStatus: any, keyword:string, newPage:number, pageSize:number ) => {
    try {
      const response = await BatchService.getBatchList(newPage, pageSize, keyword, plateStatus);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setDiscardTotalPages(response?.totalPages)
            setDiscardTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
            let statusText = plateStatus === 18 ? "Discard" : plateStatus === 1 ? "Exposure" : "Unknown";

             return { 
                 slno: index + 1, 
                 batch_id: element.batch_no,
                 manufacturingDate: moment(element.created_at).format('DD-MM-YYYY - HH:mm:ss'),
                 quantity: element.plate_count,
                 status: statusText,
                 id: element.id
             };
           });

           setPendingBatchList(rowData);
          }
      } else {
        setPendingBatchList([])
      }
    } catch (error) {
      return error;
    }
  }

  //list plate batch detail 
  const listBatchPlateDetails = async (plateStatus: any, batchId: string) => {
    try {
      const response = await BatchService.listPlateDetails(discardPage, discardPageSize,sort,order, plateStatus, batchId, "");
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {
             return { 
                 slno: index + 1, 
                 serialNumber: element.serial_number,
             };
           });
           setBatchPlateList(rowData);
          }
      }
    } catch (error) {
      return error;
    }

  }

  //on cell click 
  const handleCellClick = (rowData: any) => {
    if(rowData.column.id === "quantity"){
      setSelectedBatch(rowData.row.original)
      setShowBatch(true)
      if(rowData?.row?.original?.status === "Discard") {
        listBatchPlateDetails(18, rowData?.row?.original?.batch_id)
      }
      else if(rowData?.row?.original?.status === "Exposure") {
        listBatchPlateDetails(1, rowData?.row?.original?.batch_id)
      }
    }
  }

  //handle filter 
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setKeyword(value)
    }
    setUseEffectRequired(true)
  }

  //handle filter batch 
  const handleFilterBatch = (key: string, value: any) => {
    if (key === "search") {
      setKeyword(value)
    }
    getPendingBatchList(1, value, discardPage, discardPageSize)
  }

  //on main table filter change 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //handle pagination 
  const handlePendingPageChange = (newPage : any, pageSize?: number) => {
    setDiscardPage(newPage);
    if(pageSize) {
      setDiscardPageSize(pageSize)
      getPendingBatchList(1, keyword, newPage, pageSize)
    } else {
      getPendingBatchList(1, keyword, newPage, discardPageSize)
    }
  };

  //get batch id from selected rows 
  const handleSubmitDiscard = (selectedRows: string[]) => {
    const filteredPlateDetails = pendingBatchList.filter((item : any) => selectedRows.includes(item.slno));
    const selectedbatchIds = filteredPlateDetails.map((item: any) => item.batch_id).join(',');
    if(selectedRows.length > 0 ) {
      setBatchIds(selectedbatchIds)
    } else {
      setBatchIds([])
    }
  }

  //on submit discard click 
  const submitDiscard =async () => {
    const dataToSend = {
        user_id : userId,
        batch_numbers : batchIds,
        interface_name : "Discard Plates"
    }
    try {
      const response = await BatchService.discardPlates(dataToSend);
      if(response.status && response.statusCode === 200){
        handleClose()
        setToastType("success")
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      handleClose()
    } catch (error) {
      console.error('user response', error);
    }

  }



  return (
    <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              tableHead={t('plateInventory.discarded')}
              data={batchList}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              discardButton={permission?.includes("discard_plate")}
              discardButtonText={t('plateInventory.discard')}
              plateSerialNumber={plateSerielNum}
              plateBatch={plateBatches}
              isSeachable={true}
              onFilterClick={handleFilter}
              onDiscardClick={handleDiscardClick}
              onCellClick={handleCellClick}
            />
          </div>
          
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <Modal
              className="top-right-modal"
              centered={false}
              show={show}
              size='lg'
              onHide={() => handleClose()}
          >
            <div className="modalHead">
              <p className="modalHeadText ms-3 pt-3 p-2">{t('plateInventory.discardBatch')}</p>
              <div onClick={() => setShow(false)} className="p-2 mx-3 my-2 ">
                <TfiClose fill="#FFFFFF" />
              </div>
          </div>
          <div>
            <div className=" mx-3 pe-2 " style={{"height":"45vh"}}>
                <CustomTable
                  tableHead={t('plateInventory.batches')}
                  data={pendingBatchList}
                  columns={columns}
                  isEditable={false}
                  totalElements={discardTotalElements}
                  isActionVisible={true}
                  isViewVisible={false}
                  plateSerialNumber={plateSerielNum}
                  plateBatch={plateBatches}
                  isSeachable={true}
                  onFilterClick={handleFilterBatch}
                  onCellClick={handleCellClick}
                  onActionClick={handleSubmitDiscard}
                />
            </div>
            <div>
              <Pagination
                page={discardPage}
                totalPages={discardTotalPages}
                handlePageChange={handlePendingPageChange}
              />
            </div>
          </div>
           

            <div className='colonyCountBottomSec2 pe-3 py-2'>
                    <button onClick={() => handleClose()} className='modalCancelBtn mx-3'>{t('buttons.cancel')}</button>
                    {batchIds.length > 0 ? (
                        <button onClick={() => submitDiscard()}  className='squareOrangeBtn3'>{t('buttons.submit')}</button>
                    ) : (
                      <button className='squareOrangeBtn3 squareOrangeBtn3Disabled'>{t('buttons.submit')}</button>
                    )}
            </div>
          </Modal>

          <Modal
              className="top-right-modal"
              centered={false}
              show={showBatch}
              onHide={() => setShowBatch(false)}
          >
            <div className="modalHead">
              <p className="modalHeadText ms-3 pt-3 p-2">{t('plateInventory.batch_view')}</p>
              <div onClick={() => setShowBatch(false)} className="p-2 mx-3 my-2 ">
                <TfiClose fill="#FFFFFF" />
              </div>
            </div>
            <div style={{"backgroundColor":"#F7F7F7"}}>
              <div className="row px-3 py-2" >
                  <div className="col-md-4 text-start">
                    <p className='normalText2 my-1'>{t('plateInventory.batch_code')}</p>
                    <p className='boldText2 '>{selectedBatch?.batch_id}</p>
                  </div>
                  <div className="col-md-4 text-start">
                    <p className='normalText2 my-1'>{t('plateInventory.manufactureDate')}</p>
                    <p className='boldText2'>{selectedBatch?.manufacturingDate}</p>
                  </div>
                  <div className="col-md-4 text-start">
                    <p className='normalText2 my-1'>{t('plateInventory.expiryDate')}</p>
                    <p className='boldText2'>--</p>
                  </div>
              </div>
            </div>
            <div className='mx-3 my-2 boldText2'>
              <p className='text-start '>plates | {batchPlateList.length}</p>
            </div>
            <div className="scrollable-list mx-2">
              <ul className="list-group text-start">
                {batchPlateList?.map((obj :any, index: number) => (
                    <li key={index} className='list-group-item'>{obj?.serialNumber}</li>
                ))}
              </ul>
            </div>
          </Modal>

        </div>
  )
}

export default Discards;