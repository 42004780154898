export const columns : any = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'policyManagement.tableColumns.policy_name',
      accessor: 'activity_name',
    },
    {
      Header: 'policyManagement.tableColumns.duration',
      accessor: 'duration',
    },
    {
      Header: 'tableCommon.area_type',
      accessor: 'policy_area_type',
    },
    // {
    //   Header: 'policyManagement.tableColumns.calibration',
    //   accessor: 'caliberation',
    // },
    // {
    //   Header: 'policyManagement.tableColumns.colony_count',
    //   accessor: 'colony_count',
    // },
]
export const columnsSchedule : any = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'scheduleManagement.tableColumns.policy_schedule_name',
      accessor: 'schedule_name',
    },
    {
      Header: 'tableCommon.area_type',
      accessor: 'location_type',
    },
    {
      Header: 'scheduleManagement.tableColumns.area_name',
      accessor: 'location_name',
    },
    {
      Header: 'scheduleManagement.tableColumns.duration',
      accessor: 'duration',
    },
    {
       Header: 'scheduleManagement.tableColumns.from_time',
       accessor: 'from_time',
    },
    {
      Header: 'scheduleManagement.tableColumns.to_time',
      accessor: 'to_time',
   },
   {
    Header: 'scheduleManagement.tableColumns.repeat_days',
    accessor: 'repeat_days',
   },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }