
//table columns for area management table section 
export const columns: TableColumn[] = [
    {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
      },
      {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
      },
      {
        Header: "Activity Type",
        accessor: "plate_type",
      },
      {
        Header: "Duration",
        accessor: "incubation_duration",
      },
      // {
      //   Header: "Incubated By",
      //   accessor: "incubated_by",
      // },
      {
        Header: "Plate Status",
        accessor: "status",
      },
      // {
      //   Header: "Plate Type",
      //   accessor: "plate_type",
      // },
      // {
      //   Header: "Exposure Start Time",
      //   accessor: "exposure_start_time",
      // },
      //  {
      //   Header: "Exposure End Time",
      //   accessor: "exposure_end_time",
      // },
      // {
      //   Header: "Exposed Time",
      //   accessor: "exposed_time",
      // },
      // {
      //   Header: "Exposed By",
      //   accessor: "exposed_by",
      // },
      // {
      //   Header: "Exposure Area Name",
      //   accessor: "exposure_area_name",
      // },
    
      // {
      //   Header: "Incubation Area Name",
      //   accessor: "incubation_area_name",
      // },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  