import { useEffect, useState } from "react";
import { userService } from "../service/userService";
import { Modal } from "react-bootstrap";

const Footer = () => {
  const [currentVersionDetails, setCurrentVersionDetails] = useState({
    id: null,
    versionName: null,
    changes: null,
    releaseDate: null,
    updated_at: null
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getCurrentVersion = async () => {
      try {
        const response = await userService.getCurrentVersion();
        if (response.status && response.statusCode === 200) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach((element: any) => {
              if (element.current_version === "t") {
                // Store the details or do whatever you need with the current version
                const currentVersionDetails = {
                  id: element.id,
                  versionName: element.version_name,
                  changes: element.changes,
                  releaseDate: element.release_date,
                  updated_at: element.updated_at
                };
                // Set the state with the current version details
                setCurrentVersionDetails(currentVersionDetails);
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCurrentVersion();
  }, []);

  const handleVersionClick = () => {
    setIsModalOpen(true);
  };


  return (
    <footer className="footerTab py-1 mt-5">
      <div>
        <p className="footer-text">
          &copy; {new Date().getFullYear()} MPATS. All rights reserved |
          <a
            onClick={handleVersionClick}
            className="footer-link"
            data-bs-target="#exampleModal"
          >
            {" "}
            {currentVersionDetails.versionName}
          </a>
        </p>
      </div>

      <Modal
        show={isModalOpen}
        className="modal-view-audit"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={() => setIsModalOpen(false)}>
          <Modal.Title id="contained-modal-title-vcenter">Release Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "left" }}>Version Report</h4>
          {/* {rowData && ( */}
            <div
              className="key-value-pairs"
              style={{
                display: "flex",
                padding: "10px",
                width: "100%",
                textAlign: "left",
              }}
            >
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">Version Name</div>
                <div className="audit-value">{currentVersionDetails?.versionName}</div>
              </div>
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">Release Date</div>
                <div className="audit-value">{currentVersionDetails?.releaseDate}</div>
              </div>
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">Updated At</div>
                <div className="audit-value">{currentVersionDetails?.updated_at}</div>
              </div>
            </div>
          {/* )} */}
          <table className="table" style={{ width: "100%", textAlign: "left" }}>
            <thead>
              <tr>
                <th style={{ width: "33%" }}>Changes</th>
              </tr>
            </thead>
            <tbody>
                <tr className="audit-view-table">
                  <td style={{ width: "33%" }}>{currentVersionDetails?.changes}</td>
                </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <button
            onClick={() => setIsModalOpen(false)}
            className="custom-close-button"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </footer>
  );
};

export default Footer;
