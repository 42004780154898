import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import translationData from '../../../../locales/dashboardTranslation.json'
import { useDispatch } from 'react-redux'
import DownloadPdf from "../../../../widgets/downloadPdf";



const InnerPageExposure = () => {
    const { exposureId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const displayName = getDisplayName(Number(exposureId));
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");

  //display name based on the inner id passed 
    function getDisplayName(id: any) {
        const exposureDataHead = translationData.ExposureDataHead;
        const exposure = exposureDataHead.find(item => item.id === id);
        return exposure ? exposure.displayName : "";
    }

    //column header for exposure view
    const columns : any  = [
    {
      Header: "Sl.No",
      accessor: "slno",
    },
    {
      Header: "dashboard.platesInProcessColumns.location",
      accessor: "location_name",
    },
    {
      Header: "dashboard.platesInProcessColumns.plateSerialNumber",
      accessor: "plate_serial_number",
    },
    {
      Header: "dashboard.platesInProcessColumns.batchNumber",
      accessor: "batch_number",
    },
    {
      Header: "dashboard.platesInProcessColumns.exposedDateOrTime",
      accessor: "exposed_date",
    },
    {
      Header: "dashboard.platesInProcessColumns.exposedBy",
      accessor: "exposed_by",
    },
    {
      Header: "dashboard.platesInProcessColumns.collectedDateOrTime",
      accessor: "collected_date",
    },
    {
        Header: "dashboard.platesInProcessColumns.collectedBy",
        accessor: "collected_by",
    },
    {
        Header: "dashboard.platesInProcessColumns.discrepenciesIfAny",
        accessor: "descrepencies_any",
    },
    {
        Header: "dashboard.platesInProcessColumns.deviationsIfAny",
        accessor: "deviations_any",
    },
    {
        Header: "dashboard.platesInProcessColumns.status",
        accessor: "status",
    },
  ]
  
  //handle pagination 
    const handleSchedulePageChange = (newPage :any, newPageSize?: number) => {
      setPage(newPage);
      if (newPageSize) {
        setPageSize(newPageSize);  
      }
      setUseEffectRequired(true)

    };

    //api call for inner page table listing 
    const fetchData = async () => {
      try {
        let response;
        switch (exposureId) {
          case "2":
            response = await dashboardServices.getPlateInExposureDetails(page, pageSize, sort, order);
            break;
          case "3":
            response = await dashboardServices.getExposedInprogress(page, pageSize, sort, order);
            break;
          case "4":
            response = await dashboardServices.getReadyForCollectionDetails(page, pageSize, sort, order);
            break;
          case "5":
            response = await dashboardServices.getOverduePlatesDetails(page, pageSize, sort, order);
            break;
          default:
            break;
        }
  
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages);
              setTotalElements(response?.totalElements);
              let rowData = response.data.map((element:any, index: any) => ({
                slno: index + 1,
                location_name: element.location_name,
                plate_serial_number: element.plate_serial_number,
                batch_number: element.plate_batch_number,
                exposed_date: element.start_date_time,
                exposed_by: element.exposed_user_name,
                collected_date: element.end_date_time,
                collected_by: element.collected_user_name,
                descrepencies_any: element.descrepencies_any,
                deviations_any: element.deviations_any,
                status: element.status,
              }));
              setScheduleDetails(rowData);
              // dispatch(setLoading(false));
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setScheduleDetails([]);
        }
      } catch (error) {
        console.error('API call error', error);
      }
    };


    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

  
    useEffect(() => {
        fetchData();
        setUseEffectRequired(false)
      }, [exposureId, useEffectRequired]);

      useEffect(() =>{
        // dispatch(setLoading(true));
        fetchData();
      },[])
      return (
        <div>
          <HeaderSection />
          <PageHeader pageHeader={t('dashboard.title')} />
          <div id="dashWrap">
          <nav aria-label="breadcrumb" className="breadcrumb-container row">
            <div className='col-10 d-flex'>
              <ol className="breadcrumb">
                <li className="breadcrumb-item dashboard-hover">
                  <Link to="/*">{t('dashboard.title')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {displayName}
                </li>
              </ol>
            </div>
            <div className='col-2 d-flex'>
              <DownloadPdf divId="dashWrap" reportHead="Exposure Report" />
            </div>
          </nav>
    
            <div className='col-md-12 mx-3 pe-5' style={{"height":"45vh"}}>
                    <CustomTable
                        tableHead={displayName} 
                        data={scheduleDetails} 
                        columns={columns} 
                        isEditable={false} 
                        isActionVisible={false} 
                        isViewVisible={false} 
                        totalElements={totalElements}
                        isSeachable={false}
                        isToggleStatus={false} 
                        onUseEffectRequired={() => setUseEffectRequired(true)}  
                        onSortClick={handleSort}    
                    />
            </div>
            <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handleSchedulePageChange}
                  />
            </div>
          </div>
        </div>
      );
  };
  
  export default InnerPageExposure;
  