import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import translationData from '../../../../locales/dashboardTranslation.json'
import { userService } from "../../../../service/plateServices";
import DownloadPdf from "../../../../widgets/downloadPdf";



const InnerPageDescrepency = () => {
    const { descrepencyId } = useParams();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const displayName = getDisplayName(Number(descrepencyId));
    const [authToken , setAuthToken] = useState("");
    const [authUserID , setAuthUserID] = useState("");
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");

    function getDisplayName(id: any) {
        const DiscrepancyHead = translationData.DiscrepancyHead;
        const descrepency = DiscrepancyHead.find(item => item.id === id);
        return descrepency ? descrepency.displayName : "";
    }

    //column header for discrepency view
    const columns : any  = [
    {
      Header: "Sl.No",
      accessor: "slno",
    },
    {
      Header: "dashboard.pendingExceptionColumns.plateSerialNumber",
      accessor: "serial_number",
    },
    {
      Header: "dashboard.pendingExceptionColumns.batchNumber",
      accessor: "batch_number",
    },
    {
      Header: "dashboard.pendingExceptionColumns.exceptionReasons",
      accessor: "remarks",
    },
    {
      Header: "dashboard.pendingExceptionColumns.exceptionAddedBy",
      accessor: "exception_requested_user_firstname",
    },
    {
      Header: "dashboard.pendingExceptionColumns.exceptionAddedDate",
      accessor: "exception_requested_datetime",
    },
    {
        Header: "dashboard.pendingExceptionColumns.exceptionAddedArea",
        accessor: "location_name",
    },
    {
        Header: "dashboard.pendingExceptionColumns.status",
        accessor: "status",
    },
  ]
  
    //handle pagination 
    const handleSchedulePageChange = (newPage :any, newPageSize?: number) => {
      setPage(newPage);
      if (newPageSize) {
        setPageSize(newPageSize);  
      }
      setUseEffectRequired(true)
    };

    //api call for inner page table listing 
    const fetchData = async (x_auth_token: string, x_user_id: string) => {
        try {
          let response;
          switch (descrepencyId) {
            case "1":
              response = await dashboardServices.getDescrepencyDetails(page, pageSize , 16, x_auth_token, x_user_id , sort, order);
              break;
            case "2":
              response = await dashboardServices.getDescrepencyDetails(page, pageSize, 17, x_auth_token, x_user_id , sort, order);
              break;
            default:
              break;
          }
      
          if (response.status && response.statusCode === 200) {
            try {
              if (Array.isArray(response.data) && response.data.length > 0) {
                setTotalPages(response.totalPages);
                setTotalElements(response.totalElements);
                let rowData = response.data.map((element: any, index: any) => {
                  const createdFirstName = element.exception_requested_user_firstname || '-';
                  const createdLastName = element.exception_requested_user_lastname || '-';
                  const fullName = createdFirstName + ' ' + createdLastName;
      
                  const statusNumber = parseInt(element.status, 10); 
                  const plateStatus = userService.getPlateStatus(statusNumber);
                  return {
                    slno: index + 1, 
                    serial_number : element.serial_number,
                    batch_number: element.batch_number,
                    remarks: element.remarks,
                    exception_requested_user_firstname: fullName,
                    exception_requested_datetime: element.exception_requested_datetime,
                    location_name: element.location_name,
                    status: plateStatus,
                  };
                });
                setScheduleDetails(rowData);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            setScheduleDetails([]);
          }
        } catch (error) {
          console.error('API call error', error);
        }
      };


  
    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');
      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setAuthToken(storedObject[0].x_auth_token)
        setAuthUserID(storedObject[0].x_userid)

        fetchData(storedObject[0]?.x_auth_token ,storedObject[0]?.x_userid);
        setUseEffectRequired(false)
      }
      }, [descrepencyId, useEffectRequired]);


      // function to sort table
      const handleSort = (key: string, value: boolean) => {
        setSort(key)
        if (value) {
          setOrder("asc")
        } else {
          setOrder("desc")
        }
        setUseEffectRequired(true)
      }


      return (
        <div>
          <HeaderSection />
          <PageHeader pageHeader={t('dashboard.title')} />
          <div id='dashWrap'>
          <nav aria-label="breadcrumb" className="breadcrumb-container row">
            <div className='col-10 d-flex'>
              <ol className="breadcrumb">
                <li className="breadcrumb-item dashboard-hover">
                  <Link to="/*">{t('dashboard.title')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {displayName}
                </li>
              </ol>
            </div>
            <div className='col-2 d-flex'>
              <DownloadPdf divId="dashWrap" reportHead="Descrepency Report" />
            </div>
          </nav>
    
          <div className='col-md-12 mx-3 pe-5' style={{"height":"45vh"}}>
                    <CustomTable
                        tableHead={displayName} 
                        data={scheduleDetails} 
                        columns={columns} 
                        isEditable={false} 
                        isActionVisible={false} 
                        isViewVisible={false} 
                        totalElements={totalElements}
                        isSeachable={false}
                        isToggleStatus={false}    
                        onUseEffectRequired={() => setUseEffectRequired(true)} 
                        onSortClick={handleSort}
                    />
            </div>
            <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handleSchedulePageChange}
                  />
            </div>
          </div>
        </div>
      );
  };
  
  export default InnerPageDescrepency;
  