import React, {useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import { Modal} from 'react-bootstrap'
import { Column } from 'react-table';
import { TfiClose } from 'react-icons/tfi';
import AddScheduleModal from '../modals/addScheduleModal';
import AddPolicyModal from '../modals/addPolicyModal';
import { userService } from '../../../../service/userService';
import AlertModal from '../../../../widgets/alertModal';
import { areaService } from '../../../../service/areaServices';
import AddLocationPolicyModal from '../modals/addLocationPolicyModal';
import Pagination from '../../../../widgets/pagination';
import { useTranslation } from 'react-i18next';


interface Incubator {
  id: string;
  name: string;
}

interface FilterSecProps {
    columns: Column[];
    showAddUserAlert: boolean;
    modalName: string;
    showAddLocationModal: boolean;
    policySubmitted: boolean;
    statusChanged: boolean;
    selectedPolicyId: string;
    setSelectedPolicyId: (id: string) => void;
    setSelectedIncubators:(value: Incubator[]) => void;
    setPolicySubmitted: (value : boolean) => void;
    setStatusChanged: (value : boolean) => void;
    selectedIncubators: Incubator[];
    setAddPolicyData: React.Dispatch<React.SetStateAction<{ 
      id:string,
      policyName: string,
      areaType: string,
      policyDuration: string,
      isEquipmentCalReq: string,
      lastCalDate: string,
      nextCalDate: string,
      incubationDurFromExp: string,
      incubationCount: string,
      colCountMinRange: string,
      colCountMaxRange: string,
      isColCountManual: string,
      labTestDurFromInc: string,
      AutoclaveDurFromLab: string,
      isAutoclaveStopMandatory: string,
      fingerDabType: string,
      fingerDabDepartment: string,
      incubationTemparature: string,
      incubationTemparatureMax: string,
      equipmentCalibrationDays: string,
      plateType: string,
      reuseUnUsedPlates: string,
      incubationOrder: string,
      reason: string
    }>>;
    addPolicyData: { 
      policyName: string,
      areaType: string,
      policyDuration: string,
      isEquipmentCalReq: string,
      lastCalDate: string,
      nextCalDate: string,
      incubationDurFromExp: string,
      incubationCount: string,
      colCountMinRange: string,
      colCountMaxRange: string,
      isColCountManual: string,
      labTestDurFromInc: string,
      AutoclaveDurFromLab: string,
      isAutoclaveStopMandatory: string,
      fingerDabType: string,
      fingerDabDepartment: string,
      plateType?: string,
      reuseUnUsedPlates?: string,
      incubationOrder?: string,
      reason? :string
    };

    // handleFilter: (key: string, value: any) => void;
    handleStatusChange: (policy_id: any, status: string) => void;
    handleAddPolicySubmit: () => void;
    setShowAddUserAlert: (value: boolean) => void;
    setModalName:(value : string) => void;
    setShowAddLocationModal:(value: boolean) => void;
}

const LocationPolicy: React.FC<FilterSecProps> = ({
    columns,
    setAddPolicyData,
    addPolicyData,
    showAddUserAlert,
    modalName,
    showAddLocationModal,
    selectedIncubators,
    policySubmitted,
    statusChanged,
    selectedPolicyId,

    // handleFilter,
    handleStatusChange,
    handleAddPolicySubmit,
    setShowAddUserAlert,
    setModalName,
    setShowAddLocationModal,
    setSelectedIncubators,
    setPolicySubmitted,
    setStatusChanged,
    setSelectedPolicyId
}) => {

    const { t } = useTranslation();
    const [departmentList, setDepartmentList] = useState<Array<any>>([]);
    const [areas, setAreas] = useState<Array<any>>([]);
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    // const [selectedPolicyId, setSelectedPolicyId] = useState<string>("");
    const [userId, setUserId] = useState<number>(0);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [keyWord, setkeyWord] = useState("")
    const [policyData, setPolicyData] = useState<Array<any>>([]);
    const [areaType, setAreaType] = useState("10,13,11,15")
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [permission, setPermission] = useState<any>([]);



   

    
    useEffect(() => {
        var storedData = localStorage.getItem('userDetails');
  
        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          getPermissionByRole(storedObject[0].role_master_id)
        }
  
        getLocationPolicy();
        // getSchedulePolicy();
        getDepartmentList();
        geAreaTypeList();
        setUseEffectRequired(false)
        setPolicySubmitted(false)
        setStatusChanged(false)
    }, [useEffectRequired, policySubmitted, statusChanged])


    //api call to list policy table data
    const getLocationPolicy = async () => {
      try {
        const response = await areaService.getLocationPolicy(page, pageSize, sort, order, keyWord, areaType);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {

                  
                   const statusNumber = parseInt(element.policy_area_type, 10); 
                   const policy_area_type = areaService.getLocationStatus(statusNumber);

                  const duration = element.duration > 0  ? element.duration : "-" 
                  const caliberation = element.equipment_calibration == 0 ? "No" : "Yes"
                  const colony_count = element.lab_colony_count_entry_flag == 1 ? `${element.lab_colony_count_min_range} - ${element.lab_colony_count_max_range}` : '-'
                  return { 
                      slno: index + 1, 
                      id: element.id,
                      activity_name: element.activity_name,
                      duration: duration,
                      policy_area_type: policy_area_type,
                      caliberation: caliberation,
                      colony_count: colony_count,
                      status : element.status
                  };
                });
                setPolicyData(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        } else {
          setPolicyData([]);
        }
      } catch (error) {
        console.error('user response', error);
      }
    };

    //handle policy page change 
    const handlePageChange = (newPage : any, pageSize?: number) => {
      setPage(newPage)
      if(pageSize) {
        setPageSize(pageSize)
      }
      setUseEffectRequired(true)
    };

    //handle policy table filter 
    const handleFilter = (key: string, value: any) => {
      if(key === "AreaType") {
        if(value === "0") {
          setAreaType("10,13,11,15")
        } else {
          setAreaType(value)
        }
      }else if (key === "search") {
        setkeyWord(value)
      }
      setUseEffectRequired(true)
      }


    //on Add button clicked 
    const handleAddClick = () => {
        setShowAddLocationModal(true)
        setModalName("Add Location Policy")
    }

    //get department list 
    const getDepartmentList = async () => {
        try {
          const response = await userService.getUserDepartmentList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                        slno: index + 1, 
                        department_name: element.department_name,
                        id : element.id,
                        status : element.status,
                    };
                  });
                  setDepartmentList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
        }
    
    //api call for area type drop down 
    const geAreaTypeList = async () => {
        try {
          const response = await areaService.geLocationDropDownList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                      slno: index + 1, 
                      title: element.type_name,
                      id : element.type_code,
                      status : element.status,
                    };
                  });
                  setAreas(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('area response', error);
        }
      }

    //on Edit button clicked 
    const handleEditClick = (id: string) => {
        const filteredUserDetails = policyData.filter((item) => item.slno === id);
        const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
        setSelectedPolicyId(selectedIds)
        setShowAddLocationModal(true)
        setModalName("Edit Location Policy")
      }

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    //get permission by role 
    const getPermissionByRole = async (role : number) => {
      try {
        const response = await userService.getPermissionByRole(role);
        if(response.status && response.statusCode === 200){
          setPermission(response.data.permissions)
          // try {
          //     if ( Array.isArray(response.data) && response.data?.length > 0) {
          //       let rowData: any = response.data.map((element: any, index: number) => {

          //         return { 
          //           // slno: index + 1, 
          //           label: element.title,
          //           value : element.id,
          //           // status : element.status,
          //         };
          //       });
          //       // setUserRoleList(rowData);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }
        }
      } catch (error) {
        console.error(error);
      }
    }

    






  return (
    <div className='me-3' style={{marginTop : "10px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                  tableHead={t('policyManagement.locationPolicy')}
                  data={policyData} 
                  columns={columns} 
                  isEditable={permission?.includes("edit_policy")} 
                  totalElements={totalElements}  
                  isActionVisible={false} 
                  isViewVisible={false} 
                  isSeachable={true}  
                  onFilterClick={handleFilter}  
                  onUseEffectRequired={() => 
                  setUseEffectRequired(true)} 
                  isToggleStatus={permission?.includes("edit_policy")} 
                  onStatusChange={handleStatusChange}  
                  addButton={permission?.includes("add_policy")}
                  addButtonText={t('policyManagement.add_location')}
                  onAddClick={handleAddClick}
                  areaList={areas}
                  onEditClick={handleEditClick}
                  onSortClick={handleSort}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>

            { /*modal for Add/edit Location policy */}
            <AddLocationPolicyModal 
                showAddModal={showAddLocationModal} 
                setShowAddUserAlert={() => setShowAddUserAlert(true)} 
                setShowAddModal={() => setShowAddLocationModal(false)}
                departmentList={departmentList} 
                areas={areas} 
                policyId={selectedPolicyId}
                setSelectedPolicyId={setSelectedPolicyId}
                setAddPolicyData={setAddPolicyData}
                addPolicyData={addPolicyData}
                modalName={modalName}
                setSelectedIncubators={setSelectedIncubators}
                selectedIncubators={selectedIncubators}
                policySubmitted={policySubmitted}
                setPolicySubmitted={setPolicySubmitted}
            />

        </div>
  )
}

export default LocationPolicy