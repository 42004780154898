import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';


//column data for missed schedules 
const columns : any  = [
      {
        Header: "Sl.No",
        accessor: "slno",
      },
      {
        Header: "dashboard.missedScheduleColumns.locationName",
        accessor: "location_name",
      },
      {
        Header: "dashboard.missedScheduleColumns.scheduleName",
        accessor: "schedule_name",
      },
      {
        Header: "dashboard.missedScheduleColumns.policyName",
        accessor: "policy_name",
      },
      {
        Header: "dashboard.missedScheduleColumns.duration",
        accessor: "duration",
      },
      {
        Header: "dashboard.missedScheduleColumns.startTime",
        accessor: "start_time",
      },
      {
        Header: "dashboard.missedScheduleColumns.endTime",
        accessor: "end_time",
      },
]

const MissedSchedule = () => {

  const { t } = useTranslation();
  const [page, setpage] = useState(1)
  const [pagesize, setpagesize] = useState(5)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [scheduleDetails , setScheduleDetails] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");


//handle pagination 
const handleSchedulePageChange = (newPage : any, pageSize?: number) => {
  setpage(newPage);
  if(pageSize) {
    setpagesize(pageSize)
  }
  setUseEffectRequired(true)
}

// function to sort table
const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")
  } else {
    setOrder("desc")
  }
  setUseEffectRequired(true)
}

//api call for data table listing 
const getMissedScheduleDetails = async () => {
  try {
    const response = await dashboardServices.getMissedScheduleDetails(page, pagesize, sort, order);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            settotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1,             
                  location_name : element.location_name,
                  schedule_name : element.schedule_name,
                  policy_name : element.policy_name,
                  duration : element.duration,
                  start_time : element.start_time,
                  end_time : element.end_time,            
              };
            });
            setScheduleDetails(rowData);
          }
        } catch (error) {
         
          console.log(error);
        }
    }else{
      setScheduleDetails([])
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

useEffect(() => {
  getMissedScheduleDetails();
}, [useEffectRequired])

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('dashboard.title')} />
      <nav aria-label="breadcrumb" className="breadcrumb-container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover">
            <Link to="/*">{t('dashboard.title')}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
          {t('dashboard.missed_schedule')}
          </li>
        </ol>
      </nav>

      <div className='col-md-12 mx-3 pe-5' style={{"height":"45vh"}}>
                <CustomTable
                    tableHead={t('dashboard.missed_schedule')}
                    data={scheduleDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onUseEffectRequired={() => setUseEffectRequired(true)}                 
                    onSortClick={handleSort}
                />
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
  );
};

export default MissedSchedule;
