export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_number",
    },
    // {
    //     Header: "Serial Number",
    //     accessor: "serial_number",
    // },
    // {
    //     Header: "Manufacture Date",
    //     accessor: "manufacturing_date",
    // },
    // {
    //     Header: "Expiry Date",
    //     accessor: "expiry_date",
    // },
     {
        Header: "plateInventory.tableColumns.manufacture_details",
        accessor: "manufacturer_details",
    },
    {
        Header: "plateInventory.tableColumns.quantity",
        accessor: "quantity",
    },
    {
        Header: "plateInventory.tableColumns.plate_type",
        accessor: "plate_type",
    },
    {
        Header: "plateInventory.tableColumns.status",
        accessor: "status",
    },
  ];


export const columnsByBatch: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    // {
    //     Header: "Batch Code",
    //     accessor: "batch_number",
    // },
    {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
    },
    {
        Header: "plateInventory.tableColumns.manufacture_date",
        accessor: "manufacturing_date",
    },
    {
        Header: "plateInventory.tableColumns.expiry_date",
        accessor: "expiry_date",
    },
    {
        Header: "plateInventory.tableColumns.status",
        accessor: "status",
    },
  ];


export const discardColumns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
    },
    {
        Header: "plateInventory.tableColumns.manufacture_date",
        accessor: "manufacturingDate",
    },
    {
        Header: "plateInventory.tableColumns.expiry_date",
        accessor: "expiry_date",
    },
    {
        Header: "plateInventory.tableColumns.quantity",
        accessor: "quantity",
    },
    {
        Header: "plateInventory.tableColumns.status",
        accessor: "status",
    },
]

  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
